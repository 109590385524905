<template>
  <div class="sws-setup-body">
    <div class="sws-middle-menu">
      <ul class="pull-left">
        <li v-for="(item,index) in serviceTypes" :key="index" v-bind:class="{'active': serviceTypeID == item.ID}" @click="enterType(item, index)">
          {{item.ServiceTypeName}}
        </li>
      </ul>
    </div>
    <div class="table-head" style="height: 30px;line-height: 30px;">
      <table>
        <colgroup>
          <col style="width:85%;">
          <col style="width:15%;">
        </colgroup>
        <thead>
          <tr>
            <th>标题</th>
            <th>时间</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:85%;">
          <col style="width:15%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in services" :key="index" @click="openWord(item.Href)">
            <td>{{item.Title}}</td>
            <td>{{item.Date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
// import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'SetupService',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      serviceTypes: [],
      services: [],
      pagingInfo: '',
      pagingKey: 0,
      serviceTypeID: 0,
      version: 0,
      page: 1
    })
    // 服务产品分类
    const loadServiceType = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataService/ServiceType'
      }).then(function (res) {
        const serviceTypes = res.data.ServiceTypes
        state.serviceTypes = serviceTypes

        if (serviceTypes.length > 0) {
          state.serviceTypeID = serviceTypes[0].ID
          state.version = serviceTypes[0].Version
          state.page = 1
          loadService()
        }
      })
    }
    // 数据列表
    const loadService = () => {
      const serviceTypeID = state.serviceTypeID
      const version = state.version
      const page = state.page

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataService/ServiceList?serviceTypeID=' + serviceTypeID + '&version=' + version + '&page=' + page
      }).then(function (res) {
        console.log(res)
        state.services = res.data.Services
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    const enterType = (serviceType) => {
      state.serviceTypeID = serviceType.ID
      state.version = serviceType.Version
      state.page = 1
      loadService()
    }
    const openWord = (href) => {
      window.open(href, '_blank')
      console.log(href)
    }
    // 加载指定页
    const goPage = (page) => {
      state.page = page
      loadService()
    }
    onMounted(() => {
      loadServiceType()
    })
    return {
      ...toRefs(state),
      goPage,
      loadServiceType,
      loadService,
      enterType,
      openWord
    }
  }
}
</script>
