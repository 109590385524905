<template>
<div>
  <div id="mapContainer"></div>
  <div class="sws-tool-panel">
    <div class="sws-warning-panel" v-if="userConfig  != null && userConfig.WarningState">
      <div v-for="(item, index) in dataWarning.dataGroup" :key="index" class="sws-warning-item">
        <div class="sws-warning-item">
          <div class="sws-warning-img">
            <img :src="'https://swsapi.bjzkzm.com/content/images/WarningIcon/s/' + item.IconName" :title="item.Title" />
            <span>x {{item.data.length}}</span>
          </div>
          <div class="sws-warning-area">
            <span>{{item.Area}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="panel">
      <ul v-if="appFunSetup && userConfig" class="nav" :style="{width:unfoldState.width}">
        <li class="fir"  v-bind:class="{'active':userConfig.ImgSurferTemState || userConfig.ImgSurferPreState}">
          <i class="iconfont" title="实况色斑图">&#xe614;</i>实况斑图
          <ul>
            <li v-if="appFunSetup.SurferTemBit" class="nafp sec" v-bind:class="{'active':userConfig.ImgSurferTemState}" @click="ctrlSurferTemDis">气温色斑图</li>
            <li v-if="appFunSetup.SurferPreBit" class="nafp sec" v-bind:class="{'active':userConfig.ImgSurferPreState}" @click="ctrlSurferPreDis">降水色斑图</li>
          </ul>
        </li>
        <li v-if="appFunSetup.RadarBit" class="radar fir" v-bind:class="{'active':userConfig.ImgRadarState}" @click="ctrlRadarDis">
          <i class="iconfont" title="雷达图">&#xe601;</i>雷达图
        </li>
        <!-- <li v-if="appFunSetup.SatelliteBit" class="satellite fir" v-bind:class="{'active':userConfig.ImgSatelliteState}" @click="ctrlSatelliteDis">
          <i class="iconfont" title="卫星云图">&#xe70c;</i>卫星云图
        </li> -->
        <li v-if="appFunSetup.NafpBit || appFunSetup.NafpBit3h" class="fir" v-bind:class="{'active':gridData.eleType != ''}">
          <i class="iconfont" title="格点预报">&#xe9bd;</i>格点预报
          <ul>
            <li v-if="appFunSetup.NafpBit" class="sec" v-bind:class="{'active':gridData.eleType === 'tem' && gridData.timeDelt === 'h01'}" @click="ctrlNafpDis('tem', '1小时气温预报', 'h01')">1小时气温预报</li>
            <li v-if="appFunSetup.NafpBit3h" class="sec" v-bind:class="{'active':gridData.eleType === 'tem' && gridData.timeDelt === 'h03'}" @click="ctrlNafpDis('tem', '3小时气温预报', 'h03')">3小时气温预报</li>
            <li v-if="appFunSetup.NafpBit" class="sec" v-bind:class="{'active':gridData.eleType === 'pre' && gridData.timeDelt === 'h01'}" @click="ctrlNafpDis('pre', '1小时降水预报', 'h01')">1小时降水预报</li>
            <li v-if="appFunSetup.NafpBit3h" class="sec" v-bind:class="{'active':gridData.eleType === 'pre' && gridData.timeDelt === 'h03'}" @click="ctrlNafpDis('pre', '3小时降水预报', 'h03')">3小时降水预报</li>
            <li v-if="appFunSetup.NafpBit" class="sec" v-bind:class="{'active':gridData.eleType === 'win' && gridData.timeDelt === 'h01'}" @click="ctrlNafpDis('win', '1小时风速预报', 'h01')">1小时风速预报</li>
            <li v-if="appFunSetup.NafpBit3h" class="sec" v-bind:class="{'active':gridData.eleType === 'win' && gridData.timeDelt === 'h03'}" @click="ctrlNafpDis('win', '3小时风速预报', 'h03')">3小时风速预报</li>
            <li v-if="appFunSetup.NafpBit" class="sec" v-bind:class="{'active':gridData.eleType === 'vis' && gridData.timeDelt === 'h01'}" @click="ctrlNafpDis('vis', '1小时能见度预报', 'h01')">1小时能见度预报</li>
            <li v-if="appFunSetup.NafpBit3h" class="sec" v-bind:class="{'active':gridData.eleType === 'vis' && gridData.timeDelt === 'h03'}" @click="ctrlNafpDis('vis', '3小时能见度预报', 'h03')">3小时能见度预报</li>
          </ul>
        </li>
        <li v-if="appFunSetup.WarningBit" class="warning fir" v-bind:class="{'active':userConfig.WarningState}" @click="ctrlWarningDis">
          <i class="iconfont" title="灾害预警">&#xe602;</i>灾害预警
        </li>
        <li v-if="appFunSetup.ThunderBit" class="thunder fir" v-bind:class="{'active':userConfig.ThunderState}" @click="ctrlThunderDis">
          <i class="iconfont">&#xe70a;</i>雷电
        </li>
        <li v-if="appFunSetup.WinBit" class="satellite fir" v-bind:class="{'active':userConfig.WinState}" @click="ctrlWinDis">
          <i class="iconfont">&#xe6eb;</i>风场
        </li>
        <li v-if="appFunSetup.TyphoonBit" class="satellite fir" v-bind:class="{'active':userConfig.TyphoonState}" @click="ctrlTyphoonDis">
          <i class="iconfont">&#xe70b;</i>台风
        </li>
      </ul>
    </div>
    <div class="clr"></div>
    <div v-if="userConfig" class="panel">
        <ul class="nav" :style="{width:unfoldState.width}">
            <li class="restorezoom fir" @click="setZoomAndCenter">
                <i class="iconfont" title="初始化视距">&#xe604;</i>初始化视距
            </li>
            <li class="formdisplay fir" @click="formDisplay($event)">
                <i class="iconfont" data-val="0" title="隐藏表单">&#xe605;</i>{{formDisplayText}}
            </li>
        </ul>
    </div>
    <div class="clr"></div>
    <div class="panel">
        <i class="iconfont icon-btn-unfold" @click="unfold" v-html="unfoldState.icon"></i>
    </div>
  </div>
  <div class="sws-maptool-panel-2">
    <ul class="fir" v-if="mapOverlay.length > 0 || layerImage.length > 0 || gridData.eleType != ''">
      <li v-if="gridData.eleType != ''" class="grid-ctrl">
        <i class="iconfont">&#xe9bd;</i><text class="group-name">{{gridData.eleTypeName}}{{gridData.dataTime}}</text>
        <div class="sec">
          <ul class="play-list">
            <li class="iconfont" @click="gridBack">&#xe6a2;</li>
            <li class="iconfont" v-html="gridData.play ? '&#xe682;' : '&#xe681;'" @click="gridData.play = !gridData.play"></li>
            <li class="iconfont" @click="gridNext">&#xe707;</li>
            <li class="img-ctrl-time">
              <input v-model="gridData.timeSpan" @change="gridData.timeSpan = verify05(gridData.timeSpan)" />
            </li>
          </ul>
          <ul class="grid-list">
            <li v-for="(item, index) in gridData.data" :key="index" @click="gridShow(index)" v-bind:class="{'active':index === gridData.index}">{{item.DataTime.slice(5, 16)}}</li>
          </ul>
        </div>
      </li>
      <li v-if="mapOverlay.length > 0 || layerImage.length > 0">
        <i class="iconfont">&#xe737;</i><text class="group-name">覆盖物</text>
        <div class="sws-maptool-panel-box">
          <div class="sws-maptool-panel-con" id="overlayPanelBox">
            <div v-for="(item, index) in mapOverlay" :key="index" class="overlay-panel-item">
              <div class="title">{{item.name}}</div>
              <div class="con">
                <button v-for="(item2, index2) in item.overlays" :key="index2" v-bind:class="{'active': item2.myVisible}" @click="ctrlMapLayer(item2)">{{item2.name}}</button>
              </div>
            </div>
            <div v-for="(item, index) in layerImage" :key="index" class="overlay-panel-item">
              <div class="title">{{item.name}}</div>
              <div class="con">
                <button v-for="(item2, index2) in item.overlays" :key="index2" v-bind:class="{'active': item2.myVisible}" @click="ctrlMapLayer(item2)">{{item2.name}}</button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="sws-maptool-panel">
    <div class="sws-mapstyle-wrap" :class="mapStyle">
      <ul class="sws-mapstyle-icon">
        <li class="style-earth" @click="mapStyle = 'earth'"></li>
        <li class="style-map" @click="mapStyle = 'map'"></li>
      </ul>
    </div>
  </div>
  <div class="sws-lvl-wrap">
    <div class="sws-tool-lvl" v-if="userConfig != null && (userConfig.ImgSurferTemState || userConfig.ImgSurferPreState)">
      <div class="sws-tool-lvl-title">
        <span>{{imgSurfer.lvl.name}}</span>
        <span class="unit">{{imgSurfer.lvl.unit}}</span>
      </div>
      <div class="clr"></div>
      <div class="sws-tool-lvl-color">
        <div v-for="(item, index) in imgSurfer.lvl.color" :key="index" :style="{background: item}"></div>
      </div>
      <div class="sws-tool-lvl-value">
        <div v-for="(item, index) in imgSurfer.lvl.val" :key="index">{{item}}</div>
      </div>
    </div>
    <div v-if="userConfig != null && userConfig.ImgRadarState" class="sws-tool-lvl">
      <div class="sws-tool-lvl-title">
        <span>反射率</span>
        <span class="unit">dBZ</span>
      </div>
      <div class="clr"></div>
      <div class="sws-tool-lvl-color">
        <div v-for="(item, index) in imgRadar.lvl.color" :key="index" :style="{background: item}"></div>
      </div>
      <div class="sws-tool-lvl-value">
        <div v-for="(item, index) in imgRadar.lvl.val" :key="index">{{item}}</div>
      </div>
    </div>
  </div>
  <div class="sws-tool-bottom">
    <MsgChat
    v-if="userConfig != null"
    :userConfig="userConfig"
    @saveChatConfig="saveChatConfig"
    ></MsgChat>
    <div v-if="imgSurfer.data != '' && imgSurfer.data.ImgSurfers.length > 0 && (userConfig.ImgSurferTemState || userConfig.ImgSurferPreState)" class="sws-tool-item" id="swsToolImgCtrlSurfer">
      <div class="sws-tool-imgctrl-title">
        <div class="name">
          {{imgSurfer.data.Name_CN}}
        </div>
        <div class="icon">
          <ul>
            <li class="iconfont" @click="imgSurferBack">&#xe6a2;</li>
            <li class="iconfont" v-html="imgSurfer.play ? '&#xe682;' : '&#xe681;'" @click="imgSurfer.play = !imgSurfer.play"></li>
            <li class="iconfont" @click="imgSurferNext">&#xe707;</li>
            <li class="img-ctrl-time">
              <input v-model="imgSurfer.timeSpan" @change="imgSurfer.timeSpan = verify05(imgSurfer.timeSpan)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="sws-tool-imgctrl">
        <ul>
          <li v-for="(item, index) in imgSurfer.data.ImgSurfers" :key="index" v-bind:class="{'active': index === imgSurfer.index}" @click="imgSurfer.index = index;imgSurfer.imageLayer.setImageUrl(imgSurfer.data.ImgSurfers[index].Src)"></li>
        </ul>
        <div v-if="imgSurfer.data.ImgSurfers.length > 0" class="sws-tool-imgctrl-piece">
          {{imgSurfer.data.ImgSurfers[imgSurfer.index].UpdateTime.substring(5,16)}}
        </div>
      </div>
      <div class="clr"></div>
    </div>
    <div v-if="imgRadar.data.length > 0 && userConfig.ImgRadarState" class="sws-tool-item" id="swsToolImgCtrlRadar">
      <div class="sws-tool-imgctrl-title">
        <div class="name">
          {{imgRadar.radarList.Name}}雷达
        </div>
        <div class="icon">
          <ul>
            <li class="iconfont" @click="imgRadarBack">&#xe6a2;</li>
            <li class="iconfont" v-html="imgRadar.play ? '&#xe682;' : '&#xe681;'" @click="imgRadar.play = !imgRadar.play"></li>
            <li class="iconfont" @click="imgRadarNext">&#xe707;</li>
            <li class="img-ctrl-time">
              <input v-model="imgRadar.timeSpan" @change="imgRadar.timeSpan = verify05(imgRadar.timeSpan)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="sws-tool-imgctrl">
        <ul>
          <li v-for="(item, index) in imgRadar.data" :key="index" v-bind:class="{'active': index === imgRadar.index}" @click="imgRadar.index = index;imgRadar.imageLayer.setImageUrl(imgRadar.data[index].Src)"></li>
        </ul>
        <div v-if="imgRadar.data.length > 0" class="sws-tool-imgctrl-piece">
          {{imgRadar.data[imgRadar.index].UpdateTime.substring(5,16)}}
        </div>
      </div>
      <div class="clr"></div>
    </div>
    <div v-if="imgSatellite.data.length > 0 && userConfig.ImgSatelliteState"  class="sws-tool-item" id="swsToolImgCtrlSatellite">
      <div class="sws-tool-imgctrl-title">
        <div class="name">
          全国云图
        </div>
        <div class="icon">
          <ul>
            <li class="iconfont" @click="imgSatelliteBack">&#xe6a2;</li>
            <li class="iconfont" v-html="imgSatellite.play ? '&#xe682;' : '&#xe681;'" @click="imgSatellite.play = !imgSatellite.play"></li>
            <li class="iconfont" @click="imgSatelliteNext">&#xe707;</li>
            <li class="img-ctrl-time">
              <input v-model="imgSatellite.timeSpan" @change="imgSatellite.timeSpan = verify05(imgSatellite.timeSpan)" />
            </li>
          </ul>
        </div>
      </div>
      <div class="sws-tool-imgctrl">
        <ul>
          <li v-for="(item, index) in imgSatellite.data" :key="index" v-bind:class="{'active': index === imgSatellite.index}" @click="imgSatellite.index = index;imgSatellite.imageLayer.setImageUrl(imgSatellite.data[index].Src)"></li>
        </ul>
        <div v-if="imgSatellite.data.length > 0" class="sws-tool-imgctrl-piece">
          {{imgSatellite.data[imgSatellite.index].UpdateTime.substring(5,16)}}
        </div>
      </div>
      <div class="clr"></div>
    </div>
    <div class="sws-tool-item" id="swsToolCtrlTxtNafp">

    </div>
    <div class="sws-tool-pointicon-wrap" @mouseover="showPointicon" @mouseout="hidePointicon">
      <div class="sws-tool-pointicon-nav">
        <ul>
          <li class="active">站点显示</li>
        </ul>
      </div>
        <div class="sws-tool-pointicon">
          <div class="sws-tool-pointicon-item">
            <ul v-if="appFunSetup && userConfig" class="sws-tool-pointicon-sta">
              <li v-if="appFunSetup.StaCheLvlBit" v-bind:class="{'active':contains(userConfig.StationDisplay, 'chn')}" @click="ctrlStaDis('chn')"><img src="@/assets/images/sta_chn.png" /><span>国家自动站</span></li>
              <li v-if="appFunSetup.StaCheLvlBit" v-bind:class="{'active':contains(userConfig.StationDisplay, 'area')}" @click="ctrlStaDis('area')"><img src="@/assets/images/sta_area.png" /><span>区域自动站</span></li>
              <li v-if="appFunSetup.StaCheAreaBit" v-bind:class="{'active':contains(userConfig.StationDisplay, 'city')}" @click="ctrlStaDis('city')"><img src="@/assets/images/sta_chn.png" /><span>市县自动站</span></li>
              <li v-if="appFunSetup.StaCheAreaBit" v-bind:class="{'active':contains(userConfig.StationDisplay, 'town')}" @click="ctrlStaDis('town')"><img src="@/assets/images/sta_town.png" /><span>镇级自动站</span></li>
              <li v-if="appFunSetup.StaCheAreaBit" v-bind:class="{'active':contains(userConfig.StationDisplay, 'village')}" @click="ctrlStaDis('village')"><img src="@/assets/images/sta_village.png" /><span>村级自动站</span></li>
              <li v-if="appFunSetup.MdlStaBit" v-bind:class="{'active':userConfig.MdlStaDisplay}" @click="ctrlMdlStaDis()"><img src="@/assets/images/sta_village.png" /><span>雷场站</span></li>
            </ul>
            <ul v-if="appFunSetup && appFunSetup.AirQualityBit" class="sws-tool-pointicon-airsta">
              <li id="navAirSta"><img src="@/assets/images/sta_village.png" /><span>空气质量站</span></li>
            </ul>
            <ul v-if="cusStationTypes" class="sws-tool-pointicon-cussta">
              <li v-for="(item, index) in cusStationTypes" :key="index" v-bind:class="{'active':contains(userConfig.CusStationDisplay, item.ID + '')}" @click="ctrlCusStaDis(item.ID)">
                <i class="iconfont" v-html="item.IconUnicode"></i>
                <span>{{item.Name}}</span>
              </li>
            </ul>
            <ul v-if="userConfig" class="sws-tool-pointicon-color">
                <li v-bind:class="{'active':contains(userConfig.ThresholdDisplay, 'blu')}" data-val="blu" @click="ctrlThrDis('blu')"><span>蓝色阈值</span></li>
                <li v-bind:class="{'active':contains(userConfig.ThresholdDisplay, 'yel')}" data-val="yel" @click="ctrlThrDis('yel')"><span>黄色阈值</span></li>
                <li v-bind:class="{'active':contains(userConfig.ThresholdDisplay, 'org')}" data-val="org" @click="ctrlThrDis('org')"><span>橙色阈值</span></li>
                <li v-bind:class="{'active':contains(userConfig.ThresholdDisplay, 'red')}" data-val="red" @click="ctrlThrDis('red')"><span>红色阈值</span></li>
            </ul>
            <ul v-if="appFunSetup && userConfig" class="sws-tool-pointicon-ele">
                <li v-if="contains(appFunSetup.SurfEleBtn,'tem')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'tem')}" @click="ctrlSurfEleDis('tem')"><span>气温</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_1h')}" @click="ctrlSurfEleDis('pre_1h')"><span>1H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_3h')}" @click="ctrlSurfEleDis('pre_3h')"><span>3H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_6h')}" @click="ctrlSurfEleDis('pre_6h')"><span>6H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_12h')}" @click="ctrlSurfEleDis('pre_12h')"><span>12H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_24h')}" @click="ctrlSurfEleDis('pre_24h')"><span>24H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_48h')}" @click="ctrlSurfEleDis('pre_48h')"><span>48H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre1h')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_72h')}" @click="ctrlSurfEleDis('pre_72h')"><span>72H降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre0808')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_0808')}" @click="ctrlSurfEleDis('pre_0808')"><span>8时至当前降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'pre2020')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'pre_2020')}" @click="ctrlSurfEleDis('pre_2020')"><span>20时至当前降水</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'winsinst')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'win')}" @click="ctrlSurfEleDis('win')"><span>极大风</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'rhu')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'rhu')}" @click="ctrlSurfEleDis('rhu')"><span>湿度</span></li>
                <li v-if="contains(appFunSetup.SurfEleBtn,'vis')" v-bind:class="{'active':contains(userConfig.SurfEleDisplay, 'vis')}" @click="ctrlSurfEleDis('vis')"><span>最小能见度</span></li>
            </ul>
          </div>
      </div>
      <div class="clr"></div>
    </div>
  </div>
  <MapStationInfo
  v-if="stationInfo.staID != ''"
  :stationInfo="stationInfo"
  :type="stationInfo.type"
  :cusInfo="cusInfo"
  :appFunSetup = appFunSetup
  :key = stationInfo.key
  @close="closeStationInfo"
  >
  </MapStationInfo>
</div>
</template>
<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import { ElMessage } from 'element-plus'
import MapStationInfo from './MapStationInfo'
import MsgChat from './MsgChat'
import AMapLoader from '@amap/amap-jsapi-loader'
import { shallowRef } from '@vue/reactivity'
import axios from 'axios'
import common from '@/assets/js/common'
import { useStore } from 'vuex'

export default {
  name: 'MapComtaint',
  components: {
    MapStationInfo,
    MsgChat
  },
  props: ['cusInfo', 'dataMetars', 'dataCusMetars', 'metarThreshold', 'metarThresholdCusSta'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      map: shallowRef(null),
      mapStyle: 'map',
      imgSurfer: {
        data: '',
        name_EN: '',
        dataSource: [],
        imageLayer: '',
        eleIndex: 0,
        index: 0,
        play: true,
        timeSpan: 2,
        lvl: {
          color: [],
          val: []
        }
      },
      imgRadar: {
        data: [],
        radarList: {},
        imageLayer: '',
        index: 0,
        play: true,
        timeSpan: 0.5,
        lvl: {
          name: '',
          unit: '',
          color: ['#ad90f0', '#9600b4', '#ff00f0', '#c00000', '#d60000', '#ff0000', '#ff9000', '#e7c000', '#ffff00', '#019000', '#00d800', '#00ecec', '#01a0f6'],
          val: ['70', '65', '60', '55', '50', '45', '40', '35', '30', '25', '20', '15', '10']
        }
      },
      imgSatellite: {
        data: [],
        location: {
          xMin: 43.6868,
          xMax: 150.2372,
          yMin: 0,
          yMax: 59.9458
        },
        imageLayer: '',
        index: 0,
        play: true,
        timeSpan: 0.5
      },
      cusInfo: props.cusInfo,
      dataMetars: props.dataMetars,
      dataCusMetars: props.dataCusMetars,
      dataWarning: {
        dataGroup: [],
        dataSource: []
      },
      metarThreshold: props.metarThreshold,
      metarThresholdCusSta: props.metarThresholdCusSta,
      mapState: false,
      appFunSetup: null,
      appMapSetup: null,
      userConfig: null,
      staMarkers: [],
      gridData: {
        markers: [],
        eleType: '',
        eleTypeName: '',
        timeDelt: '',
        data: [],
        index: 0,
        dataTime: '',
        play: true,
        timeSpan: 5
      },
      cusStaMarkers: [],
      mdlStaMarkers: [],
      cusStationTypes: [],
      formDisplayText: '隐藏表单',
      unfoldState: {
        state: false,
        icon: '&#xe622;',
        width: '110px'
      },
      stationInfo: {
        staID: '',
        staName: '',
        lon: 0,
        lat: 0,
        type: '',
        key: 0
      },
      timeSum: 0,
      mapOverlay: [],
      layerImage: []
    })
    // 初始化地图
    const ininMap = () => {
      AMapLoader.load({
        key: '9ae50db282777ad69e6b225cf7a8714a',
        version: '2.0',
        plugins: ['AMap.ToolBar', 'AMap.Driving'],
        AMapUI: {
          version: '1.1',
          plugins: []
        },
        Loca: {
          version: '2.0.0'
        }
      }).then((AMap) => {
        state.map = new AMap.Map('mapContainer', {
          viewMode: '2D',
          zoom: state.appMapSetup.Zoom,
          zooms: [2, 22],
          center: [state.appMapSetup.CenterLon, state.appMapSetup.CenterLat],
          mapStyle: state.appMapSetup.MapStyle
        })
        state.mapState = true
        // 地图移动事件
        state.map.on('moveend', function () {
          loadNafp()
        })
        // 地图加载完成
        state.map.on('complete', function () {
          loadMapPath()
          if (state.userConfig.ImgRadarState) {
            loadRadar()
          }
          if (state.userConfig.ImgSatelliteState) {
            loadSatellite()
          }
          if (state.userConfig.ImgSurferTemState || state.userConfig.ImgSurferPreState) {
            loadSurfer()
          }
          if (state.userConfig.WarningState) {
            loadWarning()
          }
          if (state.appFunSetup.MdlStaBit && state.userConfig.MdlStaDisplay) {
            setMdlStaMarker()
          }
          loadMapLayer()
          autoPlay()
          window.satelliteLayer = new window.AMap.TileLayer.Satellite()
          state.gridLayer = new window.AMap.LabelMarker({
            zooms: [3, 20],
            zIndex: 1000,
            // collision: false,
            // 设置 allowCollision：true，可以让标注避让用户的标注
            allowCollision: true
          })
        })
      }).catch(e => {
        console.log(e)
      })
    }
    // 设置站点标注
    const setStaMarker = (data) => {
      for (let i = state.staMarkers.length - 1; i >= 0; i--) {
        state.staMarkers[i].remove()
      }
      const metarThreshold = props.metarThreshold
      if (state.mapState && metarThreshold) {
        data.DataMetars.filter(tem => tem.State === true).forEach(function (ele, index, arr) {
          let iconImg = ''
          let content = ''
          let color = ''
          let temThr = false
          let pre1hThr = false
          let pre3hThr = false
          let pre6hThr = false
          let pre12hThr = false
          let pre24hThr = false
          let pre48hThr = false
          let pre72hThr = false
          let pre0808Thr = false
          let pre2020Thr = false
          let winThr = false
          let visThr = false
          if (!isContainsEng(ele.Station_ID_C) || ele.Lvl === 'city') {
            iconImg = require('../assets/images/sta_city.png')
          } else if (ele.Lvl === 'town') {
            iconImg = require('../assets/images/sta_town.png')
          } else {
            iconImg = require('../assets/images/sta_village.png')
          }
          const icon = new window.AMap.Icon({
            size: new window.AMap.Size(21, 21), // 图标尺寸
            image: iconImg, // Icon的图像
            imageOffset: new window.AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new window.AMap.Size(21, 21) // 根据所设置的大小拉伸或压缩图片
          })
          if (contains(state.appFunSetup.SurfEleBtn, 'tem')) {
            if (ele.TEM < 900000) {
              color = getColor(ele.TEM, metarThreshold.TEM_hot_BU, metarThreshold.TEM_hot_YL, metarThreshold.TEM_hot_OG, metarThreshold.TEM_hot_RD)
              temThr = getThr(metarThreshold.TEM_hot_bit, color)
              if (state.userConfig.SurfEleDisplay.indexOf('tem') !== -1 || temThr) {
                content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_tem.png') + ' /></div><div class="val">' + ele.TEM_Max + ' ℃</div></div>'
              }
            }
          }
          if (ele.PRE_1h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre1h')) {
            color = getColor(ele.PRE_1h, metarThreshold.PRE_1hour_BU, metarThreshold.PRE_1hour_YL, metarThreshold.PRE_1hour_OG, metarThreshold.PRE_1hour_RD)
            pre1hThr = getThr(metarThreshold.PRE_1hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_1h') !== -1 || pre1hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre1.png') + ' /></div><div class="val">' + ele.PRE_1h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_3h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre3h')) {
            color = getColor(ele.PRE_3h, metarThreshold.PRE_3hour_BU, metarThreshold.PRE_3hour_YL, metarThreshold.PRE_3hour_OG, metarThreshold.PRE_3hour_RD)
            pre3hThr = getThr(metarThreshold.PRE_3hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_3h') !== -1 || pre3hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre3.png') + ' /></div><div class="val">' + ele.PRE_3h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_6h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre6h')) {
            color = getColor(ele.PRE_6h, metarThreshold.PRE_6hour_BU, metarThreshold.PRE_6hour_YL, metarThreshold.PRE_6hour_OG, metarThreshold.PRE_6hour_RD)
            pre6hThr = getThr(metarThreshold.PRE_6hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_6h') !== -1 || pre6hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre6.png') + ' /></div><div class="val">' + ele.PRE_6h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_12h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre12h')) {
            color = getColor(ele.PRE_12h, metarThreshold.PRE_12hour_BU, metarThreshold.PRE_12hour_YL, metarThreshold.PRE_12hour_OG, metarThreshold.PRE_12hour_RD)
            pre12hThr = getThr(metarThreshold.PRE_12hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_12h') !== -1 || pre12hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre12.png') + ' /></div><div class="val">' + ele.PRE_12h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_24h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre24h')) {
            color = getColor(ele.PRE_24h, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre24hThr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_24h') !== -1 || pre24hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre24.png') + ' /></div><div class="val">' + ele.PRE_24h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_48h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre48h')) {
            color = getColor(ele.PRE_48h, metarThreshold.PRE_48hour_BU, metarThreshold.PRE_48hour_YL, metarThreshold.PRE_48hour_OG, metarThreshold.PRE_48hour_RD)
            pre48hThr = getThr(metarThreshold.PRE_48hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_48h') !== -1 || pre48hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre48.png') + ' /></div><div class="val">' + ele.PRE_48h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_72h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre72h')) {
            color = getColor(ele.PRE_72h, metarThreshold.PRE_72hour_BU, metarThreshold.PRE_72hour_YL, metarThreshold.PRE_72hour_OG, metarThreshold.PRE_72hour_RD)
            pre72hThr = getThr(metarThreshold.PRE_72hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_72h') !== -1 || pre72hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre72.png') + ' /></div><div class="val">' + ele.PRE_72h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_0808 < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre0808')) {
            color = getColor(ele.PRE_0808, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre0808Thr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_0808') !== -1 || pre0808Thr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre0808.png') + ' /></div><div class="val">' + ele.PRE_0808.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_2020 < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre2020')) {
            color = getColor(ele.PRE_2020, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre2020Thr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_2020') !== -1 || pre2020Thr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre2020.png') + ' /></div><div class="val">' + ele.PRE_2020.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.WIN_S_INST_Max < 900000 && contains(state.appFunSetup.SurfEleBtn, 'winsinst')) {
            color = getColor(ele.WIN_S_INST_Max, metarThreshold.WIN_BU, metarThreshold.WIN_YL, metarThreshold.WIN_OG, metarThreshold.WIN_RD)
            winThr = getThr(metarThreshold.WIN_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('win') !== -1 || winThr) {
              content += '<div class="map-station-metar" title="' + common.getWindDirTxt8(ele.WIN_D_INST_Max) + common.getWindPower(ele.WIN_S_INST_Max) + '级"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_win.png') + ' /></div><div class="val">' + ele.WIN_S_INST_Max + ' m/s</div></div>'
            }
          }
          if (ele.RHU < 900000 && contains(state.appFunSetup.SurfEleBtn, 'rhu')) {
            color = 'gre'
            if (state.userConfig.SurfEleDisplay.indexOf('rhu') !== -1) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_rhu.png') + ' /></div><div class="val">' + ele.RHU + ' %</div></div>'
            }
          }
          if (ele.VIS < 900000 && contains(state.appFunSetup.SurfEleBtn, 'vis')) {
            color = getColorDesc(ele.VIS, metarThreshold.VIS_BU, metarThreshold.VIS_YL, metarThreshold.VIS_OG, metarThreshold.VIS_RD)
            visThr = getThr(metarThreshold.VIS_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('vis') !== -1 || visThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_vis.png') + ' /></div><div class="val">' + ele.VIS + ' m</div></div>'
            }
          }
          content = '<div class="map-station-wrap">' + content + '</div>'
          content = '<div class="map-station-name">' + ele.Station_Name + '<div>' + content

          let show = false
          if (!isContainsEng(ele.Station_ID_C)) {
            show = contains(state.userConfig.StationDisplay, 'chn') ? true : show
          } else {
            show = contains(state.userConfig.StationDisplay, 'area') ? true : show
          }

          if (ele.Lvl === 'city') {
            show = contains(state.userConfig.StationDisplay, 'city') ? true : show
          } else if (ele.Lvl === 'town') {
            show = contains(state.userConfig.StationDisplay, 'town') ? true : show
          } else if (ele.Lvl === 'village') {
            show = contains(state.userConfig.StationDisplay, 'village') ? true : show
          }

          if ((temThr || pre1hThr || pre3hThr || pre6hThr || pre12hThr || pre24hThr || pre48hThr || pre72hThr || pre0808Thr || pre2020Thr || winThr || visThr) || show) {
            const marker = new window.AMap.Marker({
              icon: icon,
              position: [ele.Lon, ele.Lat],
              offset: new window.AMap.Pixel(-10.5, -10.5),
              label: {
                content: content,
                direction: 'right'
              },
              zIndex: !isContainsEng(ele.Station_ID_C) ? 10 : 9
            })
            marker.on('click', function () { openStationInfo(ele.Station_ID_C, 'auto') })
            state.staMarkers.push(marker)
            state.map.add(marker)
          }
        })
      } else {
        setTimeout(function () {
          setStaMarker(data)
        }, 1000)
      }
    }
    // 设置自定义站点标注
    const setCusStaMarker = (data) => {
      for (let i = state.cusStaMarkers.length - 1; i >= 0; i--) {
        state.cusStaMarkers[i].remove()
      }
      const metarThreshold = props.metarThresholdCusSta

      if (state.mapState && metarThreshold && data.DataCusMetars !== null) {
        data.DataCusMetars.forEach(function (ele, index, arr) {
          let iconImg = ''
          let content = ''
          let color = ''
          let temThr = false
          let pre1hThr = false
          let pre3hThr = false
          let pre6hThr = false
          let pre12hThr = false
          let pre24hThr = false
          let pre48hThr = false
          let pre72hThr = false
          let pre0808Thr = false
          let pre2020Thr = false
          let winThr = false

          state.cusStationTypes.filter(tem => tem.ID === ele.CusStationTypeID).forEach(function (ele2, index2, arr2) {
            iconImg = require('../assets/images/CusStaIcon/' + ele2.Icon + '.png')
          })

          const icon = new window.AMap.Icon({
            size: new window.AMap.Size(21, 21), // 图标尺寸
            image: iconImg, // Icon的图像
            imageOffset: new window.AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new window.AMap.Size(21, 21) // 根据所设置的大小拉伸或压缩图片
          })
          if (contains(state.appFunSetup.SurfEleBtn, 'tem')) {
            if (ele.TEM < 900000) {
              color = getColor(ele.TEM, metarThreshold.TEM_hot_BU, metarThreshold.TEM_hot_YL, metarThreshold.TEM_hot_OG, metarThreshold.TEM_hot_RD)
              temThr = getThr(metarThreshold.TEM_hot_bit, color)
              if (state.userConfig.SurfEleDisplay.indexOf('tem') !== -1 || temThr) {
                content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_tem.png') + ' /></div><div class="val">' + ele.TEM_Max + ' ℃</div></div>'
              }
            }
          }
          if (ele.PRE_1h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre1h')) {
            color = getColor(ele.PRE_1h, metarThreshold.PRE_1hour_BU, metarThreshold.PRE_1hour_YL, metarThreshold.PRE_1hour_OG, metarThreshold.PRE_1hour_RD)
            pre1hThr = getThr(metarThreshold.PRE_1hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_1h') !== -1 || pre1hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre1.png') + ' /></div><div class="val">' + ele.PRE_1h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_3h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre3h')) {
            color = getColor(ele.PRE_3h, metarThreshold.PRE_3hour_BU, metarThreshold.PRE_3hour_YL, metarThreshold.PRE_3hour_OG, metarThreshold.PRE_3hour_RD)
            pre3hThr = getThr(metarThreshold.PRE_3hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_3h') !== -1 || pre3hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre3.png') + ' /></div><div class="val">' + ele.PRE_3h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_6h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre6h')) {
            color = getColor(ele.PRE_6h, metarThreshold.PRE_6hour_BU, metarThreshold.PRE_6hour_YL, metarThreshold.PRE_6hour_OG, metarThreshold.PRE_6hour_RD)
            pre6hThr = getThr(metarThreshold.PRE_6hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_6h') !== -1 || pre6hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre6.png') + ' /></div><div class="val">' + ele.PRE_6h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_12h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre12h')) {
            color = getColor(ele.PRE_12h, metarThreshold.PRE_12hour_BU, metarThreshold.PRE_12hour_YL, metarThreshold.PRE_12hour_OG, metarThreshold.PRE_12hour_RD)
            pre12hThr = getThr(metarThreshold.PRE_12hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_12h') !== -1 || pre12hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre12.png') + ' /></div><div class="val">' + ele.PRE_12h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_24h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre24h')) {
            color = getColor(ele.PRE_24h, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre24hThr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_24h') !== -1 || pre24hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre24.png') + ' /></div><div class="val">' + ele.PRE_24h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_48h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre48h')) {
            color = getColor(ele.PRE_48h, metarThreshold.PRE_48hour_BU, metarThreshold.PRE_48hour_YL, metarThreshold.PRE_48hour_OG, metarThreshold.PRE_48hour_RD)
            pre48hThr = getThr(metarThreshold.PRE_48hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_48h') !== -1 || pre48hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre48.png') + ' /></div><div class="val">' + ele.PRE_48h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_72h < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre72h')) {
            color = getColor(ele.PRE_72h, metarThreshold.PRE_72hour_BU, metarThreshold.PRE_72hour_YL, metarThreshold.PRE_72hour_OG, metarThreshold.PRE_72hour_RD)
            pre72hThr = getThr(metarThreshold.PRE_72hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_72h') !== -1 || pre72hThr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre72.png') + ' /></div><div class="val">' + ele.PRE_72h.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_0808 < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre0808')) {
            color = getColor(ele.PRE_0808, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre0808Thr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_0808') !== -1 || pre0808Thr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre0808.png') + ' /></div><div class="val">' + ele.PRE_0808.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.PRE_2020 < 900000 && contains(state.appFunSetup.SurfEleBtn, 'pre2020')) {
            color = getColor(ele.PRE_2020, metarThreshold.PRE_24hour_BU, metarThreshold.PRE_24hour_YL, metarThreshold.PRE_24hour_OG, metarThreshold.PRE_24hour_RD)
            pre2020Thr = getThr(metarThreshold.PRE_24hour_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('pre_2020') !== -1 || pre2020Thr) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_pre2020.png') + ' /></div><div class="val">' + ele.PRE_2020.toFixed(1) + ' mm</div></div>'
            }
          }
          if (ele.WIN_S_INST_Max < 900000 && contains(state.appFunSetup.SurfEleBtn, 'winsinst')) {
            color = getColor(ele.WIN_S_INST_Max, metarThreshold.WIN_BU, metarThreshold.WIN_YL, metarThreshold.WIN_OG, metarThreshold.WIN_RD)
            winThr = getThr(metarThreshold.WIN_bit, color)
            if (state.userConfig.SurfEleDisplay.indexOf('win') !== -1 || winThr) {
              content += '<div class="map-station-metar" title="' + common.getWindDirTxt8(ele.WIN_D_INST_Max) + common.getWindPower(ele.WIN_S_INST_Max) + '级"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_win.png') + ' /></div><div class="val">' + ele.WIN_S_INST_Max + ' m/s</div></div>'
            }
          }
          if (ele.RHU < 900000 && contains(state.appFunSetup.SurfEleBtn, 'rhu')) {
            color = 'gre'
            if (state.userConfig.SurfEleDisplay.indexOf('rhu') !== -1) {
              content += '<div class="map-station-metar"><div class="icon m-' + color + '"><img src=' + require('../assets/images/icon_rhu.png') + ' /></div><div class="val">' + ele.RHU + ' %</div></div>'
            }
          }

          content = '<div class="map-station-wrap">' + content + '</div>'
          content = '<div class="map-station-name">' + ele.Station_Name + '<div>' + content
          let show = false
          show = contains(state.userConfig.CusStationDisplay, ele.CusStationTypeID + '') ? true : show

          if ((temThr || pre1hThr || pre3hThr || pre6hThr || pre12hThr || pre24hThr || pre48hThr || pre72hThr || pre0808Thr || pre2020Thr || winThr) || show) {
            const marker = new window.AMap.Marker({
              icon: icon,
              position: [ele.Lon, ele.Lat],
              offset: new window.AMap.Pixel(-10.5, -10.5),
              label: {
                content: content,
                direction: 'right'
              },
              zIndex: 5
            })
            state.cusStaMarkers.push(marker)
            marker.on('click', function () { openStationInfo(ele.Station_ID_C, 'cus') })
            state.map.add(marker)
          }
        })
      } else {
        setTimeout(function () {
          setCusStaMarker(data)
        }, 1000)
      }
    }
    // 设置雷场站点标注
    const setMdlStaMarker = () => {
      for (let i = state.mdlStaMarkers.length - 1; i >= 0; i--) {
        state.mdlStaMarkers[i].remove()
      }
      const cusInfo = props.cusInfo
      var param = {
        appCode: cusInfo.AppCode
      }

      const iconImg = require('../assets/images/sta_mdl.png')
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(21, 21), // 图标尺寸
        image: iconImg, // Icon的图像
        imageOffset: new window.AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new window.AMap.Size(21, 21) // 根据所设置的大小拉伸或压缩图片
      })

      axios.request({
        method: 'post',
        url: 'https://swsapi.bjzkzm.com/DataMdl/StationInfo',
        data: param
      }).then(function (res) {
        const data = res.data
        if (data.State === 'ok' && data.MdlStationInfos && data.MdlStationInfos.length > 0) {
          data.MdlStationInfos.forEach(function (ele, index, arr) {
            let content = ''
            content = '<div class="map-station-wrap">' + content + '</div>'
            content = '<div class="map-station-name">' + ele.Station_Name + '<div>' + content
            const marker = new window.AMap.Marker({
              icon: icon,
              position: [ele.Lon, ele.Lat],
              offset: new window.AMap.Pixel(-10.5, -10.5),
              label: {
                content: content,
                direction: 'right'
              },
              zIndex: 8
            })
            marker.Station_ID_C = ele.Station_ID_C
            marker.Station_Name = ele.Station_Name

            state.mdlStaMarkers.push(marker)
            state.map.add(marker)
          })

          SetMdlStormEventsData()
        }
      })
    }
    const SetMdlStormEventsData = () => {
      const cusInfo = props.cusInfo
      var param = {
        appCode: cusInfo.AppCode
      }

      axios.request({
        method: 'post',
        url: 'https://swsapi.bjzkzm.com/DataMdl/StormEvents',
        data: param
      }).then(function (res) {
        const data = res.data
        console.log(data)
        if (data.State === 'ok' && data.MdlStormEvents && data.MdlStormEvents.length > 0) {
          state.mdlStaMarkers.forEach(function (ele, index, arr) {
            const label = {
              content: '<div class="map-station-mdl">无预警信息</div>',
              direction: 'right'
            }
            if (data.MdlStormEvents.some(tem => tem.Station_ID_C === ele.Station_ID_C)) {
              data.MdlStormEvents.filter(tem => tem.Station_ID_C === ele.Station_ID_C).forEach(function (ele, index, arr) {
                if (new Date(ele.EndTime) < new Date('2020-01-01 00:00:00')) {
                  label.content = '<div class="map-station-mdl">' +
                  '<div>最小等级:' + ele.MinLvl + ' 最大等级:' + ele.MaxLvl + '</div>' +
                  '<div>最小值:' + ele.MinVal + ' 最大值:' + ele.MaxVal + '</div>' +
                  '<div>最小均值:' + ele.MinAvgVal + ' 最大均值:' + ele.MaxAvgVal + '</div>' +
                  '</div>'
                }
              })
            }
            label.content = '<div class="map-station-wrap">' + label.content + '</div>'
            label.content = '<div class="map-station-name">' + ele.Station_Name + '<div>' + label.content

            ele.setLabel(label)
          })
        }
      })
    }
    // 设置站点海量标注
    const setStaLableMarker = (data) => {
      const labelsLayer = new window.AMap.LabelsLayer({
        zooms: [3, 20],
        zIndex: 1000,
        collision: true, // 该层内标注是否避让
        allowCollision: true // 设置 allowCollision：true，可以让标注避让用户的标注
      })
      data.DataMetars.forEach(function (ele, index, arr) {
        // 设置一个图标对象
        const icon = {
          type: 'image', // 图标类型，现阶段只支持 image 类型
          image: 'https://a.amap.com/jsapi_demos/static/demo-center/marker/express2.png', // 图片 url
          size: [64, 30], // 图片尺寸
          anchor: 'center' // 图片相对 position 的锚点，默认为 bottom-center
        }
        // 设置文字对象
        const text = {
          content: '<div style:"color:red;">中邮速递易</div>', // 要展示的文字内容
          direction: 'right', // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          offset: [-20, -5], // 在 direction 基础上的偏移量
          style: { // 文字样式
            fontSize: 12, // 字体大小
            fillColor: '#22886f', // 字体颜色
            strokeColor: '#fff', // 描边颜色
            strokeWidth: 2 // 描边宽度
          }
        }
        const labelMarker = new window.AMap.LabelMarker({
          name: '标注2', // 此属性非绘制文字内容，仅最为标识使用
          position: [116.466994, 39.984904],
          zIndex: 16,
          icon: icon, // 将第一步创建的 icon 对象传给 icon 属性
          text: text // 将第二步创建的 text 对象传给 text 属性
        })
        labelsLayer.add(labelMarker)
      })
      state.map.add(labelsLayer)
    }
    // 加载雷达图
    const loadRadar = () => {
      // const zoom = state.map.getZoom() // 获取当前地图级别
      let center = state.map.getCenter() // 获取当前地图中心位置
      center = center.toString().split(',')
      const url = 'https://swsapi.bjzkzm.com/DataRadarSatellite/RadarByLocation?lat=' + center[1] + '&lon=' + center[0]
      // if (zoom < 8) {
      //   url = 'https://swsapi.bjzkzm.com/DataRadarSatellite/RadarOverall'
      // }

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: url
      }).then(function (res) {
        state.imgRadar.data = res.data.ImgRadars
        state.imgRadar.radarList = res.data.RadarList
        if (state.imgRadar.imageLayer === '') {
          state.imgRadar.imageLayer = new window.AMap.ImageLayer({
            url: res.data.ImgRadars[0].Src,
            opacity: 1,
            zIndex: 100,
            bounds: new window.AMap.Bounds(
              [res.data.RadarList.XMin, res.data.RadarList.YMin],
              [res.data.RadarList.XMax, res.data.RadarList.YMax]
            )
          })
          // 将图层添加至地图实例
          state.map.add(state.imgRadar.imageLayer)
        }
      })
    }
    // 加载卫星云图
    const loadSatellite = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataRadarSatellite/SatelliteOverall'
      }).then(function (res) {
        state.imgSatellite.data = res.data.ImgSatellites
        state.imgSatellite.location = {
          xMin: res.data.XMin,
          xMax: res.data.XMax,
          yMin: res.data.YMin,
          yMax: res.data.YMax
        }
        if (state.imgSatellite.imageLayer === '') {
          state.imgSatellite.imageLayer = new window.AMap.ImageLayer({
            url: res.data.ImgSatellites[0].Src,
            opacity: 1,
            zIndex: 100,
            bounds: new window.AMap.Bounds(
              [res.data.XMin, res.data.YMin],
              [res.data.XMax, res.data.YMax]
            )
          })
          // 将图层添加至地图实例
          state.map.add(state.imgSatellite.imageLayer)
        }
      })
    }
    // 加载地图覆盖物
    const loadMapLayer = () => {
      const url = 'https://swsapi.bjzkzm.com/datamap/maplayer'

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: url
      }).then(function (res) {
        console.log(res)
        res.data.MapOverlays.forEach(function (ele, index, arr) {
          const obj = {
            name: ele.Name,
            radioBit: ele.RadioBit,
            overlays: []
          }

          ele.Texts.forEach(function (ele2, index2, arr2) {
            const text = new window.AMap.Text({
              text: ele2.Text,
              anchor: 'center',
              draggable: false,
              style: {
                background: 'none',
                borderColor: 'rgba(255, 255, 255, .5)',
                color: 'rgba(255, 255, 255, .5)'
              },
              position: [ele2.Position.split(',')[0], ele2.Position.split(',')[1]],
              zIndex: ele2.ZIndex
            })
            text.name = ele2.Name
            text.myVisible = true
            obj.overlays.push(text)
            state.map.add(text)
          })
          ele.Polylines.forEach(function (ele2, index2, arr2) {
            const overlayGroup = new window.AMap.OverlayGroup()
            console.log(3)
            ele2.Paths.forEach(function (ele3, index3, arr3) {
              const path = []
              ele3.Lons.forEach(function (ele4, index4, arr4) {
                path.push(new window.AMap.LngLat(ele3.Lons[index4], ele3.Lats[index4]))
              })
              // 创建折线实例
              const polyline = new window.AMap.Polyline({
                path: path,
                strokeWeight: ele2.StrokeWeight, // 线条宽度，默认为 1
                strokeColor: ele2.StrokeColor, // 线条颜色
                strokeOpacity: ele2.StrokeOpacity, // 折线透明度
                lineJoin: ele2.LineJoin, // 折线拐点连接处样式
                isOutline: ele2.BorderWeight > 0, // 是否描边
                outlineColor: ele2.OutlineColor, // 描边颜色
                borderWeight: ele2.BorderWeight, // 描边粗细
                zIndex: ele2.ZIndex
              })
              overlayGroup.name = ele2.Name
              overlayGroup.myVisible = ele2.true
              // 将折线添加至地图实例
              state.map.add(polyline)
              overlayGroup.addOverlay(polyline)
            })
            obj.overlays.push(overlayGroup)
          })
          ele.Polygons.forEach(function (ele2, index2, arr2) {
            ele2.Paths.forEach(function (ele3, index3, arr3) {
              const path = []
              ele3.Lons.forEach(function (ele4, index4, arr4) {
                path.push(new window.AMap.LngLat(ele3.Lons[index4], ele3.Lats[index4]))
              })
              // 创建折线实例
              const polygon = new window.AMap.Polygon({
                path: path,
                fillColor: ele2.FillColor,
                fillOpacity: ele2.FillOpacity,
                strokeWeight: ele2.StrokeWeight, // 线条宽度，默认为 1
                strokeColor: ele2.StrokeColor, // 线条颜色
                lineJoin: ele2.LineJoin, // 折线拐点连接处样式
                zIndex: ele2.ZIndex
              })
              polygon.name = ele2.Name
              polygon.myVisible = true
              obj.overlays.push(polygon)
              // 将折线添加至地图实例
              state.map.add(polygon)
            })
          })
          ele.Circles.forEach(function (ele2, index2, arr2) {
            const circle = new window.AMap.Circle({
              center: new window.AMap.LngLat(ele2.Position.split(',')[0], ele2.Position.split(',')[1]), // 圆心位置
              radius: ele2.Radius, // 圆半径
              fillColor: ele2.FillColor, // 圆形填充颜色
              fillOpacity: 0, // 圆形填充颜色
              strokeColor: ele2.StrokeColor, // 描边颜色
              strokeWeight: ele2.StrokeWeight // 描边宽度
            })

            circle.name = ele2.Name
            circle.myVisible = true
            obj.overlays.push(circle)
            state.map.add(circle)
          })

          state.mapOverlay.push(obj)
        })

        res.data.LayerImages.forEach(function (ele, index, arr) {
          const obj = {
            name: ele.Name,
            radioBit: ele.RadioBit,
            overlays: []
          }

          ele.LayerImages.forEach(function (ele2, index2, arr2) {
            // 创建图片图层
            const imageLayer = new window.AMap.ImageLayer({
              bounds: new window.AMap.Bounds(
                [ele2.Bounds.split(',')[0], ele2.Bounds.split(',')[1]],
                [ele2.Bounds.split(',')[2], ele2.Bounds.split(',')[3]]
              ),
              url: ele2.Url, // 图片Url
              zIndex: ele2.ZIndex,
              zooms: [ele2.Zooms.split(',')[0], ele2.Zooms.split(',')[1]] // 设置可见级别，[最小级别，最大级别]
            })
            // 将图层添加至地图实例
            imageLayer.name = ele2.Name
            if (index2 === 0) {
              imageLayer.myVisible = true
            } else {
              imageLayer.myVisible = false
              imageLayer.hide()
            }
            obj.overlays.push(imageLayer)
            state.map.add(imageLayer)
          })

          state.layerImage.push(obj)
        })
      })
    }
    // 控制地图覆盖物显示
    const ctrlMapLayer = (item) => {
      if (item.myVisible) {
        item.myVisible = false
        item.hide()
      } else {
        item.myVisible = true
        item.show()
      }
    }
    // 加载预警
    const loadWarning = () => {
      const url = 'https://swsapi.bjzkzm.com/datawarning/index'

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: url
      }).then(function (res) {
        const dataWarning = []
        if (res.data.DataWarnings.length > 0) {
          res.data.DataWarnings.forEach(function (ele, index, arr) {
            ele.WarningInfos.forEach(function (ele2, index2, arr2) {
              if (dataWarning.some(tem => tem.EventType_CN === ele2.WarningData.EventType_CN && tem.Severity_CN === ele2.WarningData.Severity_CN)) {
                dataWarning.forEach(function (ele3, index3, arr3) {
                  if (ele3.EventType_CN === ele2.WarningData.EventType_CN && ele3.Severity_CN === ele2.WarningData.Severity_CN) {
                    ele3.Area += '、' + ele.Name
                    ele3.Title += '\r\n' + ele2.WarningData.Headline
                    ele3.data.push(ele2.WarningData)
                  }
                })
              } else {
                dataWarning.push({
                  Area: ele.Name,
                  Title: ele2.WarningData.Headline,
                  IconName: ele2.WarningSet.IconName,
                  EventType_CN: ele2.WarningData.EventType_CN,
                  Severity_CN: ele2.WarningData.Severity_CN,
                  data: [ele2.WarningData]
                })
              }
            })
          })
          state.dataWarning.dataGroup = dataWarning
          state.dataWarning.dataSource = res.data.DataWarnings
        } else {
          state.dataWarning.dataGroup = []
          state.dataWarning.dataSource = []
        }
      })
    }
    // 加载格点
    const loadNafp = () => {
      if (state.gridData.eleType === '') {
        return
      }
      let gridData = []

      const cusInfo = props.cusInfo
      const northWest = state.map.getBounds().getNorthWest()
      const southEast = state.map.getBounds().getSouthEast()

      const param = {
        lonMin: Math.floor(northWest.lng * 100) / 100,
        lonMax: Math.ceil(southEast.lng * 100) / 100,
        latMin: Math.floor(southEast.lat * 100) / 100,
        latMax: Math.ceil(northWest.lat * 100) / 100,
        adminCode: cusInfo.Admin_Code_CHN.slice(0, 2) + '0000',
        ele: state.gridData.eleType,
        type: state.gridData.timeDelt,
        xValNum: 12
      }

      axios.request({
        method: 'post',
        url: 'https://awd.bjzkzm.com/nwp/lonlatrange',
        data: param
      }).then(function (res) {
        const data = res.data
        if (data.State === 'ok' || data.data.length > 0) {
          data.data = data.data.length > 24 ? data.data.slice(0, 24) : data.data
          gridData = gridData.concat(data.data)
          state.gridData.data = gridData
          gridShow(state.gridData.index)
        } else {
          state.gridData.eleType = ''
          removeGrid()
          ElMessage({
            showClose: true,
            message: '未加载到' + state.gridData.eleTypeName + '数据'
          })
        }
      })
    }
    // 加载色斑图
    const loadSurfer = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataSurfer/index'
      }).then(function (res) {
        try {
          switch (res.data.DataSurfers[state.imgSurfer.eleIndex].Name_EN) {
            case 'tem':
              if (state.userConfig.ImgSurferTemState) {
                state.imgSurfer.data = res.data.DataSurfers[state.imgSurfer.eleIndex]
                state.name_EN = 'tem'
              }
              break
            case 'pre1h':
              if (state.userConfig.ImgSurferPreState) {
                state.imgSurfer.data = res.data.DataSurfers[state.imgSurfer.eleIndex]
                state.name_EN = 'pre1h'
              }
              break
          }
          if (state.imgSurfer.data === '') {
            res.data.DataSurfers.forEach(function (ele, index, arr) {
              switch (ele.Name_EN) {
                case 'tem':
                  if (state.userConfig.ImgSurferTemState) {
                    state.imgSurfer.data = ele
                    state.name_EN = 'tem'
                  }
                  break
                case 'pre1h':
                  if (state.userConfig.ImgSurferPreState) {
                    state.imgSurfer.data = ele
                    state.name_EN = 'pre1h'
                  }
                  break
              }
            })
          }
          state.imgSurfer.dataSource = res.data.DataSurfers
          if (state.imgSurfer.imageLayer === '') {
            state.imgSurfer.imageLayer = new window.AMap.ImageLayer({
              url: state.imgSurfer.data.ImgSurfers[0].Src,
              opacity: 1,
              zIndex: 98,
              bounds: new window.AMap.Bounds(
                [state.imgSurfer.data.XMin, state.imgSurfer.data.YMin],
                [state.imgSurfer.data.XMax, state.imgSurfer.data.YMax]
              )
            })
            // 将图层添加至地图实例
            state.map.add(state.imgSurfer.imageLayer)
          }
        } catch (error) {
          console.log(error)
        }
      })
    }
    // 自动执行
    const autoPlay = () => {
      if (state.userConfig.ImgRadarState && state.timeSum % (state.imgRadar.timeSpan * 10) === 0 && state.imgRadar.play) {
        imgRadarNext()
      }
      if (state.gridData.eleType !== '' && state.timeSum % (state.gridData.timeSpan * 10) === 0 && state.gridData.play) {
        gridNext()
      }
      if (state.userConfig.ImgSatelliteState && state.timeSum % (state.imgSatellite.timeSpan * 10) === 0 && state.imgSatellite.play) {
        imgSatelliteNext()
      }
      if ((state.userConfig.ImgSurferTemState || state.userConfig.ImgSurferPreState) && state.timeSum % (state.imgSurfer.timeSpan * 10) === 0 && state.imgSurfer.play) {
        imgSurferPlay()
      }

      // 每3分钟更新一次
      if (state.timeSum > 0 && state.timeSum % 3600 === 0) {
        if (state.userConfig.WarningState) {
          loadWarning()
        }
        if (state.userConfig.ImgRadarState) {
          loadRadar()
        }
        if (state.gridData.eleType !== '') {
          loadNafp()
        }
        if (state.userConfig.ImgSatelliteState) {
          loadSatellite()
        }
        if (state.userConfig.ImgSurferTemState || state.userConfig.ImgSurferPreState) {
          loadSurfer()
        }
        if (state.appFunSetup.MdlStaBit && state.userConfig.MdlStaDisplay) {
          SetMdlStormEventsData()
        }
      }
      state.timeSum += 5
      setTimeout(function () { autoPlay() }, 500)
    }
    // 显示下一时次格点数据
    const gridNext = () => {
      let index = state.gridData.index
      if (index >= state.gridData.data.length - 1) {
        gridShow(0)
      } else {
        index = index + 1
        gridShow(index)
      }
    }
    // 显示上一时次格点数据
    const gridBack = () => {
      let index = state.gridData.index
      if (index <= 0) {
        gridShow(state.gridData.data.length - 1)
      } else {
        index = index - 1
        gridShow(index)
      }
    }
    // 显示格点数据
    const gridShow = (indexIn) => {
      removeGrid()
      const markers = []
      const data = state.gridData.data
      state.gridData.index = indexIn
      state.gridData.dataTime = data[indexIn].DataTime.slice(5, 16)
      data[indexIn].Points.forEach(function (ele, index, arr) {
        const text = new window.AMap.LabelMarker({
          position: [ele.Lon, ele.Lat],
          zooms: [5, 20],
          opacity: 1,
          zIndex: 16,
          text: {
            content: ele.Vals[0] + '',
            direction: 'right',
            offset: [-20, -5],
            style: {
              fontSize: 12,
              fontWeight: 'normal',
              fillColor: '#ffffff',
              strokeColor: '#033991',
              strokeWidth: 2,
              padding: '2, 5'
            }
          }
        })

        markers.push(text)
      })
      state.gridData.markers = markers
      state.map.add(markers)
    }
    // 移除格点
    const removeGrid = () => {
      if (state.gridData.markers.length > 0) {
        state.map.remove(state.gridData.markers)
      }
    }
    // 下一张雷达图
    const imgRadarNext = () => {
      if (state.imgRadar.data.length > 0) {
        if (state.imgRadar.index >= state.imgRadar.data.length - 1) {
          state.imgRadar.index = 0
        } else {
          state.imgRadar.index++
        }
        state.imgRadar.imageLayer.setImageUrl(state.imgRadar.data[state.imgRadar.index].Src)
      }
    }
    // 上一张雷达图
    const imgRadarBack = () => {
      if (state.imgRadar.data.length > 0) {
        if (state.imgRadar.index === 0) {
          state.imgRadar.index = state.imgRadar.data.length - 1
        } else {
          state.imgRadar.index--
        }
        state.imgRadar.imageLayer.setImageUrl(state.imgRadar.data[state.imgRadar.index].Src)
      }
    }
    // 下一张云图
    const imgSatelliteNext = () => {
      if (state.imgSatellite.data.length > 0) {
        if (state.imgSatellite.index >= state.imgSatellite.data.length - 1) {
          state.imgSatellite.index = 0
        } else {
          state.imgSatellite.index++
        }
        state.imgSatellite.imageLayer.setImageUrl(state.imgSatellite.data[state.imgSatellite.index].Src)
      }
    }
    // 上一张云图
    const imgSatelliteBack = () => {
      if (state.imgSatellite.data.length > 0) {
        if (state.imgSatellite.index === 0) {
          state.imgSatellite.index = state.imgSatellite.data.length - 1
        } else {
          state.imgSatellite.index--
        }
        state.imgSatellite.imageLayer.setImageUrl(state.imgSatellite.data[state.imgSatellite.index].Src)
      }
    }
    // 播放色斑图
    const imgSurferPlay = () => {
      if (state.imgSurfer.data !== '' && state.imgSurfer.data.ImgSurfers.length > 0) {
        // 跳过关掉的要素剩余帧
        switch (state.imgSurfer.data.Name_EN) {
          case 'tem':
            state.imgSurfer.lvl.name = '气温'
            state.imgSurfer.lvl.unit = '℃'
            if (!state.userConfig.ImgSurferTemState) {
              setImgSurferData(state.imgSurfer.eleIndex)
              state.imgSurfer.index = -1
            }
            break
          case 'pre1h':
            state.imgSurfer.lvl.name = '降水'
            state.imgSurfer.lvl.unit = 'mm'
            if (!state.userConfig.ImgSurferPreState) {
              setImgSurferData(state.imgSurfer.eleIndex)
              state.imgSurfer.index = -1
            }
            break
        }
        // 下一帧
        if (state.imgSurfer.index >= state.imgSurfer.data.ImgSurfers.length - 1) {
          setImgSurferData(state.imgSurfer.eleIndex)
          state.imgSurfer.index = 0
        } else {
          state.imgSurfer.index++
        }
        state.imgSurfer.imageLayer.setImageUrl(state.imgSurfer.data.ImgSurfers[state.imgSurfer.index].Src)
        state.imgSurfer.lvl.color = state.imgSurfer.data.ImgSurfers[state.imgSurfer.index].Colors.split(',').reverse()
        state.imgSurfer.lvl.val = state.imgSurfer.data.ImgSurfers[state.imgSurfer.index].Values.split(',').reverse()
      }
    }
    // 选择色斑图要素
    const setImgSurferData = (eleIndex) => {
      let index
      if (eleIndex >= state.imgSurfer.dataSource.length - 1) {
        index = 0
      } else {
        index = eleIndex + 1
      }

      switch (state.imgSurfer.dataSource[index].Name_EN) {
        case 'tem':
          if (state.userConfig.ImgSurferTemState) {
            state.imgSurfer.data = state.imgSurfer.dataSource[index]
            state.name_EN = 'tem'
            state.imgSurfer.eleIndex = index
          } else {
            setImgSurferData(index)
          }
          break
        case 'pre1h':
          if (state.userConfig.ImgSurferPreState) {
            state.imgSurfer.data = state.imgSurfer.dataSource[index]
            state.name_EN = 'pre1h'
            state.imgSurfer.eleIndex = index
          } else {
            setImgSurferData(index)
          }
          break
      }
    }
    // 下一张色斑图
    const imgSurferNext = () => {
      if (state.imgSurfer.data.ImgSurfers.length > 0) {
        if (state.imgSurfer.index >= state.imgSurfer.data.ImgSurfers.length - 1) {
          state.imgSurfer.index = 0
        } else {
          state.imgSurfer.index++
        }
        state.imgSurfer.imageLayer.setImageUrl(state.imgSurfer.data.ImgSurfers[state.imgSurfer.index].Src)
      }
    }
    // 上一张色斑图
    const imgSurferBack = () => {
      if (state.imgSurfer.data.ImgSurfers.length > 0) {
        if (state.imgSurfer.index === 0) {
          state.imgSurfer.index = state.imgSurfer.data.ImgSurfers.length - 1
        } else {
          state.imgSurfer.index--
        }
        state.imgSurfer.imageLayer.setImageUrl(state.imgSurfer.data.ImgSurfers[state.imgSurfer.index].Src)
      }
    }
    // 获取设置时间的0.5秒倍数
    const verify05 = (val) => {
      if (val <= 0) {
        return 0.5
      } else {
        return val - val % 0.5
      }
    }
    // 控制自动站显示
    const ctrlStaDis = (staType) => {
      const arr = state.userConfig.StationDisplay.split(',')
      if (contains(state.userConfig.StationDisplay, staType)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === staType) {
            arr.splice(i, 1)
          }
        }
      } else {
        arr.push(staType)
      }
      state.userConfig.StationDisplay = arr.join()
      setStaMarker(props.dataMetars)
      saveUserConfig()
    }
    // 控制自定义站显示
    const ctrlCusStaDis = (staType) => {
      staType = staType + ''
      const arr = state.userConfig.CusStationDisplay.split(',')
      if (contains(state.userConfig.CusStationDisplay, staType)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === staType) {
            arr.splice(i, 1)
          }
        }
      } else {
        arr.push(staType)
      }
      state.userConfig.CusStationDisplay = arr.join()
      setCusStaMarker(props.dataCusMetars)
      saveUserConfig()
    }
    // 控制雷场站显示
    const ctrlMdlStaDis = () => {
      const mdlStaDisplay = state.userConfig.MdlStaDisplay
      if (mdlStaDisplay) {
        state.mdlStaMarkers.forEach(function (ele, index, arr) {
          ele.remove()
        })
      } else {
        setMdlStaMarker()
      }
      state.userConfig.MdlStaDisplay = !mdlStaDisplay
      saveUserConfig()
    }
    // 控制显示等级
    const ctrlThrDis = (thrType) => {
      const arr = state.userConfig.ThresholdDisplay.split(',')
      if (contains(state.userConfig.ThresholdDisplay, thrType)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === thrType) {
            arr.splice(i, 1)
          }
        }
      } else {
        arr.push(thrType)
      }
      state.userConfig.ThresholdDisplay = arr.join()
      setStaMarker(props.dataMetars)
      setCusStaMarker(props.dataCusMetars)
      saveUserConfig()
    }
    // 控制显示要素
    const ctrlSurfEleDis = (eleType) => {
      const arr = state.userConfig.SurfEleDisplay.split(',')
      if (contains(state.userConfig.SurfEleDisplay, eleType)) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] === eleType) {
            arr.splice(i, 1)
          }
        }
      } else {
        arr.push(eleType)
      }
      state.userConfig.SurfEleDisplay = arr.join()
      setStaMarker(props.dataMetars)
      setCusStaMarker(props.dataCusMetars)
      saveUserConfig()
    }
    // 控制雷达图显示
    const ctrlRadarDis = () => {
      state.mapStyle = 'map'
      state.userConfig.ImgRadarState = !state.userConfig.ImgRadarState
      if (state.userConfig.ImgRadarState) {
        state.userConfig.ImgSatelliteState = false
        if (state.imgSatellite.imageLayer !== '') {
          state.imgSatellite.imageLayer.setMap(null)
          state.imgSatellite.imageLayer = ''
          state.imgSatellite.data = []
          state.imgSatellite.index = 0
        }
        loadRadar()
      } else {
        if (state.imgRadar.imageLayer !== '') {
          state.imgRadar.imageLayer.setMap(null)
          state.imgRadar.imageLayer = ''
          state.imgRadar.data = []
          state.imgRadar.index = 0
        }
      }
      saveUserConfig()
    }
    // 控制云图显示
    const ctrlSatelliteDis = () => {
      state.userConfig.ImgSatelliteState = !state.userConfig.ImgSatelliteState
      if (state.userConfig.ImgSatelliteState) {
        state.userConfig.ImgRadarState = false
        if (state.imgRadar.imageLayer !== '') {
          state.imgRadar.imageLayer.setMap(null)
          state.imgRadar.imageLayer = ''
          state.imgRadar.data = []
          state.imgRadar.index = 0
        }
        state.mapStyle = 'earth'
        loadSatellite()
      } else {
        if (state.imgSatellite.imageLayer !== '') {
          state.imgSatellite.imageLayer.setMap(null)
          state.imgSatellite.imageLayer = ''
          state.imgSatellite.data = []
          state.imgSatellite.index = 0
        }
        state.mapStyle = 'map'
      }
      saveUserConfig()
    }
    const ctrlNafpDis = (ele, eleName, timeDelt) => {
      if (state.gridData.eleType === ele) {
        state.gridData.eleType = ''
        state.gridData.eleTypeName = ''
        state.gridData.timeDelt = ''
        removeGrid()
      } else {
        state.gridData.eleType = ele
        state.gridData.eleTypeName = eleName
        state.gridData.timeDelt = timeDelt
      }

      loadNafp()
    }
    // 控制气温色斑图显示
    const ctrlSurferTemDis = () => {
      state.userConfig.ImgSurferTemState = !state.userConfig.ImgSurferTemState
      if (state.userConfig.ImgSurferTemState) {
        if (state.imgSurfer.imageLayer === '') {
          loadSurfer()
        }
      }
      if (!state.userConfig.ImgSurferTemState && !state.userConfig.ImgSurferPreState) {
        closeImgSurfer()
      }
      saveUserConfig()
    }
    // 控制降水色斑图显示
    const ctrlSurferPreDis = () => {
      state.userConfig.ImgSurferPreState = !state.userConfig.ImgSurferPreState
      if (state.userConfig.ImgSurferPreState) {
        if (state.imgSurfer.imageLayer === '') {
          loadSurfer()
        }
      }
      if (!state.userConfig.ImgSurferTemState && !state.userConfig.ImgSurferPreState) {
        closeImgSurfer()
      }
      saveUserConfig()
    }
    // 关闭色斑图
    const closeImgSurfer = () => {
      state.imgSurfer.data = ''
      state.imgSurfer.eleIndex = 0
      state.imgSurfer.index = 0
      if (state.imgSurfer.imageLayer !== '') {
        state.imgSurfer.imageLayer.setMap(null)
        state.imgSurfer.imageLayer = ''
      }
    }
    // 控制预警
    const ctrlWarningDis = () => {
      state.userConfig.WarningState = !state.userConfig.WarningState
      if (state.userConfig.WarningState) {
        loadWarning()
      }
      saveUserConfig()
    }
    // 初始化视距
    const setZoomAndCenter = () => {
      state.map.setZoomAndCenter(state.appMapSetup.Zoom, [state.appMapSetup.CenterLon, state.appMapSetup.CenterLat])
    }
    const formDisplay = (e) => {
      let marginFir = ''
      let marginSec = ''
      let ele = e.target
      if (ele.tagName === 'LI') {
        ele = ele.getElementsByTagName('I')[0]
      }
      if (state.userConfig.UnfoldBtnState) {
        state.userConfig.UnfoldBtnState = false
        ele.innerHTML = '&#xe606;'
        state.formDisplayText = '显示表单'
        marginFir = '-500px'
        marginSec = '20px'
      } else {
        state.userConfig.UnfoldBtnState = true
        ele.innerHTML = '&#xe605;'
        state.formDisplayText = '隐藏表单'
        marginFir = '20px'
        marginSec = '520px'
      }
      const swsLeft = document.getElementsByClassName('sws-left')
      for (let i = 0; i < swsLeft.length; i++) {
        swsLeft[i].style.left = marginFir
      }
      const swsRight = document.getElementsByClassName('sws-right')
      for (let i = 0; i < swsRight.length; i++) {
        swsRight[i].style.right = marginFir
      }
      const swsToolPanel = document.getElementsByClassName('sws-tool-panel')
      for (let i = 0; i < swsToolPanel.length; i++) {
        swsToolPanel[i].style.left = marginSec
      }
      const swsToolBottom = document.getElementsByClassName('sws-tool-bottom')
      for (let i = 0; i < swsToolBottom.length; i++) {
        swsToolBottom[i].style.left = marginSec
      }
      const swsLvlWrap = document.getElementsByClassName('sws-lvl-wrap')
      for (let i = 0; i < swsLvlWrap.length; i++) {
        swsLvlWrap[i].style.right = marginSec
      }
      const swsMapToolPanel = document.getElementsByClassName('sws-maptool-panel')
      for (let i = 0; i < swsMapToolPanel.length; i++) {
        swsMapToolPanel[i].style.right = marginSec
      }
      const swsMapToolPanel2 = document.getElementsByClassName('sws-maptool-panel-2')
      for (let i = 0; i < swsMapToolPanel2.length; i++) {
        swsMapToolPanel2[i].style.right = marginSec
      }
    }
    const unfold = () => {
      if (state.unfoldState.state) {
        state.unfoldState.state = false
        state.unfoldState.icon = '&#xe622;'
        state.unfoldState.width = '110px'
      } else {
        state.unfoldState.state = true
        state.unfoldState.icon = '&#xe603;'
        state.unfoldState.width = '30px'
      }
    }
    const closeStationInfo = () => {
      state.stationInfo.staID = ''
    }
    // 判断是否包含英文字符
    const isContainsEng = (str) => {
      if (str == null || str === '') return false
      return (/[a-z]/i).test(str)
    }
    // 根据阈值返回颜色，顺序，适用于高温、降水、风力等
    const getColor = (val, blueVal, yelVal, orgVal, redVal) => {
      if (val >= redVal) {
        return 'red'
      } else if (val >= orgVal) {
        return 'org'
      } else if (val >= yelVal) {
        return 'yel'
      } else if (val >= blueVal) {
        return 'blu'
      } else {
        return 'gre'
      }
    }
    // 根据阈值返回颜色，倒序，适用于低温、湿度、能见度等
    const getColorDesc = (val, blueVal, yelVal, orgVal, redVal) => {
      if (val <= redVal) {
        return 'red'
      } else if (val <= orgVal) {
        return 'org'
      } else if (val <= yelVal) {
        return 'yel'
      } else if (val <= blueVal) {
        return 'blu'
      } else {
        return 'gre'
      }
    }
    // 获取阈值开关状态
    const getThr = (bit, color) => {
      let res = -1
      if (bit) {
        switch (color) {
          case 'blu':
            res = state.userConfig.ThresholdDisplay.indexOf('blu')
            break
          case 'yel':
            res = state.userConfig.ThresholdDisplay.indexOf('yel')
            break
          case 'org':
            res = state.userConfig.ThresholdDisplay.indexOf('org')
            break
          case 'red':
            res = state.userConfig.ThresholdDisplay.indexOf('red')
            break
        }
      }

      if (res === -1) {
        return false
      } else {
        return true
      }
    }
    const showPointicon = (e) => {
      const tar = document.getElementsByClassName('sws-tool-pointicon-wrap')[0]
      const ele = document.getElementsByClassName('sws-tool-pointicon')[0]
      tar.style.height = (ele.scrollHeight + 38 + 20) + 'px'
    }
    const hidePointicon = (e) => {
      const tar = document.getElementsByClassName('sws-tool-pointicon-wrap')[0]
      tar.style.height = '38px'
    }
    // 是否包含
    const contains = (arr, str) => {
      try {
        if (arr === null) {
          return false
        }
        if (typeof (arr) === 'string') {
          arr = arr.split(',')
        }
        if (str === '' || arr.indexOf(str) !== -1) {
          return true
        } else {
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    }
    const saveChatConfig = (chatConfig) => {
      state.userConfig.ChatMetarDisplay = chatConfig.chatMetarDisplay
      state.userConfig.ChatWarningDisplay = chatConfig.chatWarningDisplay
      saveUserConfig()
    }
    // 保存用户数据
    const saveUserConfig = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        data: state.userConfig,
        url: 'https://swsapi.bjzkzm.com/UserConfig/Save'
      }).then(function (res) {
      })
    }
    // 加载边界线
    const loadMapPath = () => {
      const appMapSetup = state.appMapSetup
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/datamap/mappath'
      }).then(function (res) {
        const data = res.data
        for (let i = 0, m = data.Blanks.length; i < m; i++) {
          // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
          const path = []
          for (let j = 0, n = data.Blanks[i].Lons.length; j < n; j++) {
            path.push(new window.AMap.LngLat(data.Blanks[i].Lons[j], data.Blanks[i].Lats[j]))
          }
          // 创建折线实例
          const polyline = new window.AMap.Polyline({
            path: path,
            strokeWeight: appMapSetup.PathCoverStrokeWeight, // 线条宽度，默认为 1
            strokeColor: appMapSetup.PathCoverStrokeColor, // 线条颜色
            strokeOpacity: appMapSetup.PathCoverStrokeOpacity,
            lineJoin: 'round' // 折线拐点连接处样式
          })

          // 将折线添加至地图实例
          state.map.add(polyline)
        }

        for (let i = 0, m = data.Mains.length; i < m; i++) {
          // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
          const path = []
          for (let j = 0, n = data.Mains[i].Lons.length; j < n; j++) {
            path.push(new window.AMap.LngLat(data.Mains[i].Lons[j], data.Mains[i].Lats[j]))
          }
          // 创建折线实例
          const polygon = new window.AMap.Polygon({
            path: path,
            fillColor: appMapSetup.PathMainFill, // 多边形填充颜色
            fillOpacity: appMapSetup.PathMainFillOpacity,
            strokeWeight: appMapSetup.PathMainStrokeWeight, // 线条宽度，默认为 1
            strokeColor: appMapSetup.PathMainStrokeColor, // 线条颜色
            strokeOpacity: appMapSetup.PathMainStrokeOpacity
          })

          // 将折线添加至地图实例
          state.map.add(polygon)
        }

        if (data.Bln50.length > 0) {
          for (let i = 0, m = data.Bln50.length; i < m; i++) {
            // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
            const path = []
            for (let j = 0, n = data.Bln50[i].Lons.length; j < n; j++) {
              path.push(new window.AMap.LngLat(data.Bln50[i].Lons[j], data.Bln50[i].Lats[j]))
            }
            console.log(path)
            // 创建折线实例
            const polyline = new window.AMap.Polyline({
              path: path,
              strokeWeight: 2, // 线条宽度，默认为 1
              strokeColor: 'rgba(255,0,0,1)', // 线条颜色
              lineJoin: 'round' // 折线拐点连接处样式
            })
            // 将折线添加至地图实例
            state.map.add(polyline)
          }
        }

        if (data.Bln100.length > 0) {
          for (let i = 0, m = data.Bln100.length; i < m; i++) {
            // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
            const path = []
            for (let j = 0, n = data.Bln100[i].Lons.length; j < n; j++) {
              path.push(new window.AMap.LngLat(data.Bln100[i].Lons[j], data.Bln100[i].Lats[j]))
            }
            // 创建折线实例
            const polyline = new window.AMap.Polyline({
              path: path,
              strokeWeight: 2, // 线条宽度，默认为 1
              strokeColor: 'rgba(255,200,0,1)', // 线条颜色
              lineJoin: 'round' // 折线拐点连接处样式
            })
            // 将折线添加至地图实例
            state.map.add(polyline)
          }
        }
      })
    }
    // 加载地图设置并加载地图
    const loadAppFunSetup = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/other/mapsetup'
      }).then(function (res) {
        state.appFunSetup = res.data.AppFunSetup
        state.appMapSetup = res.data.AppMapSetup
        state.cusStationTypes = res.data.CusStationTypes
        state.userConfig = res.data.UserConfig
        // DOM初始化完成进行地图初始化
        ininMap()
      })
    }
    const openStationInfo = (staID, type) => {
      const openStation = {
        staID: staID,
        type: type
      }
      store.commit('setOpenStation', openStation)
    }
    watch(() => store.state.openStation.staID, (newValue) => {
      if (newValue === '') {
        state.stationInfo.staID = ''
      } else {
        const openStation = store.getters.getOpenStation
        let stationInfos
        if (openStation.type === 'cus') {
          stationInfos = store.getters.getCusStationInfos
        } else {
          stationInfos = store.getters.getStationInfos
        }

        stationInfos.filter(tem => tem.Station_ID_C === openStation.staID).forEach(function (ele, index, arr) {
          const stationInfo = {
            staID: ele.Station_ID_C,
            staName: ele.Station_Name,
            lon: ele.Lon,
            lat: ele.Lat,
            type: openStation.type,
            key: state.stationInfo.key + 1
          }
          emit('closeData')
          state.map.setCenter([stationInfo.lon, stationInfo.lat])
          state.stationInfo = stationInfo
        })
      }
    })
    watch(() => state.mapStyle, (val) => {
      if (val === 'map') {
        state.map.remove(window.satelliteLayer)
      } else if (val === 'earth') {
        state.map.add(window.satelliteLayer)
      }
    })
    watch(() => props.dataMetars.UpdateTime, () => {
      setStaMarker(props.dataMetars)
    })
    watch(() => props.dataCusMetars.UpdateTime, () => {
      setCusStaMarker(props.dataCusMetars)
    })
    onMounted(() => {
      loadAppFunSetup()
    })
    return {
      ...toRefs(state),
      openStationInfo,
      setStaMarker,
      setCusStaMarker,
      setMdlStaMarker,
      setStaLableMarker,
      ctrlMdlStaDis,
      ctrlStaDis,
      ctrlCusStaDis,
      ctrlThrDis,
      ctrlSurfEleDis,
      ctrlRadarDis,
      ctrlSurferTemDis,
      ctrlSurferPreDis,
      ctrlSatelliteDis,
      ctrlNafpDis,
      ctrlWarningDis,
      loadMapLayer,
      ctrlMapLayer,
      formDisplay,
      setZoomAndCenter,
      unfold,
      closeStationInfo,
      getColor,
      getColorDesc,
      showPointicon,
      hidePointicon,
      contains,
      loadMapPath,
      loadRadar,
      loadSatellite,
      loadSurfer,
      autoPlay,
      imgRadarNext,
      imgRadarBack,
      gridNext,
      gridBack,
      gridShow,
      saveChatConfig,
      imgSatelliteNext,
      imgSatelliteBack,
      imgSurferPlay,
      imgSurferNext,
      imgSurferBack,
      verify05
    }
  },
  create () {
  },
  methods: {

  },
  mounted () {
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/mapcontainer.scss";  //引入方式
</style>
