export default {
  datetimeToStrDate (time) {
    const date = time.getFullYear() + '-' + this.prefixInteger(time.getMonth() + 1, 2) + '-' + this.prefixInteger(time.getDate(), 2) + ' ' + this.prefixInteger(time.getHours(), 2) + ':' + this.prefixInteger(time.getMinutes(), 2) + ':' + this.prefixInteger(time.getSeconds(), 2)
    return date
  },
  timeToStrDate (time) {
    time = time.replace('/Date(', '')
    time = time.replace(')/', '')
    time = new Date(time - 0)
    const date = time.getFullYear() + '-' + this.prefixInteger(time.getMonth() + 1, 2) + '-' + this.prefixInteger(time.getDate(), 2) + ' ' + this.prefixInteger(time.getHours(), 2) + ':' + this.prefixInteger(time.getMinutes(), 2) + ':' + this.prefixInteger(time.getSeconds(), 2)
    return date
  },
  prefixInteger (num, n) {
    return (Array(n).join(0) + num).slice(-n)
  },
  // 正序
  sortAsc (data, key) {
    const array = JSON.parse(JSON.stringify(data))
    return array.sort(function (a, b) {
      const x = a[key] - 0
      const y = b[key] - 0
      return ((x < y) ? -1 : (x > y) ? 1 : 0)
    })
  },
  // 倒序
  sortDesc (data, key) {
    const array = JSON.parse(JSON.stringify(data))
    return array.sort(function (a, b) {
      const x = a[key] - 0
      const y = b[key] - 0
      return ((x > y) ? -1 : (x < y) ? 1 : 0)
    })
  },
  // 平均值 neglect为true时忽略数据为0的对象
  avgVal (array, key, neglect = true) {
    let total = 0
    let ii = 0
    array = neglect ? array.filter(tem => tem[key] < 900000 && tem[key] !== 0) : array.filter(tem => tem[key] < 900000)
    array.forEach(function (ele, index, arr) {
      total += ele[key]
      ii++
    })
    return (total / ii).toFixed(1)
  },
  // 最大值
  maxVal (array, key, neglect = true) {
    array = neglect ? array.filter(tem => tem[key] < 900000 && tem[key] !== 0) : array.filter(tem => tem[key] < 900000)
    if (array.length === 0) {
      return 0
    } else {
      return Math.max.apply(Math, array.map(function (o) { return o[key] }))
    }
  },
  // 最小值
  minVal (array, key, neglect = true) {
    array = neglect ? array.filter(tem => tem[key] < 900000 && tem[key] !== 0) : array.filter(tem => tem[key] < 900000)
    if (array.length === 0) {
      return 0
    } else {
      return Math.min.apply(Math, array.map(function (o) { return o[key] }))
    }
  },
  // 最大值
  maxObj (data, key, neglect = true) {
    let array = JSON.parse(JSON.stringify(data))
    array = neglect ? array.filter(tem => tem[key] < 900000 && tem[key] !== 0) : array.filter(tem => tem[key] < 900000)
    array = this.sortDesc(array, key)
    return array[0]
  },
  // 最小值
  minObj (data, key, neglect = true) {
    let array = JSON.parse(JSON.stringify(data))
    array = neglect ? array.filter(tem => tem[key] < 900000 && tem[key] !== 0) : array.filter(tem => tem[key] < 900000)
    array = this.sortAsc(array, key)
    return array[0]
  },
  // 风速转风力
  getWindPower (speed) {
    if (speed >= 999900) {
      return '--'
    }
    if (speed <= 0.2) {
      return '静风'
    } else if (speed <= 1.5) {
      return 1
    } else if (speed <= 3.3) {
      return 2
    } else if (speed <= 5.4) {
      return 3
    } else if (speed <= 7.9) {
      return 4
    } else if (speed <= 10.7) {
      return 5
    } else if (speed <= 13.8) {
      return 6
    } else if (speed <= 17.1) {
      return 7
    } else if (speed <= 20.7) {
      return 8
    } else if (speed <= 24.4) {
      return 9
    } else if (speed <= 28.4) {
      return 10
    } else if (speed <= 32.6) {
      return 11
    } else if (speed <= 36.9) {
      return 12
    } else if (speed <= 41.4) {
      return 13
    } else if (speed <= 46.1) {
      return 14
    } else if (speed <= 50.9) {
      return 15
    } else if (speed <= 56.0) {
      return 16
    } else if (speed <= 61.2) {
      return 17
    } else {
      return 18
    }
  },
  // 风向数值转文字描述（8向）
  getWindDirTxt8 (windD) {
    if (windD <= 22.5 || (windD >= 337.6 && windD <= 360)) {
      return '北风'
    } else if (windD <= 67.5) {
      return '东北风'
    } else if (windD <= 112.5) {
      return '东风'
    } else if (windD <= 157.5) {
      return '东南风'
    } else if (windD <= 202.5) {
      return '南风'
    } else if (windD <= 247.5) {
      return '西南风'
    } else if (windD <= 292.5) {
      return '西风'
    } else if (windD <= 337.5) {
      return '西北风'
    } else {
      return ''
    }
  },
  // 是否包含
  contains (arr, str) {
    try {
      if (typeof (arr) === 'string') {
        arr = arr.split(',')
      }
      if (str === '' || arr.indexOf(str) !== -1) {
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  },
  JudgeNum (num, n) {
    if (num - 0 >= 900000) {
      return '--'
    } else {
      if (n === '') {
        return num
      } else {
        return (num - 0).toFixed(n)
      }
    }
  },
  metarDataToSvgData (data) {
    const d = {
      dataLength: 0,
      updateTime: [],
      tem: {
        name: '气温',
        data: []
      },
      win: {
        name: '极大风',
        data_s: [],
        data_d: []
      },
      pre: {
        name: '降水',
        data: []
      }
    }

    d.dataLength = data.length
    for (let i = 0, n = data.length; i < n; i++) {
      d.updateTime.push(data[i].UpdateTime)
      d.tem.data.push(data[i].TEM)
      d.win.data_s.push(data[i].WIN_S_INST_Max)
      d.win.data_d.push(data[i].WIN_D_INST_Max || 999999)
      d.pre.data.push(data[i].PRE_1h)
    }

    return d
  },
  makeSvgMetarHour (width, height, data) {
    const arrTime = []
    const left = 40
    const right = 100
    const top = 40
    const bottom = 30
    let max = 0
    let min = 0
    let baseVal = 0 // 底数
    let content = '<svg id="stationMetar_img" version="1.1" xmlns="http://www.w3.org/2000/svg" style="width:' + width + 'px;height:' + height + 'px;">'
    content += '<text x="' + (left - 5) + '" y="25" fill="#f08300" text-anchor="end" style="font-size:14px;">' + data.tem.name + '</text>'
    content += '<text x="' + (width - right + 5) + '" y="25" fill="#7a8ab5" text-anchor="start" style="font-size:14px;">' + data.pre.name + '</text>'
    content += '<text x="' + (width - right + 50) + '" y="25" fill="#27b92d" text-anchor="start" style="font-size:14px;">' + data.win.name + '</text>'
    const xPer = (width - left - right) / (data.dataLength)
    const yPer = ((height - top - bottom) / 4).toFixed(0) - 0

    for (let i = 4; i >= 0; i--) {
      content += '<line x1="' + left + '" y1="' + (i * yPer + top) + '" x2="' + (width - right) + '" y2="' + (i * yPer + top) + '" stroke="rgba(255,255,255,0.3)" stroke-width="1" transform="translate(0.5 0.5)"></line>'
    }
    let ii = data.dataLength - 1
    let showDate = 2
    if (data.updateTime.some(tem => new Date(tem).getHours() === 0)) {
      showDate = 0
    }
    for (let i = 0; i < data.dataLength; i++) {
      const time = new Date(data.updateTime[ii])
      const xTime = time.getHours() === showDate ? time.getDate() + '日' : time.getHours()
      content += '<text x="' + (i * xPer + xPer / 2 + left) + '" y="' + (height - bottom + 20) + '" fill="rgba(255,255,255,0.6)" text-anchor="middle" style="font-size:12px;">' + xTime + '</text>'
      arrTime.push(new Date(data.updateTime[ii]))
      ii--
    }

    let temBit = false // 是否插入气温折线
    let preBit = false // 是否插入降水折线
    let winBit = false // 是否插入风向风力折线
    const arrTem = [] // 气温值数组
    const arrPre = [] // 降水值数组
    const arrWin = [] // 风向风力值数组
    const arrDir = [] // 风向风力值数组

    for (let i = 0, n = data.dataLength; i < n; i++) {
      if (data.tem.data[i] !== '' && data.tem.data[i] < 900000) {
        temBit = true
        arrTem.unshift(data.tem.data[i])
      } else {
        arrTem.unshift('')
      }

      if (data.pre.data[i] !== '' && data.pre.data[i] < 900000) {
        preBit = true
        arrPre.unshift(data.pre.data[i])
      } else {
        arrPre.unshift('')
      }

      if (data.win.data_s[i] !== '' && data.win.data_s[i] < 900000) {
        winBit = true
        arrWin.unshift(this.getWindPower(data.win.data_s[i]))
        arrDir.unshift(data.win.data_d[i])
      } else {
        arrWin.unshift('')
        arrDir.unshift('')
      }
    }

    // if (arrTem.length > 25) {
    //   arrTem = arrTem.slice(arrTem.length - 25, arrTem.length)
    //   arrPre = arrPre.slice(arrTem.length - 25, arrTem.length)
    //   arrWin = arrWin.slice(arrTem.length - 25, arrTem.length)
    // }

    // 降水柱图
    if (preBit) {
      max = Math.max(...arrPre)
      min = 0

      baseVal = 0 // 底数
      let levelVal = Math.round(max / 7 * 3)
      levelVal = levelVal < 1 ? 1 : levelVal

      if (levelVal > 50 && levelVal < 60) {
        levelVal = 50
      } else if (levelVal > 40 && levelVal < 50) {
        levelVal = 40
      } else if (levelVal > 30 && levelVal < 40) {
        levelVal = 30
      } else if (levelVal > 20 && levelVal < 30) {
        levelVal = 20
      } else if (levelVal > 15 && levelVal < 20) {
        levelVal = 15
      } else if (levelVal > 10 && levelVal < 15) {
        levelVal = 10
      }

      const topVal = levelVal * 4 // 顶数
      const perVal = (height - top - bottom) / (topVal - baseVal) // 每摄氏度高度

      // y轴数值
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (width - right + 5) + '" y="' + (i * yPer + 45) + '" fill="#7a8ab5" text-anchor="start" style="font-size:12px;">' + (topVal - levelVal * i) + ' mm</text>'
      }

      let n = -1
      for (let i = 0; i < arrPre.length; i++) {
        n++
        const x = xPer * n + left

        if (arrPre[i] === '') {
          continue
        }

        const preHeight = arrPre[i] * perVal
        const time = arrTime[i]

        content += '<rect x="' + (x + xPer * 0.1) + '" y="' + (top + yPer * 4 - preHeight) + '" width="' + (xPer * 0.8) + '" height="' + preHeight + '" fill="rgba(255,255,255,0.15)" stroke-width="0" stroke="none"  data-time="' + (time.getMonth() + 1) + '月' + time.getDate() + '日' + time.getHours() + '时' + '" data-value="' + arrPre[i] + '" data-unit="mm"></rect>'
      }
    } else {
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (width - right + 5) + '" y="' + (i * yPer + 45) + '" fill="#7a8ab5" text-anchor="start" style="font-size:12px;">' + (4 - 1 * i) + ' mm</text>'
      }
    }

    // 风向风力折线
    if (winBit) {
      max = Math.max(...arrWin)
      min = 0
      let topVal
      let levelVal

      if (max <= 6) {
        topVal = 8 // 顶数
        levelVal = 2 // 每级数
      } else if (max <= 9) {
        topVal = 12 // 顶数
        levelVal = 3 // 每级数
      } else if (max <= 12) {
        topVal = 16 // 顶数
        levelVal = 4 // 每级数
      } else {
        topVal = 20 // 顶数
        levelVal = 5 // 每级数
      }

      baseVal = 0 // 底数
      const perVal = (height - bottom - top) / (topVal - baseVal) // 每摄氏度高度

      // y轴数值
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (width - right + 50) + '" y="' + (i * yPer + 45) + '" fill="#27b92d" text-anchor="start" style="font-size:12px;">' + (topVal - levelVal * i) + ' 级</text>'
      }

      const svgPaths = this.SetPath(arrWin, xPer, baseVal, perVal, left, height, bottom)

      for (let i = 0; i < svgPaths.length; i++) {
        let strPath = ''
        for (let j = 0; j < svgPaths[i].length; j++) {
          if (j === 0) {
            strPath += ('M' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
          } else {
            strPath += ('L' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
          }
        }
        content += '<path d="' + strPath + '" fill="none" stroke="#27b92d" stroke-width="1"></path>'
      }

      let n = -1
      for (let i = 0; i < arrWin.length; i++) {
        n++
        const x = xPer * n + left + xPer / 2
        const y = (height - bottom - (arrWin[i] - baseVal) * perVal)
        if (arrDir[i] === '' || arrWin[i] === '') {
          continue
        }

        const dir = arrDir[i]
        const time = arrTime[i]

        content += '<polygon points="' + (x + ',' + (y + 7.5) + ' ' + (x - 6) + ', ' + (y - 7.5) + ' ' + x + ', ' + (y - 3) + ' ' + (x + 6) + ', ' + (y - 7.5) + ' ' + x + ', ' + (y + 7.5)) + '" transform = "translate(' + x + ',' + y + ') rotate(' + dir + ') translate(' + (-x) + ',' + (-y) + ')" fill = "#27b92d" stroke - width="0" data-time="' + (time.getMonth() + 1) + '月' + time.getDate() + '日' + time.getHours() + '时' + '" data-value="' + this.getWindDirTxt8(dir) + ' ' + arrWin[i] + '" data-unit="级"></polygon >'
      }
    } else {
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (width - right + 50) + '" y="' + (i * yPer + 45) + '" fill="#27b92d" text-anchor="start" style="font-size:12px;">' + (8 - 2 * i) + ' 级</text>'
      }
    }

    // 气温折线
    if (temBit) {
      max = Math.max(...arrTem)
      min = Math.min(...arrTem)

      baseVal = ((max - min) * 1.6 / 5)
      let levelVal

      if (Math.round((max - min) * 1.6 / 4) === 1) {
        // 计算基数
        baseVal = Math.round(min - 1)
        // 每级数值
        levelVal = 2
      } else {
        // 计算基数
        baseVal = Math.round(min - baseVal / 2)
        // 每级数值
        levelVal = Math.round((max - min) * 1.6 / 4)
      }

      if (levelVal === 0) {
        levelVal = 1
      }

      if (baseVal === Math.round(min - 0)) {
        baseVal = baseVal - 1
      }

      // 顶数
      const topVal = baseVal + levelVal * 4
      // 每摄氏度高度
      const perVal = (height - bottom - top) / (topVal - baseVal)

      // y轴数值
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (left - 5) + '" y="' + (i * yPer + 45) + '" fill="#f08300" text-anchor="end" style="font-size:12px;">' + (topVal - levelVal * i) + ' ℃</text>'
      }

      const svgPaths = this.SetPath(arrTem, xPer, baseVal, perVal, left, height, bottom)

      for (let i = 0; i < svgPaths.length; i++) {
        let strPath = ''
        for (let j = 0; j < svgPaths[i].length; j++) {
          if (j === 0) {
            strPath += ('M' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
          } else {
            strPath += ('L' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
          }
        }
        content += '<path d="' + strPath + '" fill="none" stroke="#f08300" stroke-width="1"></path>'
      }

      let n = -1
      for (let i = 0; i < arrTem.length; i++) {
        n++
        const x = xPer * n + left + xPer / 2
        const y = (height - bottom - (arrTem[i] - baseVal) * perVal)
        if (arrTem[i] === '') {
          continue
        }
        const time = arrTime[i]
        content += '<circle class="svg-tem-circle" cx="' + x + '" cy="' + y + '" r="3" fill="#f08300" stroke-width="0" data-time="' + (time.getMonth() + 1) + '月' + time.getDate() + '日' + time.getHours() + '时' + '" data-value="' + arrTem[i] + '" data-unit="℃"></circle>'
      }
    } else {
      for (let i = 0; i < 5; i++) {
        content += '<text x="' + (left - 5) + '" y="' + (i * yPer + 45) + '" fill="#f08300" text-anchor="end" style="font-size:12px;">' + (30 - 5 * i) + ' ℃</text>'
      }
    }

    content += '</svg>'
    return content
  },
  SetPath (values, xPer, baseVal, perVal, left, height, bottom) {
    let svgLocs = []
    const svgPaths = []
    let h = -1

    for (let i = 0, m = values.length; i < m; i++) {
      h++
      if (values[i] === '') {
        if (svgLocs.length > 0) {
          svgPaths.push(svgLocs)
        }
        svgLocs = []
        continue
      }

      svgLocs.push({
        X: xPer * h + left + xPer / 2,
        Y: height - bottom - (values[i] - baseVal) * perVal
      })
    }

    if (svgLocs.length > 0) {
      svgPaths.push(svgLocs)
    }

    return svgPaths
  },
  turnNum (nums) {
    return nums.map(Number)
  },
  makeMeteo7dayChart (forecasts, width, height) {
    const arrDay = []
    const arrNight = []
    let svg = '<svg width="100%" height="100%">'

    for (let i = 0; i < forecasts.length; i++) {
      if (!isNaN(forecasts[i].Tem_Day)) {
        arrDay.push(forecasts[i].Tem_Day)
      }
      if (!isNaN(forecasts[i].Tem_Night)) {
        arrNight.push(forecasts[i].Tem_Night)
      }
    }

    const max = Math.max(...arrDay)
    const min = Math.min(...arrNight)

    // 窗口宽度
    const r = 3
    const textSize = 12
    const widthPer = width / forecasts.length
    const top = 5
    const bottom = 20
    const left = 0

    let baseVal = ((max - min) * 1.6 / 5)
    let levelVal

    if (Math.round((max - min) * 1.6 / 4) === 1) {
      // 计算基数
      baseVal = Math.round(min - 1)
      // 每级数值
      levelVal = 2
    } else {
      // 计算基数
      baseVal = Math.round(min - baseVal / 2)
      // 每级数值
      levelVal = Math.round((max - min) * 1.6 / 4)
    }

    if (levelVal === 0) {
      levelVal = 1
    }

    if (baseVal === Math.round(min - 0)) {
      baseVal = baseVal - 1
    }

    // 顶数
    const topVal = baseVal + levelVal * 4
    // 每摄氏度高度
    const perVal = (height - bottom - top) / (topVal - baseVal)

    let inner = ''
    let svgPaths = this.SetPath(arrDay, widthPer, baseVal, perVal, left, height, bottom)

    for (let i = 0; i < svgPaths.length; i++) {
      let strPath = ''
      for (let j = 0; j < svgPaths[i].length; j++) {
        if (j === 0) {
          strPath += ('M' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
        } else {
          strPath += ('L' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
        }
      }
      inner += '<path d="' + strPath + '" fill="none" stroke="#fdcb01" stroke-width="1"></path>'
    }

    for (let i = 0; i < arrDay.length; i++) {
      if (arrDay[i] === '' || arrDay[i] === '999.9') {
        continue
      }

      const x = widthPer * i + left + widthPer / 2
      const y = height - bottom - (arrDay[i] - baseVal) * perVal
      inner += '<circle cx="' + x + '" cy="' + y + '" r="' + r + '" fill="#fdcb01" stroke-width="1"></circle>'
      inner += '<text x="' + x + '" y="' + (y - 8) + '" fill="rgba(255,255,255,0.6)" text-anchor="middle" style="font-size:' + textSize + 'px;">' + arrDay[i] + ' ℃</text>'
    }

    svgPaths = this.SetPath(arrNight, widthPer, baseVal, perVal, left, height, bottom)
    for (let i = 0; i < svgPaths.length; i++) {
      let strPath = ''
      for (let j = 0; j < svgPaths[i].length; j++) {
        if (j === 0) {
          strPath += ('M' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
        } else {
          strPath += ('L' + svgPaths[i][j].X + ',' + svgPaths[i][j].Y)
        }
      }
      inner += '<path d="' + strPath + '" fill="none" stroke="#91e0fc" stroke-width="1"></path>'
    }

    for (let i = 0; i < arrNight.length; i++) {
      if (arrNight[i] === '' || arrNight[i] === '999.9') {
        continue
      }

      // const x = widthPer * i + left + widthPer / 2
      // const y = height - bottom - (arrNight[i] - baseVal) * perVal
      inner += '<circle cx="' + svgPaths[0][i].X + '" cy="' + svgPaths[0][i].Y + '" r="' + r + '" fill="#91e0fc" stroke-width="1"></circle>'
      inner += '<text x="' + svgPaths[0][i].X + '" y="' + (svgPaths[0][i].Y + 18) + '" fill="rgba(255,255,255,0.6)" text-anchor="middle" style="font-size:' + textSize + 'px;">' + arrNight[i] + ' ℃</text>'
    }
    svg += inner
    svg += '</svg>'
    return svg
  },
  getTemColor (tem) {
    tem = tem - 0

    if (tem <= -48) {
      return '#330d80'
    } else if (tem <= -44) {
      return '#462192'
    } else if (tem <= -40) {
      return '#5440b6'
    } else if (tem <= -36) {
      return '#4349c9'
    } else if (tem <= -32) {
      return '#4d66d2'
    } else if (tem <= -28) {
      return '#5775d5'
    } else if (tem <= -24) {
      return '#4f97e1'
    } else if (tem <= -20) {
      return '#84b9fb'
    } else if (tem <= -16) {
      return '#6cc6ec'
    } else if (tem <= -12) {
      return '#73e1e7'
    } else if (tem <= -8) {
      return '#abf7eb'
    } else if (tem <= -4) {
      return '#cbfbda'
    } else if (tem <= 0) {
      return '#eefdca'
    } else if (tem <= 4) {
      return '#f9fad5'
    } else if (tem <= 8) {
      return '#fcf2ac'
    } else if (tem <= 12) {
      return '#fde37d'
    } else if (tem <= 16) {
      return '#fcc865'
    } else if (tem <= 20) {
      return '#faa644'
    } else if (tem <= 24) {
      return '#fa9200'
    } else if (tem <= 28) {
      return '#ff791a'
    } else if (tem <= 32) {
      return '#f05d04'
    } else if (tem <= 36) {
      return '#f74f14'
    } else if (tem <= 40) {
      return '#fc2603'
    } else if (tem <= 44) {
      return '#e70000'
    } else if (tem <= 48) {
      return '#d50637'
    } else if (tem <= 9999) {
      return '#bb012d'
    } else {
      return '#ffffff'
    }
  },
  getPreColor (pre) {
    pre = pre - 0

    if (pre >= 9000) {
      return '#ffffff'
    } else if (pre >= 250) {
      return '#800f3f'
    } else if (pre >= 100) {
      return '#fa00f9'
    } else if (pre >= 50) {
      return '#0000e1'
    } else if (pre >= 25) {
      return '#61b8ff'
    } else if (pre >= 10) {
      return '#3aba3c'
    } else if (pre >= 0.1) {
      return '#a5f38e'
    } else {
      return '#ffffff'
    }
  },
  setValDef (str) {
    str = str.replace('999999.0', '--')
    str = str.replace('999999', '--')
    return str
  }
}
