<template>
<div>
  <div class="sws-left">
    <div v-for="(item, index) in layoutLeft" :key="index" class="sws-side" v-bind:class="['sws-' + item.Size, 'sws-layout-' + item.Code.split('-')[0]]" :data-type="item.Code">
      <div class="sws-side-title">{{sideLayout.LeftPartNames.split(',')[index]}}</div>
      <LayoutPart
      :layoutSize=item.Size
      :layoutType=item.Code
      :dataMetars=dataMetars
      :metarsSetup=metarsSetup
      :metarThreshold=metarThreshold
      :title="sideLayout.LeftPartNames.split(',')[index]"
      @openMaxz="openMaxz"
      ></LayoutPart>
    </div>
  </div>
  <div class="sws-right">
    <div v-for="(item, index) in layoutRight" :key="index"  class="sws-side" v-bind:class="['sws-' + item.Size, 'sws-layout-' + item.Code.split('-')[0]]" :data-type="item.Code">
      <div class="sws-side-title">{{sideLayout.RightPartNames.split(',')[index]}}</div>
      <LayoutPart
      :layoutSize=item.Size
      :layoutType=item.Code
      :dataMetars=dataMetars
      :metarsSetup=metarsSetup
      :metarThreshold=metarThreshold
      :title="sideLayout.RightPartNames.split(',')[index]"
      @openMaxz="openMaxz"
      ></LayoutPart>
    </div>
  </div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import LayoutPart from './LayoutPart'
import { useStore } from 'vuex'

export default {
  name: 'SideData',
  components: {
    LayoutPart
  },
  props: ['dataMetars', 'metarsSetup', 'metarThreshold', 'metarThresholdCusSta'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      sideLayout: '',
      layoutLeft: [],
      layoutRight: [],
      dataMetars: props.dataMetars,
      metarsSetup: props.metarsSetup,
      metarThreshold: props.metarThreshold,
      metarThresholdCusSta: props.metarThresholdCusSta
    })
    const openMaxz = (maxzType, title) => {
      emit('openMaxz', maxzType, title)
    }
    onMounted(() => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/other/sidelayout'
      }).then(function (res) {
        state.sideLayout = res.data.SideLayout
        state.layoutLeft = res.data.Lefts
        state.layoutRight = res.data.Rights
      })
    })
    return {
      ...toRefs(state),
      openMaxz
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/sidedata.scss";  //引入方式
</style>
