<template>
<div>
  <div class="sws-side-menu">
    <ul id="swsRadarSatelliteNav">
      <li v-for="(item, index) in radarSatellites" :key="index" @click="slideTo(index)">{{item.Name}}</li>
    </ul>
    <div class="sws-side-menu-right">
      <div class="sws-side-tool pull-left">
        <i class="iconfont maximize"  @click="openMaxz('radarsatellite')">&#xe733;</i>
      </div>
    </div>
    <div style="position:absolute;right:0;top:14px;">
      <input type="text" id="radarSatelliteTimeSpan" style="width:28px;height:18px;background:rgba(255,255,255,0.4);border:none;color:rgba(255,255,255,0.85);padding-top:2px;" value="1" /> s
    </div>
  </div>
  <div class="sws-metars">
    <swiper
      :modules="modules"
      :scrollbar="{ draggable: true }"
      :autoplay=false
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(item, index) in radarSatellites" :key="index" class="sws-radarsatellite">
        <div class="sws-radarsatellite-img">
            <img src="" :id="'img_' + item.Name_EN" />
        </div>
        <div class="sws-radarsatellite-btn" v-for="(itemSec, indexSec) in radarSatellitedata.filter(tem => tem.Name_EN === item.Name_EN)" :key="indexSec">
          <button v-for="(itemThr, indexThr) in itemSec.Time" :key="indexThr" v-bind:class="{'active': index === imgIndex.index1 && indexThr === imgIndex.index2}" @click="enterImg($event, index, indexThr)">{{itemThr}}</button>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'
import 'swiper/css'

export default {
  name: 'LayoutPartRadarSatellite',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['layoutSize', 'layoutType'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      autoPlay: '',
      layoutSize: props.layoutSize,
      layoutType: props.layoutType,
      mySwiper: {},
      radarSatellites: [],
      radarSatellitedata: [],
      imgIndex: {
        index1: 0,
        index2: 0
      }
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (index) => {
      state.imgIndex.index1 = index
      state.imgIndex.index2 = 0
      state.mySwiper.slideTo(index)
    }
    const onSlideChange = () => {
      const swsRadarSatelliteNav = document.getElementById('swsRadarSatelliteNav')
      const lis = swsRadarSatelliteNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
        } else {
          lis[i].className = ''
        }
      }
    }
    const enterImg = (e, index1, index2) => {
      const imgType = state.radarSatellitedata[index1]
      const imgSrc = state.radarSatellitedata[index1].Src[index2]
      state.imgIndex.index1 = index1
      state.imgIndex.index2 = index2
      document.getElementById('img_' + imgType.Name_EN).setAttribute('src', imgSrc)
      if (state.autoPlay !== '') {
        clearTimeout(state.autoPlay)
        state.autoPlay = ''
        setTimeout(autoPlay, 5000)
      }
    }
    const updateRadarSatelliteData = (startAutoPlay = true) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataRadarSatellite/data'
      }).then(function (res) {
        res.data.RadarSatellites.forEach(function (ele, index, arr) {
          if (ele.Src.length > 0) {
            document.getElementById('img_' + ele.Name_EN).setAttribute('src', ele.Src[0])
          }
        })
        state.radarSatellitedata = res.data.RadarSatellites
        if (startAutoPlay) {
          autoPlay()
        }
      })
    }
    const loadRadarSatelliteSetup = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataRadarSatellite/setup'
      }).then(function (res) {
        state.radarSatellites = res.data.RadarSatellites
        updateRadarSatelliteData(true)
      })
    }
    const autoPlay = () => {
      // 执行完这里的代码需要 2s
      const token = store.getters.getToken
      if (token !== '') {
        next()
        state.autoPlay = setTimeout(autoPlay, 1000)
      }
    }
    const next = () => {
      try {
        const imgIndex = state.imgIndex
        const data = state.radarSatellitedata

        if (data.length - 1 === imgIndex.index1 && data[imgIndex.index1].Src.length - 1 === imgIndex.index2) {
          state.imgIndex.index1 = 0
          state.imgIndex.index2 = 0
          updateRadarSatelliteData(false)
        } else if (data[imgIndex.index1].Src.length - 1 === imgIndex.index2) {
          state.imgIndex.index1++
          state.imgIndex.index2 = 0
        } else {
          state.imgIndex.index2++
        }

        if (state.mySwiper.activeIndex !== state.imgIndex.index1) {
          state.mySwiper.slideTo(state.imgIndex.index1)
        }
        document.getElementById('img_' + data[imgIndex.index1].Name_EN).setAttribute('src', data[imgIndex.index1].Src[imgIndex.index2])
      } catch (error) {
        console.log(error)
      }
    }
    const openMaxz = (maxzType) => {
      emit('openMaxz', maxzType)
    }
    onMounted(() => {
      loadRadarSatelliteSetup()
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      slideTo,
      onSwiper,
      onSlideChange,
      enterImg,
      next,
      autoPlay,
      openMaxz
    }
  }
}
</script>
