<template>
  <div class="paging-info">
    <button class="iconfont" v-bind:class="{'notAllow': pagingInfo.CurrentPage === 1}" @click="pageTo(1)">&#xe730;</button>
    <button class="iconfont" v-bind:class="{'notAllow': pagingInfo.CurrentPage === 1}" @click="pageTo(pagingInfo.CurrentPage - 1)">&#xe712;</button>
    <button v-for="(item, index) in pages" :key="index" v-bind:class="{'notAllow':item === pagingInfo.CurrentPage}" @click="pageTo(item)">{{item}}</button>
    <button class="iconfont" v-bind:class="{'notAllow': pagingInfo.CurrentPage === pagingInfo.TotalPages || pagingInfo.TotalPages === 0}" @click="pageTo(pagingInfo.CurrentPage + 1)">&#xe718;</button>
    <button class="iconfont" v-bind:class="{'notAllow': pagingInfo.CurrentPage === pagingInfo.TotalPages || pagingInfo.TotalPages === 0}" @click="pageTo(pagingInfo.TotalPages)">&#xe72d;</button>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'

export default {
  name: 'PagingHelper',
  components: {
  },
  props: ['pagingInfo'],
  setup (props, { emit }) {
    const state = reactive({
      pagingInfo: props.pagingInfo,
      pages: []
    })
    const setPage = () => {
      const pages = []
      const pagingInfo = props.pagingInfo

      let ii = 1
      let nn = pagingInfo.TotalPages > 11 ? 11 : pagingInfo.TotalPages
      if (pagingInfo.TotalPages > 11 && pagingInfo.CurrentPage > 5) {
        if ((pagingInfo.TotalPages - pagingInfo.CurrentPage) > 4) {
          ii = pagingInfo.CurrentPage - 5
          nn = ii + 10
        } else {
          ii = pagingInfo.TotalPages - 10
          nn = pagingInfo.TotalPages
        }
      }

      // pages = pages.filter(tem => tem < pagingInfo.TotalPages)

      // 指定页码
      for (let i = ii; i <= nn; i++) {
        pages.push(i)
      }
      state.pages = pages
    }
    const pageTo = (page) => {
      const pagingInfo = props.pagingInfo
      if (page !== pagingInfo.CurrentPage && page > 0 && page <= pagingInfo.TotalPages && pagingInfo.TotalPages !== 0) {
        emit('goPage', page)
      }
    }
    onMounted(() => {
      setPage()
    })
    return {
      ...toRefs(state),
      setPage,
      pageTo
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/pagingHelper.scss";  //引入方式
</style>
