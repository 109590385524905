import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    userName: '',
    appCode: '',
    nickName: '',
    stationInfos: [],
    cusStationInfos: [],
    openStation: {
      staID: '',
      type: ''
    }
  },
  getters: {
    getToken (state) {
      state.token = localStorage.getItem('token')
      return state.token || localStorage.getItem('token') || ''
    },
    getUserName (state) {
      state.userName = localStorage.getItem('userName')
      return state.userName || localStorage.getItem('userName') || ''
    },
    getAppCode (state) {
      state.appCode = localStorage.getItem('appCode')
      return state.appCode || localStorage.getItem('appCode') || ''
    },
    getNickName (state) {
      state.nickName = localStorage.getItem('nickName')
      return state.nickName || localStorage.getItem('nickName') || ''
    },
    getStationInfos (state) {
      return state.stationInfos
    },
    getCusStationInfos (state) {
      return state.cusStationInfos
    },
    getOpenStation (state) {
      return state.openStation
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', token)
    },
    delToken (state) {
      state.token = ''
      localStorage.removeItem('token')
    },
    setUserName (state, userName) {
      state.userName = userName
      localStorage.setItem('userName', userName)
    },
    delUserName (state) {
      state.userName = ''
      localStorage.removeItem('userName')
    },
    setAppCode (state, appCode) {
      state.appCode = appCode
      localStorage.setItem('appCode', appCode)
    },
    delAppCode (state) {
      state.appCode = ''
      localStorage.removeItem('appCode')
    },
    setNickName (state, nickName) {
      state.nickName = nickName
      localStorage.setItem('nickName', nickName)
    },
    delNickName (state) {
      state.nickName = ''
      localStorage.removeItem('nickName')
    },
    setOpenStation (state, openStation) {
      state.openStation = openStation
    },
    delOpenStation (state) {
      state.openStation = {
        staID: '',
        type: ''
      }
    },
    setStationInfos (state, stationInfos) {
      state.stationInfos = stationInfos
    },
    delStationInfos (state) {
      state.stationInfos = []
    },
    setCusStationInfos (state, cusStationInfos) {
      state.cusStationInfos = cusStationInfos
    },
    delCusStationInfos (state) {
      state.cusStationInfos = []
    }
  },
  actions: {
  },
  modules: {
  }
})
