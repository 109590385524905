<template>
  <div class="sws-middle">
    <div class="sws-middle23 sws-modal">
      <div class="sws-model-tool">
          <i class="iconfont close" @click="close">&#xe70f;</i>
      </div>
      <div class="sws-middle-title">
          {{dataViewInfo.title}}
      </div>
      <DataMetars v-if="dataViewInfo.type === 'metars'" :metarsSetup=metarsSetup :metarThreshold=metarThreshold></DataMetars>
      <DataRadarSatellite v-if="dataViewInfo.type === 'radarsatellite'"></DataRadarSatellite>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import DataMetars from './DataMetars'
import DataRadarSatellite from './DataRadarSatellite'

export default {
  name: 'DataView',
  components: {
    DataMetars,
    DataRadarSatellite
  },
  props: ['dataViewInfo', 'metarsSetup', 'metarThreshold'],
  setup (props, { emit }) {
    const state = reactive({
      dataViewInfo: props.dataViewInfo,
      metarsSetup: props.metarsSetup,
      metarThreshold: props.metarThreshold
    })
    const close = () => {
      emit('closeData')
    }
    onMounted(() => {

    })
    return {
      ...toRefs(state),
      close
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/data.scss";  //引入方式
</style>
