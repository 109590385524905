<template>
<div>
  <div class="sws-side-menu">
    <ul id="swsMetarNav">
      <li v-for="(item, index) in metarData" :key="index" @click="slideTo(index)" v-bind:class="{'active': index === 0}">{{item.Station_Name}}</li>
    </ul>
    <div class="sws-side-menu-right">
      <div class="sws-side-updatetime" id="swsMetarUpdateTime">{{timeText}}</div>
    </div>
  </div>
  <swiper
    :modules="modules"
    :scrollbar="{ draggable: true }"
    :autoplay="{ delay: 10000 }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="sws-metar"
  >
    <swiper-slide v-for="(item, index) in metarData" :key="index">
        <div class="sws-metar-tempre">
          <div class="sws-metar-tem">
            <div>
              <span class="name">气温：</span>
              <span class="value" id="swsMetarTem">{{temVal[index]}} ℃</span>
            </div>
          </div>
          <div class="sws-metar-pre">
            <div>
              <span class="name">降水：</span>
              <span class="value" id="swsMetarPre">{{preVal[index]}} mm</span>
            </div>
          </div>
        </div>
        <div class="sws-metar-other">
          <div class="sws-metar-other-item">
            <div>
              <i class="iconfont">&#xe60a;</i>
            </div>
            <span class="name">相对湿度</span>
            <span class="value" id="swsMetarRhu">{{rhuVal[index]}} %</span>
          </div>
          <div class="sws-metar-other-item">
            <div>
              <i class="iconfont">&#xe6eb;</i>
            </div>
            <span class="name">极大风</span>
            <span class="value" id="swsMetarWin">{{winVal[index]}} m/s</span>
          </div>
          <div class="sws-metar-other-item">
            <div>
              <i class="iconfont">&#xe6d1;</i>
            </div>
            <span class="name">最小能见度</span>
            <span class="value" id="swsMetarVis">{{visVal[index]}} m</span>
          </div>
          <div class="sws-metar-other-item">
            <div>
              <i class="iconfont">&#xe600;</i>
            </div>
            <span class="name">最大气压</span>
            <span class="value" id="swsMetarPrs">{{prsVal[index]}} hPa</span>
          </div>
        </div>
        <div class="sws-metar-chart" id="swsMetarChart" v-html="charts[index]">

        </div>
    </swiper-slide>
  </swiper>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import common from '@/assets/js/common'
import 'swiper/css'
import { useStore } from 'vuex'

export default {
  name: 'LayoutPartMetar',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['layoutSize', 'layoutType'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      layoutSize: props.layoutSize,
      layoutType: props.layoutType,
      mySwiper: {},
      metarData: [],
      temVal: [],
      preVal: [],
      rhuVal: [],
      winVal: [],
      visVal: [],
      prsVal: [],
      timeVal: [],
      timeText: '',
      charts: []
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (index) => {
      state.mySwiper.slideTo(index)
    }
    const onSlideChange = () => {
      const swsMetarNav = document.getElementById('swsMetarNav')
      const lis = swsMetarNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
          state.timeText = (state.timeVal[i])
        } else {
          lis[i].className = ''
        }
      }
    }
    const loadSetup = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataMetar/MyMetars'
      }).then(function (res) {
        state.metarData = res.data.MyMetars
        state.temVal = []
        state.preVal = []
        state.rhuVal = []
        state.winVal = []
        state.visVal = []
        state.prsVal = []
        state.timeVal = []
        state.charts = []

        res.data.MyMetars.forEach(function (ele, index, arr) {
          if (ele.SurfEle_Min !== null) {
            state.temVal.push(common.JudgeNum(ele.SurfEle_Min.TEM, ''))
            state.preVal.push(common.JudgeNum(ele.SurfEle_Min.PRE_1h, 1))
            state.rhuVal.push(common.JudgeNum(ele.SurfEle_Min.RHU, ''))
            state.winVal.push(common.JudgeNum(ele.SurfEle_Min.WIN_S_INST_Max, ''))
            state.visVal.push(common.JudgeNum(ele.SurfEle_Min.VIS_Min, ''))
            state.prsVal.push(common.JudgeNum(ele.SurfEle_Min.PRS_Max, ''))
            state.timeVal.push(ele.SurfEle_Min.UpdateTime.substring(5, 16))
          } else {
            if (ele.SurfEle_Hours.length > 0) {
              state.temVal.push(common.JudgeNum(ele.SurfEle_Hours[0].TEM, ''))
              state.preVal.push(common.JudgeNum(ele.SurfEle_Hours[0].PRE_1h, 1))
              state.rhuVal.push(common.JudgeNum(ele.SurfEle_Hours[0].RHU, ''))
              state.winVal.push(common.getWindDirTxt8(ele.SurfEle_Hours[0].WIN_D_INST_Max) + ' ' + common.getWindPower(ele.SurfEle_Hours[0].WIN_S_INST_Max))
              state.visVal.push(common.JudgeNum(ele.SurfEle_Hours[0].VIS_Min, ''))
              state.prsVal.push(common.JudgeNum(ele.SurfEle_Hours[0].PRS_Max, ''))
              state.timeVal.push(ele.SurfEle_Hours[0].UpdateTime.substring(5, 16))
            }
          }

          const chartData = []
          ele.SurfEle_Hours.forEach(function (ele2, index2, arr2) {
            if (index2 % 2 === 0) {
              chartData.push(ele2)
            }
          })

          const d = common.metarDataToSvgData(chartData)
          const strHtml = common.makeSvgMetarHour(438, 183, d)
          state.charts.push(strHtml)
        })

        state.timeText = state.timeVal[0]
      })
    }
    const showSvgVal = () => {
      console.log('abc')
    }
    const autoload = () => {
      loadSetup()
      setTimeout(autoload, 300000)
    }
    onMounted(() => {
      autoload()
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      slideTo,
      onSwiper,
      showSvgVal,
      onSlideChange
    }
  }
}
</script>
