<template>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:12%;">
          <col style="width:20%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:8%;">
        </colgroup>
        <thead>
          <tr>
            <th>气象要素</th>
            <th>超过阈值强制显示</th>
            <th>蓝色阈值(含)</th>
            <th>黄色阈值(含)</th>
            <th>橙色阈值(含)</th>
            <th>红色阈值(含)</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:12%;">
          <col style="width:20%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:8%;">
        </colgroup>
        <tbody>
          <tr>
            <td>高温</td>
            <td>{{listData.TEM_hot_bit?"是":"否"}}</td>
            <td class="blu">{{listData.TEM_hot_BU}}</td>
            <td class="yel">{{listData.TEM_hot_YL}}</td>
            <td class="org">{{listData.TEM_hot_OG}}</td>
            <td class="red">{{listData.TEM_hot_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'temmax')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>低温</td>
            <td>{{listData.TEM_cold_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.TEM_cold_BU}}</td>
            <td class="yel">{{listData.TEM_cold_YL}}</td>
            <td class="org">{{listData.TEM_cold_OG}}</td>
            <td class="red">{{listData.TEM_cold_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'temmin')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>1小时降水</td>
            <td>{{listData.PRE_1hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_1hour_BU}}</td>
            <td class="yel">{{listData.PRE_1hour_YL}}</td>
            <td class="org">{{listData.PRE_1hour_OG}}</td>
            <td class="red">{{listData.PRE_1hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre1h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>3小时降水</td>
            <td>{{listData.PRE_3hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_3hour_BU}}</td>
            <td class="yel">{{listData.PRE_3hour_YL}}</td>
            <td class="org">{{listData.PRE_3hour_OG}}</td>
            <td class="red">{{listData.PRE_3hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre3h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>6小时降水</td>
            <td>{{listData.PRE_6hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_6hour_BU}}</td>
            <td class="yel">{{listData.PRE_6hour_YL}}</td>
            <td class="org">{{listData.PRE_6hour_OG}}</td>
            <td class="red">{{listData.PRE_6hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre6h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>12小时降水</td>
            <td>{{listData.PRE_12hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_12hour_BU}}</td>
            <td class="yel">{{listData.PRE_12hour_YL}}</td>
            <td class="org">{{listData.PRE_12hour_OG}}</td>
            <td class="red">{{listData.PRE_12hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre12h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>24小时降水</td>
            <td>{{listData.PRE_24hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_24hour_BU}}</td>
            <td class="yel">{{listData.PRE_24hour_YL}}</td>
            <td class="org">{{listData.PRE_24hour_OG}}</td>
            <td class="red">{{listData.PRE_24hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre24h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>48小时降水</td>
            <td>{{listData.PRE_48hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_48hour_BU}}</td>
            <td class="yel">{{listData.PRE_48hour_YL}}</td>
            <td class="org">{{listData.PRE_48hour_OG}}</td>
            <td class="red">{{listData.PRE_48hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre48h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>72小时降水</td>
            <td>{{listData.PRE_72hour_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.PRE_72hour_BU}}</td>
            <td class="yel">{{listData.PRE_72hour_YL}}</td>
            <td class="org">{{listData.PRE_72hour_OG}}</td>
            <td class="red">{{listData.PRE_72hour_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'pre72h')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>极大风</td>
            <td>{{listData.WIN_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.WIN_BU}}</td>
            <td class="yel">{{listData.WIN_YL}}</td>
            <td class="org">{{listData.WIN_OG}}</td>
            <td class="red">{{listData.WIN_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'win2mi')">&#xec88;</button>
            </td>
          </tr>
          <tr>
            <td>能见度</td>
            <td>{{listData.VIS_bit ? "是" : "否"}}</td>
            <td class="blu">{{listData.VIS_BU}}</td>
            <td class="yel">{{listData.VIS_YL}}</td>
            <td class="org">{{listData.VIS_OG}}</td>
            <td class="red">{{listData.VIS_RD}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(listData.ID,'vis')">&#xec88;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
    </div>
  </div>
  <div v-if="editData != ''" class="sws-modal-mask">
    <div class="sws-modal">
      <div class="sws-modal-head">
        <div class="sws-modal-tool">
          <i class="iconfont close" @click="closeEdit">&#xe70f;</i>
        </div>
        <div class="title">
          {{editTitle}}
        </div>
      </div>
      <div class="sws-modal-body">
        <el-form label-width="120px">
          <el-form-item label="超过阈值时">
            <el-checkbox v-model="editData.DisplayBit" label="强制显示"/>
          </el-form-item>
          <el-form-item label="蓝色">
            <el-input v-model="editData.Blu" />
          </el-form-item>
          <el-form-item label="黄色">
            <el-input v-model="editData.Yel" />
          </el-form-item>
          <el-form-item label="橙色">
            <el-input v-model="editData.Org" />
          </el-form-item>
          <el-form-item label="红色">
            <el-input v-model="editData.Red" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="closeEdit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="sws-modal-foot"></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'SetupMetarThresholdSta',
  components: {
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      listData: [],
      editData: '',
      editTitle: ''
    })
    // 数据列表
    const loadList = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMetarThresholdSta/List'
      }).then(function (res) {
        console.log(res)
        state.listData = res.data.MetarThresholdSta
      })
    }
    // 打开编辑页
    const edit = (id, ele) => {
      id = id + ',' + ele
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMetarThresholdSta/Edit?id=' + id
      }).then(function (res) {
        state.editData = res.data
        state.editTitle = res.data.EditTitle
      })
    }
    // 提交编辑页
    const onSubmit = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMetarThresholdSta/Update',
        data: state.editData
      }).then(function (res) {
        if (res.data.State === 'error') {
          ElMessage({
            showClose: true,
            message: res.data.Msg
          })
        } else {
          loadList()
          state.editData = ''
        }
      })
    }
    // 关闭编辑页
    const closeEdit = () => {
      state.editData = ''
    }
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      loadList,
      edit,
      onSubmit,
      closeEdit
    }
  }
}
</script>
