<template>
<div class="sws-tool-item">
  <div class="sws-chat-wrap">
    <div class="sws-chat-tool">
      <ul class="sws-chat-tool-top">
        <li id="btnChatToolUnfold" data-val="1" title="展开/收缩文字窗口" @click="unfold = !unfold;">
          <i class="iconfont">{{unfold ? '&#xe622;' : '&#xe603;'}}</i>
        </li>
        <audio id="chatAudio">
            您的浏览器不支持 audio 标签。
        </audio>
        <li id="btnChatToolVoice" data-val="0" title="语音提醒" @click="setVoice">
          <i class="iconfont">{{voiceBit ? '&#xe708;' : '&#xe709;'}}</i>
        </li>
        <li>
          <i class="iconfont" title="过滤设置">&#xe714;</i>
          <div class="sws-chat-tool-filter" id="chatToolFilter">
            <ul class="fir">
              <li>
                <h4>实况阈值提醒设置：</h4>
                <ul class="sec">
                  <li v-bind:class="{'active': userConfig.ChatMetarDisplay.includes('nor')}" @click="audioFilterMetar('nor')"><i class="iconfont" v-bind:class="{'wht': userConfig.ChatMetarDisplay.includes('nor')}">&#xe716;</i><span>普通</span></li>
                  <li v-bind:class="{'active': userConfig.ChatMetarDisplay.includes('blu')}" @click="audioFilterMetar('blu')"><i class="iconfont" v-bind:class="{'blu': userConfig.ChatMetarDisplay.includes('blu')}">&#xe716;</i><span>蓝色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatMetarDisplay.includes('yel')}" @click="audioFilterMetar('yel')"><i class="iconfont" v-bind:class="{'yel': userConfig.ChatMetarDisplay.includes('yel')}">&#xe716;</i><span>黄色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatMetarDisplay.includes('org')}" @click="audioFilterMetar('org')"><i class="iconfont" v-bind:class="{'org': userConfig.ChatMetarDisplay.includes('org')}">&#xe716;</i><span>橙色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatMetarDisplay.includes('red')}" @click="audioFilterMetar('red')"><i class="iconfont" v-bind:class="{'red': userConfig.ChatMetarDisplay.includes('red')}">&#xe716;</i><span>红色</span></li>
                </ul>
              </li>
              <li>
                <h4>气象灾害预警提醒设置：</h4>
                <ul class="sec">
                  <li v-bind:class="{'active': userConfig.ChatWarningDisplay.includes('blu')}" @click="audioFilterWarning('blu')"><i class="iconfont" v-bind:class="{'blu': userConfig.ChatWarningDisplay.includes('blu')}">&#xe663;</i><span>蓝色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatWarningDisplay.includes('yel')}" @click="audioFilterWarning('yel')"><i class="iconfont" v-bind:class="{'yel': userConfig.ChatWarningDisplay.includes('yel')}">&#xe663;</i><span>黄色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatWarningDisplay.includes('org')}" @click="audioFilterWarning('org')"><i class="iconfont" v-bind:class="{'org': userConfig.ChatWarningDisplay.includes('org')}">&#xe663;</i><span>橙色</span></li>
                  <li v-bind:class="{'active': userConfig.ChatWarningDisplay.includes('red')}" @click="audioFilterWarning('red')"><i class="iconfont" v-bind:class="{'red': userConfig.ChatWarningDisplay.includes('red')}">&#xe663;</i><span>红色</span></li>
                </ul>
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <ul class="sws-chat-tool-bottom">
        <li>
          <i class="iconfont" @click="chatWinGo('top')" style="transform: rotate(180deg);" title="窗口内容到顶部">&#xe713;</i>
        </li>
        <li>
          <i class="iconfont" @click="chatWinGo('up')" title="窗口内容向上">&#xe726;</i>
        </li>
        <li>
          <i class="iconfont" @click="chatWinGo('down')" title="窗口内容向下">&#xe727;</i>
        </li>
        <li>
          <i class="iconfont" @click="chatWinGo('bottom')" title="窗口内容到底部">&#xe713;</i>
        </li>
      </ul>
    </div>
    <div class="sws-chat-win-wrap" v-bind:class="{'unfold': unfold}"  v-loading="loadingBit" element-loading-background="rgba(255,255,255,0.05)">
      <div class="sws-chat-win" id="swsChatWin">
        <div class="sws-chat-win-con" id="swsChatWinCon">
          <template v-for="(item, index) in msgList" :key="index">
            <!-- 实时内容 -->
            <div v-if="item.MsgType=='metar' && userConfig.ChatMetarDisplay.indexOf(item.Lvl) != -1" class="sws-chat-win-row">
              <div class="sws-chat-win-row-head">
                <div class="sws-chat-win-row-title">
                  <i class="iconfont" v-bind:class="item.Lvl">&#xe716;</i>
                  <span v-bind:class="item.Lvl">{{item.ChatMsgTypeName}}</span>
                  <div class="sws-chat-win-row-play">
                    <i class="iconfont" v-bind:data-src="item.TtsSrc" @click="rowPlay">&#xe708;</i>
                  </div>
                </div>
                <div class="sws-chat-win-row-time">{{item.Time}}</div>
              </div>
              <div class="sws-chat-win-row-body">
                <p>{{item.Con}}</p>
              </div>
            </div>
            <!-- 预警内容 -->
            <div v-if="item.MsgType=='warning' && userConfig.ChatWarningDisplay.indexOf(item.Lvl) != -1" class="sws-chat-win-row">
              <div class="sws-chat-win-row-head">
                <div class="sws-chat-win-row-title">
                  <i class="iconfont" v-bind:class="item.Lvl">&#xe716;</i>
                  <span v-bind:class="item.Lvl">{{item.ChatMsgTypeName}}</span>
                  <div class="sws-chat-win-row-play">
                    <i class="iconfont" v-bind:data-src="item.TtsSrc" @click="rowPlay">&#xe708;</i>
                  </div>
                </div>
                <div class="sws-chat-win-row-time">{{item.Time}}</div>
              </div>
              <div class="sws-chat-win-row-body">
                <p>{{item.Con}}</p>
              </div>
            </div>
            <!-- 用户输入内容 -->
            <div v-if="item.MsgType=='input'" class="sws-chat-win-row">
              <div class="sws-chat-win-row-head">
                <div class="sws-chat-win-row-title" style="padding-left:32px;">
                  <i class="iconfont" v-bind:class="item.Lvl">&#xe72b;</i>
                  <span v-bind:class="item.Lvl">{{item.ChatMsgTypeName}}</span>
                  <div class="sws-chat-win-row-play">
                  </div>
                </div>
                <div class="sws-chat-win-row-time">{{item.Time}}</div>
              </div>
              <div class="sws-chat-win-row-body">
                <p>{{item.Con}}</p>
              </div>
            </div>
            <!-- 用户查询结果 -->
            <div v-if="item.MsgType=='queryresult'" class="sws-chat-win-row">
              <div class="sws-chat-win-row-head">
                <div class="sws-chat-win-row-title">
                  <i class="iconfont" v-bind:class="item.Lvl">&#xe70c;</i>
                  <span v-bind:class="item.Lvl">{{item.ChatMsgTypeName}}</span>
                  <div class="sws-chat-win-row-play">
                    <i class="iconfont" v-bind:data-src="item.TtsSrc" @click="rowPlay">&#xe708;</i>
                  </div>
                </div>
                <div class="sws-chat-win-row-time">{{item.Time}}</div>
              </div>
              <div class="sws-chat-win-row-body">
                <p>{{item.Con}}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="sws-chat-win-input">
        <input type="text" @keyup.enter="chatQuery" placeholder="输入站号或站名关键字查询站点实况">
      </div>
    </div>
    <div class="clr"></div>
  </div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import common from '@/assets/js/common'
import axios from 'axios'
import { useStore } from 'vuex'

export default {
  name: 'MsgChat',
  props: ['userConfig'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      userConfig: props.userConfig,
      unfold: true,
      voiceBit: false,
      chatAudio: '',
      msgList: [],
      playList: [],
      loadingBit: false,
      autoloadBit: true
    })
    const setVoice = () => {
      state.voiceBit = !state.voiceBit
      state.chatAudio.volume = state.voiceBit ? 1 : 0
    }
    const autoload = () => {
      if (state.autoloadBit) {
        getChatMsg()

        setTimeout(function () {
          autoload()
        }, 20000)
      }
    }
    // 设置实况过滤
    const audioFilterMetar = (str) => {
      const arrSource = state.userConfig.ChatMetarDisplay.split(',')
      let arrNew = []
      if (arrSource.some(tem => tem === str)) {
        arrSource.filter(tem => tem !== str).forEach(function (ele, index, arr) {
          arrNew.push(ele)
        })
      } else {
        arrNew = arrSource
        arrNew.push(str)
      }
      state.userConfig.ChatMetarDisplay = arrNew.join(',')
      saveChatConfig()
    }
    // 设置预警过滤
    const audioFilterWarning = (str) => {
      const arrSource = state.userConfig.ChatWarningDisplay.split(',')
      let arrNew = []
      if (arrSource.some(tem => tem === str)) {
        arrSource.filter(tem => tem !== str).forEach(function (ele, index, arr) {
          arrNew.push(ele)
        })
      } else {
        arrNew = arrSource
        arrNew.push(str)
      }
      state.userConfig.ChatWarningDisplay = arrNew.join(',')
      saveChatConfig()
    }
    // 控制窗口滚动
    const chatWinGo = (act) => {
      try {
        const chatWin = document.getElementById('swsChatWin')
        const top = chatWin.scrollTop
        const conHeight = document.getElementById('swsChatWinCon').offsetHeight

        switch (act) {
          case 'top':
            chatWin.scrollTop = 0
            break
          case 'up':
            chatWin.scrollTop = top - 18
            break
          case 'down':
            chatWin.scrollTop = top + 18
            break
          case 'bottom':
            chatWin.scrollTop = conHeight
            break
        }
      } catch (error) {
        console.log(error)
      }
    }
    // 获取消息
    const getChatMsg = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataChat/NewMsg'
      }).then(function (res) {
        res.data.ChatMsgs.forEach(function (ele, index, arr) {
          let chatDisplay = ''
          ele.Time = common.timeToStrDate(ele.CreateTime).slice(5, -3)
          state.msgList.push(ele)
          switch (ele.MsgType) {
            case 'metar':
              chatDisplay = state.userConfig.ChatMetarDisplay
              break
            case 'warning':
              chatDisplay = state.userConfig.ChatWarningDisplay
              break
          }
          if (chatDisplay.indexOf(ele.Lvl) !== -1 && state.voiceBit) {
            state.playList.push(ele.TtsSrc)
          }
        })
        audioPlay()
        setTimeout(function () {
          chatWinGo('bottom')
        }, 100)
      })
    }
    // 播放
    const audioPlay = () => {
      if (state.chatAudio.paused || state.chatAudio.played.length === 0) {
        audioPlayNext()
      }
    }
    // 播放下一首
    const audioPlayNext = () => {
      if (state.playList.length > 0) {
        state.chatAudio.src = state.playList[0]
        state.chatAudio.play()
      }
    }
    // 播放选定行
    const rowPlay = (e) => {
      const src = e.target.getAttribute('data-src')
      if (!state.playList.some(tem => tem === src)) {
        state.voiceBit = true
        state.playList.push(src)
        audioPlay()
      }
    }
    // 查询及播放
    const chatQuery = (e) => {
      state.loadingBit = true
      const text = e.target.value
      const time = new Date()
      if (text === '') {
        return
      }
      console.log(text)
      state.msgList.push({
        MsgType: 'input',
        Time: common.prefixInteger(time.getMonth() + 1, 2) + '-' + common.prefixInteger(time.getDate(), 2) + ' ' + common.prefixInteger(time.getHours(), 2) + ':' + common.prefixInteger(time.getMinutes(), 2),
        Con: text,
        ChatMsgTypeName: '用户输入'
      })
      setTimeout(function () {
        chatWinGo('bottom')
      }, 100)
      const obj = {
        queryStr: text
      }
      e.target.value = ''
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        data: obj,
        url: 'https://swsapi.bjzkzm.com/DataChat/Query'
      }).then(function (res) {
        console.log(res)
        state.voiceBit = true
        res.data.ChatMsgs.forEach(function (ele, index, arr) {
          ele.Time = common.timeToStrDate(ele.CreateTime).slice(5, -3)
          state.msgList.push(ele)
          state.playList.push(ele.TtsSrc)
        })
        audioPlay()
        setTimeout(function () {
          chatWinGo('bottom')
        }, 100)
        state.loadingBit = false
      })
    }
    const saveChatConfig = () => {
      const chatConfig = {
        chatMetarDisplay: state.userConfig.ChatMetarDisplay,
        chatWarningDisplay: state.userConfig.ChatWarningDisplay
      }
      emit('saveChatConfig', chatConfig)
    }
    onMounted(() => {
      state.chatAudio = document.getElementById('chatAudio')
      state.chatAudio.onended = function () {
        try {
          state.playList.splice(0, 1)
        } catch (error) { }
        audioPlayNext()
      }
      autoload()
    })
    return {
      ...toRefs(state),
      setVoice,
      audioFilterMetar,
      audioFilterWarning,
      chatWinGo,
      getChatMsg,
      autoload,
      saveChatConfig,
      audioPlay,
      audioPlayNext,
      chatQuery,
      rowPlay
    }
  }
}
</script>
