<template>
<div v-loading="loadingBit" element-loading-background="rgba(255,255,255,0.05)">
  <div class="sws-side-menu">
    <ul v-if="metarsSetup" id="swsMetarsNav">
      <li v-if="metarsSetup.Tem != ''" class="active" @click="slideTo($event)">气温</li>
      <li v-if="metarsSetup.Pre != ''" @click="slideTo($event)">降水</li>
      <li v-if="metarsSetup.Win != ''" @click="slideTo($event)">风速</li>
      <li v-if="metarsSetup.Prs != ''" @click="slideTo($event)">气压</li>
      <li v-if="metarsSetup.Rhu != ''" @click="slideTo($event)">湿度</li>
      <li v-if="metarsSetup.Vis != ''" @click="slideTo($event)">能见度</li>
    </ul>
    <div class="sws-side-menu-right">
        <div class="sws-side-updatetime pull-left" id="swsMetarsUpdateTime">{{updateTime}}</div>
        <div class="sws-side-tool pull-left">
            <i class="iconfont maximize" @click="openMaxz('metars')">&#xe733;</i>
        </div>
    </div>
  </div>
  <div v-if="metarsSetup" class="sws-metars">
    <swiper class="big-banner"
      :modules="modules"
      :scrollbar="{ draggable: true }"
      :autoplay="{ delay: 10000 }"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-if="metarsSetup.Tem != ''" class="sws-metars-tem">
        <table class="sws-metars-thead">
          <colgroup>
            <col v-for="(item, index) in metarsSetup.TemColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(item, index) in metarsSetup.Tem.split(',')" :key="index"
              :data-ord="metarsSetup.Tem.split(',')[index]"
              :class="[item, {'asc': orderBy.tem == item}, {'desc': orderBy.tem == item + '_desc'}]"
              @click="updateOrd($event)">
              {{metarsSetup.TemColName.split(',')[index]}}
              </th>
            </tr>
          </thead>
        </table>
        <div class="sws-metars-ele-wrap">
          <table>
            <colgroup>
              <col v-for="(item, index) in metarsSetup.TemColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
            </colgroup>
            <tbody v-if="dataMetars">
              <tr v-for="(item, index) in orderMetar(dataMetars.DataMetars.filter(tem => tem.TEM - 0 < 900000), orderBy.tem)" :key="index" @click="openStationInfo(item.Station_ID_C, 'auto')">
                <td v-for="(item2, index2) in metarsSetup.Tem.split(',')" :key="index2" :class="[item2]">
                  {{getValue(index, item2, item)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="layoutType != 'metars-23_1'">
          <div v-for="(item, index) in metarsSetup.TemChart.split(',')" :key="index" class="sws-metars-chart-wrap">
            <div class="sws-metars-chart-title">
              {{metarsSetup.TemChartName.split(',')[index]}}
            </div>
            <div v-if="metarThreshold" class="sws-metars-chart-color">
              <div class="sws-metars-chart-color-item">
                <i class="red"></i>
                <span>{{item.indexOf('min') == -1 ? '≥' + metarThreshold.TEM_hot_RD : '≤' + metarThreshold.TEM_cold_RD}} ℃</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="org"></i>
                <span>{{item.indexOf('min') == -1 ? '≥' + metarThreshold.TEM_hot_OG : '≤' + metarThreshold.TEM_cold_OG}} ℃</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="yel"></i>
                <span>{{item.indexOf('min') == -1 ? '≥' + metarThreshold.TEM_hot_YL : '≤' + metarThreshold.TEM_cold_YL}} ℃</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="blu"></i>
                <span>{{item.indexOf('min') == -1 ? '≥' + metarThreshold.TEM_hot_BU : '≤' + metarThreshold.TEM_cold_BU}} ℃</span>
              </div>
            </div>
            <div class="sws-metars-chart" :id="'chart_' + item"></div>
            <div class="clr"></div>
          </div>
        </template>
      </swiper-slide>
      <swiper-slide v-if="metarsSetup.Pre != ''" class="sws-metars-pre">
        <table class="sws-metars-thead">
          <colgroup>
            <col v-for="(item, index) in metarsSetup.PreColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(item, index) in metarsSetup.Pre.split(',')" :key="index"
              :data-ord="metarsSetup.Pre.split(',')[index]"
              :class="[item, {'asc': orderBy.pre == item}, {'desc': orderBy.pre == item + '_desc'}]"
              @click="updateOrd($event)">
              {{metarsSetup.PreColName.split(',')[index]}}
              </th>
            </tr>
          </thead>
        </table>
        <div class="sws-metars-ele-wrap">
          <table>
            <colgroup>
              <col v-for="(item, index) in metarsSetup.PreColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
            </colgroup>
            <tbody v-if="dataMetars">
              <tr v-for="(item, index) in orderMetar(dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 < 900000), orderBy.pre)" :key="index" @click="openStationInfo(item.Station_ID_C, 'auto')">
                <td v-for="(item2, index2) in metarsSetup.Pre.split(',')" :key="index2" :class="[item2]">
                  {{getValue(index, item2, item)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="layoutType != 'metars-23_1'">
          <div v-for="(item, index) in metarsSetup.PreChart.split(',')" :key="index" class="sws-metars-chart-wrap">
            <div class="sws-metars-chart-title">
              {{metarsSetup.PreChartName.split(',')[index]}}
            </div>
            <div v-if="metarThreshold" class="sws-metars-chart-color">
              <div class="sws-metars-chart-color-item">
                <i class="red"></i>
                <span v-if="item === 'pre_1h'">{{'≥' + metarThreshold.PRE_1hour_RD}} mm</span>
                <span v-if="item === 'pre_3h'">{{'≥' + metarThreshold.PRE_3hour_RD}} mm</span>
                <span v-if="item === 'pre_6h'">{{'≥' + metarThreshold.PRE_6hour_RD}} mm</span>
                <span v-if="item === 'pre_12h'">{{'≥' + metarThreshold.PRE_12hour_RD}} mm</span>
                <span v-if="item === 'pre_24h'">{{'≥' + metarThreshold.PRE_24hour_RD}} mm</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="org"></i>
                <span v-if="item === 'pre_1h'">{{'≥' + metarThreshold.PRE_1hour_OG}} mm</span>
                <span v-if="item === 'pre_3h'">{{'≥' + metarThreshold.PRE_3hour_OG}} mm</span>
                <span v-if="item === 'pre_6h'">{{'≥' + metarThreshold.PRE_6hour_OG}} mm</span>
                <span v-if="item === 'pre_12h'">{{'≥' + metarThreshold.PRE_12hour_OG}} mm</span>
                <span v-if="item === 'pre_24h'">{{'≥' + metarThreshold.PRE_24hour_OG}} mm</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="yel"></i>
                <span v-if="item === 'pre_1h'">{{'≥' + metarThreshold.PRE_1hour_YL}} mm</span>
                <span v-if="item === 'pre_3h'">{{'≥' + metarThreshold.PRE_3hour_YL}} mm</span>
                <span v-if="item === 'pre_6h'">{{'≥' + metarThreshold.PRE_6hour_YL}} mm</span>
                <span v-if="item === 'pre_12h'">{{'≥' + metarThreshold.PRE_12hour_YL}} mm</span>
                <span v-if="item === 'pre_24h'">{{'≥' + metarThreshold.PRE_24hour_YL}} mm</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="blu"></i>
                <span v-if="item === 'pre_1h'">{{'≥' + metarThreshold.PRE_1hour_BU}} mm</span>
                <span v-if="item === 'pre_3h'">{{'≥' + metarThreshold.PRE_3hour_BU}} mm</span>
                <span v-if="item === 'pre_6h'">{{'≥' + metarThreshold.PRE_6hour_BU}} mm</span>
                <span v-if="item === 'pre_12h'">{{'≥' + metarThreshold.PRE_12hour_BU}} mm</span>
                <span v-if="item === 'pre_24h'">{{'≥' + metarThreshold.PRE_24hour_BU}} mm</span>
              </div>
            </div>
            <div class="sws-metars-chart" :id="'chart_' + item"></div>
            <div class="clr"></div>
          </div>
        </template>
      </swiper-slide>
      <swiper-slide v-if="metarsSetup.Win != ''" class="sws-metars-win">
        <table class="sws-metars-thead">
          <colgroup>
            <col v-for="(item, index) in metarsSetup.WinColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(item, index) in metarsSetup.Win.split(',')" :key="index"
              :data-ord="metarsSetup.Win.split(',')[index]"
              :class="[item, {'asc': orderBy.win == item}, {'desc': orderBy.win == item + '_desc'}]"
              @click="updateOrd($event)">
              {{metarsSetup.WinColName.split(',')[index]}}
              </th>
            </tr>
          </thead>
        </table>
        <div class="sws-metars-ele-wrap">
          <table>
            <colgroup>
              <col v-for="(item, index) in metarsSetup.WinColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
            </colgroup>
            <tbody v-if="dataMetars">
              <tr v-for="(item, index) in orderMetar(dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 < 900000), orderBy.win)" :key="index" @click="openStationInfo(item.Station_ID_C, 'auto')">
                <td v-for="(item2, index2) in metarsSetup.Win.split(',')" :key="index2" :class="[item2]">
                  {{getValue(index, item2, item)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="layoutType != 'metars-23_1'">
          <div v-for="(item, index) in metarsSetup.WinChart.split(',')" :key="index" class="sws-metars-chart-wrap">
            <div class="sws-metars-chart-title">
              {{metarsSetup.WinChartName.split(',')[index]}}
            </div>
            <div v-if="metarThreshold" class="sws-metars-chart-color">
              <div class="sws-metars-chart-color-item">
                <i class="red"></i>
                <span>{{'≥' + metarThreshold.WIN_RD}} m/s</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="org"></i>
                <span>{{'≥' + metarThreshold.WIN_OG}} m/s</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="yel"></i>
                <span>{{'≥' + metarThreshold.WIN_YL}} m/s</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="blu"></i>
                <span>{{'≥' + metarThreshold.WIN_BU}} m/s</span>
              </div>
            </div>
            <div class="sws-metars-chart" :id="'chart_' + item"></div>
            <div class="clr"></div>
          </div>
        </template>
      </swiper-slide>
      <swiper-slide v-if="metarsSetup.Vis != ''" class="sws-metars-vis">
        <table class="sws-metars-thead">
          <colgroup>
            <col v-for="(item, index) in metarsSetup.VisColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
          </colgroup>
          <thead>
            <tr>
              <th v-for="(item, index) in metarsSetup.Vis.split(',')" :key="index"
              :data-ord="metarsSetup.Vis.split(',')[index]"
              :class="[item, {'asc': orderBy.vis == item}, {'desc': orderBy.vis == item + '_desc'}]"
              @click="updateOrd($event)">
              {{metarsSetup.VisColName.split(',')[index]}}
              </th>
            </tr>
          </thead>
        </table>
        <div class="sws-metars-ele-wrap">
          <table>
            <colgroup>
              <col v-for="(item, index) in metarsSetup.VisColWidth.split(',')" :key="index" :style="{width:item + '%'}" />
            </colgroup>
            <tbody v-if="dataMetars">
              <tr v-for="(item, index) in orderMetar(dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 < 900000), orderBy.vis)" :key="index" @click="openStationInfo(item.Station_ID_C, 'auto')">
                <td v-for="(item2, index2) in metarsSetup.Vis.split(',')" :key="index2" :class="[item2]">
                  {{getValue(index, item2, item)}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-if="layoutType != 'metars-23_1'">
          <div v-for="(item, index) in metarsSetup.VisChart.split(',')" :key="index" class="sws-metars-chart-wrap">
            <div class="sws-metars-chart-title">
              {{metarsSetup.VisChartName.split(',')[index]}}
            </div>
            <div v-if="metarThreshold" class="sws-metars-chart-color">
              <div class="sws-metars-chart-color-item">
                <i class="red"></i>
                <span>{{'≤' + metarThreshold.VIS_RD}} m</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="org"></i>
                <span>{{'≤' + metarThreshold.VIS_OG}} m</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="yel"></i>
                <span>{{'≤' + metarThreshold.VIS_YL}} m</span>
              </div>
              <div class="sws-metars-chart-color-item">
                <i class="blu"></i>
                <span>{{'≤' + metarThreshold.VIS_BU}} m</span>
              </div>
            </div>
            <div class="sws-metars-chart" :id="'chart_' + item"></div>
            <div class="clr"></div>
          </div>
        </template>
      </swiper-slide>
    </swiper>
  </div>
  <div style="clear:both;"></div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import * as echarts from 'echarts'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import common from '@/assets/js/common'
import { useStore } from 'vuex'
import 'swiper/css'

export default {
  name: 'LayoutPartMetars',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['layoutSize', 'layoutType', 'dataMetars', 'metarsSetup', 'metarThreshold'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      layoutSize: props.layoutSize,
      layoutType: props.layoutType,
      dataMetars: props.dataMetars,
      metarsSetup: props.metarsSetup,
      metarThreshold: props.metarThreshold,
      mySwiper: {},
      updateTime: '',
      orderBy: {
        tem: 'tem',
        pre: 'pre_1h_desc',
        win: 'win_s_inst_desc',
        vis: 'vis_min'
      },
      loadingBit: true,
      metarCharts: []
    })
    const updateOrd = (e) => {
      const ele = e.target
      const ord = ele.getAttribute('data-ord')
      if (ord.indexOf('tem') !== -1) {
        if (state.orderBy.tem === ord) {
          state.orderBy.tem = ord + '_desc'
        } else {
          state.orderBy.tem = ord
        }
      }
      if (ord.indexOf('pre') !== -1) {
        if (state.orderBy.pre === ord) {
          state.orderBy.pre = ord + '_desc'
        } else {
          state.orderBy.pre = ord
        }
      }
      if (ord.indexOf('win') !== -1) {
        if (state.orderBy.win === ord) {
          state.orderBy.win = ord + '_desc'
        } else {
          state.orderBy.win = ord
        }
      }
      if (ord.indexOf('vis') !== -1) {
        if (state.orderBy.vis === ord) {
          state.orderBy.vis = ord + '_desc'
        } else {
          state.orderBy.vis = ord
        }
      }
    }
    // 实况排序
    const orderMetar = (metars, orderBy) => {
      let data = ''
      switch (orderBy) {
        case 'tem':
          data = common.sortAsc(metars, 'TEM')
          break
        case 'tem_desc':
          data = common.sortDesc(metars, 'TEM')
          break
        case 'tem_max':
          data = common.sortAsc(metars, 'TEM_Max')
          break
        case 'tem_max_desc':
          data = common.sortDesc(metars, 'TEM_Max')
          break
        case 'tem_min':
          data = common.sortAsc(metars, 'TEM_Min')
          break
        case 'tem_min_desc':
          data = common.sortDesc(metars, 'TEM_Min')
          break
        case 'tem_max_day':
          data = common.sortAsc(metars, 'TEM_Max_Day')
          break
        case 'tem_max_day_desc':
          data = common.sortDesc(metars, 'TEM_Max_Day')
          break
        case 'tem_min_day':
          data = common.sortAsc(metars, 'TEM_Min_Day')
          break
        case 'tem_min_day_desc':
          data = common.sortDesc(metars, 'TEM_Min_Day')
          break
        case 'pre_1h':
          data = common.sortAsc(metars, 'PRE_1h')
          break
        case 'pre_1h_desc':
          data = common.sortDesc(metars, 'PRE_1h')
          break
        case 'pre_3h':
          data = common.sortAsc(metars, 'PRE_3h')
          break
        case 'pre_3h_desc':
          data = common.sortDesc(metars, 'PRE_3h')
          break
        case 'pre_6h':
          data = common.sortAsc(metars, 'PRE_6h')
          break
        case 'pre_6h_desc':
          data = common.sortDesc(metars, 'PRE_6h')
          break
        case 'pre_12h':
          data = common.sortAsc(metars, 'PRE_12h')
          break
        case 'pre_12h_desc':
          data = common.sortDesc(metars, 'PRE_12h')
          break
        case 'pre_24h':
          data = common.sortAsc(metars, 'PRE_24h')
          break
        case 'pre_24h_desc':
          data = common.sortDesc(metars, 'PRE_24h')
          break
        case 'win_s_2min':
        case 'win_s_2min_lvl':
          data = common.sortAsc(metars, 'WIN_S_Avg_2mi')
          break
        case 'win_s_2min_desc':
        case 'win_s_2min_lvl_desc':
          data = common.sortDesc(metars, 'WIN_S_Avg_2mi')
          break
        case 'win_s_inst':
        case 'win_s_inst_lvl':
          data = common.sortAsc(metars, 'WIN_S_INST_Max')
          break
        case 'win_s_inst_desc':
        case 'win_s_inst_lvl_desc':
          data = common.sortDesc(metars, 'WIN_S_INST_Max')
          break
        case 'vis_min':
          data = common.sortAsc(metars, 'VIS_Min')
          break
        case 'vis_min_desc':
          data = common.sortDesc(metars, 'VIS_Min')
          break
        case 'vis_min_day':
          data = common.sortAsc(metars, 'VIS_Min_Day')
          break
        case 'vis_min_day_desc':
          data = common.sortDesc(metars, 'VIS_Min_Day')
          break
      }
      return data
    }
    const getValue = (index, colName, data) => {
      let res = ''
      switch (colName) {
        case 'ord':
          res = index + 1
          break
        case 'station_id_c':
          res = data.Station_ID_C
          break
        case 'station_name':
          if (data.Station_Name.length > 6) {
            res = data.Station_Name.substring(0, 5) + ' ...'
          } else {
            res = data.Station_Name
          }
          break
        case 'cnty':
          res = data.Cnty
          break
        case 'tem':
          res = data.TEM < 999999 ? data.TEM + ' ℃' : '--'
          break
        case 'tem_min':
          res = data.TEM_Min < 999999 ? data.TEM_Min + ' ℃' : '--'
          break
        case 'tem_max':
          res = data.TEM_Max < 999999 ? data.TEM_Max + ' ℃' : '--'
          break
        case 'tem_min_day':
          res = data.TEM_Min_Day < 999999 ? data.TEM_Min_Day + ' ℃' : '--'
          break
        case 'tem_max_day':
          res = data.TEM_Max_Day < 999999 ? data.TEM_Max_Day + ' ℃' : '--'
          break
        case 'pre_1h':
          res = data.PRE_1h < 999999 ? data.PRE_1h.toFixed(1) + ' mm' : '--'
          break
        case 'pre_3h':
          res = data.PRE_3h < 999999 ? data.PRE_3h.toFixed(1) + ' mm' : '--'
          break
        case 'pre_6h':
          res = data.PRE_6h < 999999 ? data.PRE_6h.toFixed(1) + ' mm' : '--'
          break
        case 'pre_12h':
          res = data.PRE_12h < 999999 ? data.PRE_12h.toFixed(1) + ' mm' : '--'
          break
        case 'pre_24h':
          res = data.PRE_24h < 999999 ? data.PRE_24h.toFixed(1) + ' mm' : '--'
          break
        case 'win_s_2min':
          res = data.WIN_S_Avg_2mi < 999999 ? data.WIN_S_Avg_2mi.toFixed(1) + ' m/s' : '--'
          break
        case 'win_s_2min_lvl':
          res = data.WIN_S_Avg_2mi < 999999 ? common.getWindDirTxt8(data.WIN_D_Avg_2mi) + common.getWindPower(data.WIN_S_Avg_2mi) + (data.WIN_S_Avg_2mi > 0.2 ? '级' : '') : '--'
          break
        case 'win_s_inst':
          res = data.WIN_S_INST_Max < 999999 ? data.WIN_S_INST_Max.toFixed(1) + ' m/s' : '--'
          break
        case 'win_s_inst_lvl':
          res = data.WIN_S_INST_Max < 999999 ? common.getWindDirTxt8(data.WIN_D_INST_Max) + common.getWindPower(data.WIN_S_INST_Max) + (data.WIN_S_INST_Max > 0.2 ? '级' : '') : '--'
          break
        case 'vis_min':
          res = data.VIS_Min ? data.VIS_Min + ' m' : '--'
          break
        case 'vis_min_day':
          res = data.VIS_Min_Day ? data.VIS_Min_Day + ' m' : '--'
          break
        case 'vis_min_24h':
          res = data.VIS_Min_24h ? data.VIS_Min_24h + ' m' : '--'
          break
        default:
          res = 'null'
      }
      if (isNaN(res)) {
        res = common.setValDef(res)
      }
      return res
    }
    const drawMetarsChart = () => {
      setTimeout(function () {
        const dataMetars = props.dataMetars
        const metarsSetup = props.metarsSetup
        const metarThreshold = props.metarThreshold
        if (dataMetars.DataMetars && metarsSetup && metarThreshold) {
          state.loadingBit = false
          // 气温饼图
          metarsSetup.TemChart.split(',').forEach(function (ele, index, arr) {
            const pieData = []
            switch (ele) {
              case 'tem_min':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min - 0 <= metarThreshold.TEM_cold_RD - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_RD + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min - 0 <= metarThreshold.TEM_cold_OG - 0 && tem.TEM_Min - 0 > metarThreshold.TEM_cold_RD - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_OG + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min - 0 <= metarThreshold.TEM_cold_YL - 0 && tem.TEM_Min - 0 > metarThreshold.TEM_cold_OG - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_YL + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min - 0 <= metarThreshold.TEM_cold_BU - 0 && tem.TEM_Min - 0 > metarThreshold.TEM_cold_YL - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_BU + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min - 0 > metarThreshold.TEM_cold_BU - 0 && tem.TEM_Min - 0 < 900000).length,
                  name: '未达阈值'
                })
                break
              case 'tem_max':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max - 0 >= metarThreshold.TEM_hot_RD - 0 && tem.TEM_Max - 0 < 900000).length,
                  name: '≥' + metarThreshold.TEM_hot_RD + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max - 0 >= metarThreshold.TEM_hot_OG - 0 && tem.TEM_Max - 0 < metarThreshold.TEM_hot_RD - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_OG + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max - 0 >= metarThreshold.TEM_hot_YL - 0 && tem.TEM_Max - 0 < metarThreshold.TEM_hot_OG - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_YL + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max - 0 >= metarThreshold.TEM_hot_BU - 0 && tem.TEM_Max - 0 < metarThreshold.TEM_hot_YL - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_BU + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max - 0 < metarThreshold.TEM_hot_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'tem_min_day':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min_Day - 0 <= metarThreshold.TEM_cold_RD - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_RD + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min_Day - 0 <= metarThreshold.TEM_cold_OG - 0 && tem.TEM_Min_Day - 0 > metarThreshold.TEM_cold_RD - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_OG + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min_Day - 0 <= metarThreshold.TEM_cold_YL - 0 && tem.TEM_Min_Day - 0 > metarThreshold.TEM_cold_OG - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_YL + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min_Day - 0 <= metarThreshold.TEM_cold_BU - 0 && tem.TEM_Min_Day - 0 > metarThreshold.TEM_cold_YL - 0).length,
                  name: '≤' + metarThreshold.TEM_cold_BU + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Min_Day - 0 > metarThreshold.TEM_cold_BU - 0 && tem.TEM_Min_Day - 0 < 900000).length,
                  name: '未达阈值'
                })
                break
              case 'tem_max_day':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max_Day - 0 >= metarThreshold.TEM_hot_RD - 0 && tem.TEM_Max_Day - 0 < 900000).length,
                  name: '≥' + metarThreshold.TEM_hot_RD + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max_Day - 0 >= metarThreshold.TEM_hot_OG - 0 && tem.TEM_Max_Day - 0 < metarThreshold.TEM_hot_RD - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_OG + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max_Day - 0 >= metarThreshold.TEM_hot_YL - 0 && tem.TEM_Max_Day - 0 < metarThreshold.TEM_hot_OG - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_YL + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max_Day - 0 >= metarThreshold.TEM_hot_BU - 0 && tem.TEM_Max_Day - 0 < metarThreshold.TEM_hot_YL - 0).length,
                  name: '≥' + metarThreshold.TEM_hot_BU + ' ℃'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.TEM_Max_Day - 0 < metarThreshold.TEM_hot_BU - 0).length,
                  name: '未达阈值'
                })
                break
            }
            initEcharts('chart_' + ele, pieData)
          })
          // 降水饼图
          metarsSetup.PreChart.split(',').forEach(function (ele, index, arr) {
            const pieData = []
            switch (ele) {
              case 'pre_1h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 >= metarThreshold.PRE_1hour_RD - 0 && tem.PRE_1h - 0 < 900000).length,
                  name: '≥' + metarThreshold.PRE_1hour_RD + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 >= metarThreshold.PRE_1hour_OG - 0 && tem.PRE_1h - 0 < metarThreshold.PRE_1hour_RD - 0).length,
                  name: '≥' + metarThreshold.PRE_1hour_OG + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 >= metarThreshold.PRE_1hour_YL - 0 && tem.PRE_1h - 0 < metarThreshold.PRE_1hour_OG - 0).length,
                  name: '≥' + metarThreshold.PRE_1hour_YL + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 >= metarThreshold.PRE_1hour_BU - 0 && tem.PRE_1h - 0 < metarThreshold.PRE_1hour_YL - 0).length,
                  name: '≥' + metarThreshold.PRE_1hour_BU + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_1h - 0 < metarThreshold.PRE_1hour_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'pre_3h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_3h - 0 >= metarThreshold.PRE_3hour_RD - 0 && tem.PRE_3h - 0 < 900000).length,
                  name: '≥' + metarThreshold.PRE_3hour_RD + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_3h - 0 >= metarThreshold.PRE_3hour_OG - 0 && tem.PRE_3h - 0 < metarThreshold.PRE_3hour_RD - 0).length,
                  name: '≥' + metarThreshold.PRE_3hour_OG + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_3h - 0 >= metarThreshold.PRE_3hour_YL - 0 && tem.PRE_3h - 0 < metarThreshold.PRE_3hour_OG - 0).length,
                  name: '≥' + metarThreshold.PRE_3hour_YL + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_3h - 0 >= metarThreshold.PRE_3hour_BU - 0 && tem.PRE_3h - 0 < metarThreshold.PRE_3hour_YL - 0).length,
                  name: '≥' + metarThreshold.PRE_3hour_BU + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_3h - 0 < metarThreshold.PRE_3hour_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'pre_6h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_6h - 0 >= metarThreshold.PRE_6hour_RD - 0 && tem.PRE_6h - 0 < 900000).length,
                  name: '≥' + metarThreshold.PRE_6hour_RD + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_6h - 0 >= metarThreshold.PRE_6hour_OG - 0 && tem.PRE_6h - 0 < metarThreshold.PRE_6hour_RD - 0).length,
                  name: '≥' + metarThreshold.PRE_6hour_OG + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_6h - 0 >= metarThreshold.PRE_6hour_YL - 0 && tem.PRE_6h - 0 < metarThreshold.PRE_6hour_OG - 0).length,
                  name: '≥' + metarThreshold.PRE_6hour_YL + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_6h - 0 >= metarThreshold.PRE_6hour_BU - 0 && tem.PRE_6h - 0 < metarThreshold.PRE_6hour_YL - 0).length,
                  name: '≥' + metarThreshold.PRE_6hour_BU + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_6h - 0 < metarThreshold.PRE_6hour_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'pre_12h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_12h - 0 >= metarThreshold.PRE_12hour_RD - 0 && tem.PRE_12h - 0 < 900000).length,
                  name: '≥' + metarThreshold.PRE_12hour_RD + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_12h - 0 >= metarThreshold.PRE_12hour_OG - 0 && tem.PRE_12h - 0 < metarThreshold.PRE_12hour_RD - 0).length,
                  name: '≥' + metarThreshold.PRE_12hour_OG + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_12h - 0 >= metarThreshold.PRE_12hour_YL - 0 && tem.PRE_12h - 0 < metarThreshold.PRE_12hour_OG - 0).length,
                  name: '≥' + metarThreshold.PRE_12hour_YL + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_12h - 0 >= metarThreshold.PRE_12hour_BU - 0 && tem.PRE_12h - 0 < metarThreshold.PRE_12hour_YL - 0).length,
                  name: '≥' + metarThreshold.PRE_12hour_BU + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_12h - 0 < metarThreshold.PRE_12hour_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'pre_24h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_24h - 0 >= metarThreshold.PRE_24hour_RD - 0 && tem.PRE_24h - 0 < 900000).length,
                  name: '≥' + metarThreshold.PRE_24hour_RD + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_24h - 0 >= metarThreshold.PRE_24hour_OG - 0 && tem.PRE_24h - 0 < metarThreshold.PRE_24hour_RD - 0).length,
                  name: '≥' + metarThreshold.PRE_24hour_OG + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_24h - 0 >= metarThreshold.PRE_24hour_YL - 0 && tem.PRE_24h - 0 < metarThreshold.PRE_24hour_OG - 0).length,
                  name: '≥' + metarThreshold.PRE_24hour_YL + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_24h - 0 >= metarThreshold.PRE_24hour_BU - 0 && tem.PRE_24h - 0 < metarThreshold.PRE_24hour_YL - 0).length,
                  name: '≥' + metarThreshold.PRE_24hour_BU + ' mm'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.PRE_24h - 0 < metarThreshold.PRE_24hour_BU - 0).length,
                  name: '未达阈值'
                })
                break
            }
            initEcharts('chart_' + ele, pieData)
          })
          // 降水饼图
          metarsSetup.WinChart.split(',').forEach(function (ele, index, arr) {
            const pieData = []
            switch (ele) {
              case 'win_s_2min':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 >= metarThreshold.WIN_RD - 0 && tem.WIN_S_Avg_2mi - 0 < 900000).length,
                  name: '≥' + metarThreshold.WIN_RD + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 >= metarThreshold.WIN_OG - 0 && tem.WIN_S_Avg_2mi - 0 < metarThreshold.WIN_RD - 0).length,
                  name: '≥' + metarThreshold.WIN_OG + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 >= metarThreshold.WIN_YL - 0 && tem.WIN_S_Avg_2mi - 0 < metarThreshold.WIN_OG - 0).length,
                  name: '≥' + metarThreshold.WIN_YL + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 >= metarThreshold.WIN_BU - 0 && tem.WIN_S_Avg_2mi - 0 < metarThreshold.WIN_YL - 0).length,
                  name: '≥' + metarThreshold.WIN_BU + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_Avg_2mi - 0 < metarThreshold.WIN_BU - 0).length,
                  name: '未达阈值'
                })
                break
              case 'win_s_inst':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_INST_Max - 0 >= metarThreshold.WIN_RD - 0 && tem.WIN_S_INST_Max - 0 < 900000).length,
                  name: '≥' + metarThreshold.WIN_RD + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_INST_Max - 0 >= metarThreshold.WIN_OG - 0 && tem.WIN_S_INST_Max - 0 < metarThreshold.WIN_RD - 0).length,
                  name: '≥' + metarThreshold.WIN_OG + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_INST_Max - 0 >= metarThreshold.WIN_YL - 0 && tem.WIN_S_INST_Max - 0 < metarThreshold.WIN_OG - 0).length,
                  name: '≥' + metarThreshold.WIN_YL + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_INST_Max - 0 >= metarThreshold.WIN_BU - 0 && tem.WIN_S_INST_Max - 0 < metarThreshold.WIN_YL - 0).length,
                  name: '≥' + metarThreshold.WIN_BU + ' m/s'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.WIN_S_INST_Max - 0 < metarThreshold.WIN_BU - 0).length,
                  name: '未达阈值'
                })
                break
            }
            initEcharts('chart_' + ele, pieData)
          })
          // 能见度饼图
          metarsSetup.VisChart.split(',').forEach(function (ele, index, arr) {
            const pieData = []
            switch (ele) {
              case 'vis_min':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 <= metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_RD + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 <= metarThreshold.VIS_OG - 0 && tem.VIS_Min - 0 > metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_OG + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 <= metarThreshold.VIS_YL - 0 && tem.VIS_Min - 0 > metarThreshold.VIS_OG - 0).length,
                  name: '≤' + metarThreshold.VIS_YL + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 <= metarThreshold.VIS_BU - 0 && tem.VIS_Min - 0 > metarThreshold.VIS_YL - 0).length,
                  name: '≤' + metarThreshold.VIS_BU + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min - 0 > metarThreshold.VIS_BU - 0 && tem.VIS_Min - 0 < 900000).length,
                  name: '未达阈值'
                })
                break
              case 'vis_min_day':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_Day - 0 <= metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_RD + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_Day - 0 <= metarThreshold.VIS_OG - 0 && tem.VIS_Min_Day - 0 > metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_OG + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_Day - 0 <= metarThreshold.VIS_YL - 0 && tem.VIS_Min_Day - 0 > metarThreshold.VIS_OG - 0).length,
                  name: '≤' + metarThreshold.VIS_YL + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_Day - 0 <= metarThreshold.VIS_BU - 0 && tem.VIS_Min_Day - 0 > metarThreshold.VIS_YL - 0).length,
                  name: '≤' + metarThreshold.VIS_BU + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_Day - 0 > metarThreshold.VIS_BU - 0 && tem.VIS_Min_Day - 0 < 900000).length,
                  name: '未达阈值'
                })
                break
              case 'vis_min_24h':
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_24h - 0 <= metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_RD + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_24h - 0 <= metarThreshold.VIS_OG - 0 && tem.VIS_Min_24h - 0 > metarThreshold.VIS_RD - 0).length,
                  name: '≤' + metarThreshold.VIS_OG + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_24h - 0 <= metarThreshold.VIS_YL - 0 && tem.VIS_Min_24h - 0 > metarThreshold.VIS_OG - 0).length,
                  name: '≤' + metarThreshold.VIS_YL + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_24h - 0 <= metarThreshold.VIS_BU - 0 && tem.VIS_Min_24h - 0 > metarThreshold.VIS_YL - 0).length,
                  name: '≤' + metarThreshold.VIS_BU + ' m'
                })
                pieData.push({
                  value: dataMetars.DataMetars.filter(tem => tem.VIS_Min_24h - 0 > metarThreshold.VIS_BU - 0 && tem.VIS_Min_24h - 0 < 900000).length,
                  name: '未达阈值'
                })
                break
            }
            initEcharts('chart_' + ele, pieData)
          })
        } else {
          drawMetarsChart()
        }
      }, 1000)
    }
    // 插入饼图
    const initEcharts = (eleId, pieData) => {
      if (state.metarCharts.some(tem => tem.name === eleId)) {
        state.metarCharts.filter(tem => tem.name === eleId).forEach(function (ele, index, arr) {
          ele.chart.setOption({ series: { data: pieData } }, false)
        })
      } else {
        // 饼图
        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: <br/>{c} ({d}%)'
          },
          legend: {
            show: false
          },
          color: ['#b91d06', '#e27d35', '#e7e897', '#277bd1', '#183365'],
          series: [
            {
              name: '小时最低气温',
              type: 'pie',
              top: 0,
              bottom: 0,
              radius: ['48%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'normal',
                  color: 'rgba(255, 255, 255, 0.8)'
                }
              },
              labelLine: {
                show: false
              },
              data: pieData
            }
          ]
        }

        if (document.getElementById(eleId)) {
          const myChart = echarts.init(document.getElementById(eleId))
          myChart.setOption(option)

          state.metarCharts.push({
            name: eleId,
            chart: myChart
          })
        }
      }
    }
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (e) => {
      const swsMetarsNav = document.getElementById('swsMetarsNav')
      const lis = swsMetarsNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (lis[i] === e.target) {
          state.mySwiper.slideTo(i)
        }
      }
    }
    const onSlideChange = () => {
      const swsMetarsNav = document.getElementById('swsMetarsNav')
      const lis = swsMetarsNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
        } else {
          lis[i].className = ''
        }
      }
    }
    const openMaxz = (maxzType) => {
      emit('openMaxz', maxzType)
    }
    const openStationInfo = (staID, type) => {
      const openStation = {
        staID: staID,
        type: type
      }
      store.commit('setOpenStation', openStation)
    }
    watch(() => props.dataMetars.UpdateTime, () => {
      state.updateTime = props.dataMetars.UpdateTime.substring(5, 16)
      drawMetarsChart()
    })
    onMounted(() => {
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      openStationInfo,
      orderMetar,
      updateOrd,
      getValue,
      drawMetarsChart,
      initEcharts,
      slideTo,
      onSwiper,
      onSlideChange,
      openMaxz
    }
  }
}
</script>
