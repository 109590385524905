<template>
  <div class="sws-setup-head">
      <div class="filter">
        <input v-model="keyword" placeholder="搜索联系人/手机号" @change="loadList" />
      </div>
  </div>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:8%;">
          <col style="width:12%;">
          <col style="width:55%;">
          <col style="width:7%;">
          <col style="width:18%;">
        </colgroup>
        <thead>
          <tr>
            <th>联系人</th>
            <th>通知号码</th>
            <th>内容</th>
            <th>状态</th>
            <th>创建时间</th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:8%;">
          <col style="width:12%;">
          <col style="width:55%;">
          <col style="width:7%;">
          <col style="width:18%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td :title="item.LinkmanName">{{item.LinkmanName.length > 3 ? item.LinkmanName.substring(0,3) + ' ...' : item.LinkmanName}}</td>
            <td :title="item.PhoneNo">{{item.PhoneNo}}</td>
            <td :title="item.Con">{{item.Con.length > 35 ? item.Con.substring(0,35) + ' ...' : item.Con}}</td>
            <td :title="item.State ? '已推送' : '未推送' ">{{item.State ? '已推送' : '未推送' }}</td>
            <td :title="item.CreateTime">{{item.CreateTime}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
import common from '@/assets/js/common'
import { useStore } from 'vuex'

export default {
  name: 'SetupMsgSmsLog',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      listData: [],
      pagingInfo: '',
      pagingKey: 0
    })
    const loadList = (page = 1) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMsgSmsLog/List?keyword=' + state.keyword + '&page=' + page
      }).then(function (res) {
        res.data.MsgSmses.forEach(function (ele, index, arr) {
          ele.CreateTime = common.timeToStrDate(ele.CreateTime)
        })
        state.listData = res.data.MsgSmses
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    const goPage = (page) => {
      loadList(page)
    }
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      goPage,
      loadList
    }
  }
}
</script>
