<template>
<div>
  <div class="sws-side-menu">
    <ul id="swsMeteoNav">
      <li v-for="(item, index) in meteoData" :key="index" @click="slideTo(index)" v-bind:class="{'active': index === 0}">{{item.MyMeteoArea.AreaName}}</li>
    </ul>
    <div class="sws-side-updatetime" id="swsMeteoUpdateTime">{{timeText}}</div>
  </div>
  <swiper
    :modules="modules"
    :scrollbar="{ draggable: true }"
    :autoplay="{ delay: 10000 }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    class="sws-meteo"
  >
    <swiper-slide v-for="(item, index) in meteoData" :key="index">
      <template v-if="item.CusMeteo && layoutType == 'meteo-12'">
        <div class="sws-meteo-cusmeteo" v-html="item.CusMeteo.Con"></div>
      </template>
      <div class="sws-meteo-day7">
        <div v-for="(itemSec, indexSec) in item.ForecastDays" :key="indexSec" class="sws-meteo-day" :style="{width: 440 / item.ForecastDays.length + 'px'}">
          <div class="sws-meteo-day-date">{{itemSec.Date}}</div>
          <div class="weathericon_day" v-if="layoutType == 'meteo-12_2' || !item.CusMeteo">
            <svg class="iconfont" aria-hidden="true">
              <use :xlink:href="itemSec.Weather_D_Code" />
            </svg>
          </div>
          <div class="weathertxt_day">{{itemSec.Weather_D_Txt}}</div>
          <div class="wind_day" :style="itemSec.Wind_D_Txt.length >= 5 ? 'font-size:12px;' : ''">{{itemSec.Wind_D_Txt}}</div>
          <div class="wins_day">{{itemSec.Wind_Power.indexOf('转') != -1 ? itemSec.Wind_Power.split('转')[0] : itemSec.Wind_Power}}</div>
          <div class="tem7day-chart-seat"></div>
          <div class="weathericon_night" v-if="layoutType == 'meteo-12_2' || !item.CusMeteo">
            <svg class="iconfont" aria-hidden="true">
              <use :xlink:href="itemSec.Weather_N_Code" />
            </svg>
          </div>
          <div class="weathertxt_night">{{itemSec.Weather_N_Txt}}</div>
          <div class="wind_night" :style="itemSec.Wind_N_Txt.length >= 5 ? 'font-size:12px;' : ''">{{itemSec.Wind_N_Txt}}</div>
          <div class="wins_night">{{itemSec.Wind_Power.indexOf('转') != -1 ? itemSec.Wind_Power.split('转')[1] : itemSec.Wind_Power}}</div>
        </div>
        <div class="sws-meteo-day7-chart" v-html="charts[index]"></div>
      </div>
    </swiper-slide>
  </swiper>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import common from '@/assets/js/common'
import 'swiper/css'
import { useStore } from 'vuex'

export default {
  name: 'LayoutPartMeteo',
  components: {
    Swiper,
    SwiperSlide
  },
  props: ['layoutSize', 'layoutType'],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      layoutSize: props.layoutSize,
      layoutType: props.layoutType,
      mySwiper: {},
      meteoData: [],
      timeVal: [],
      timeText: '',
      charts: []
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (index) => {
      state.mySwiper.slideTo(index)
    }
    const onSlideChange = () => {
      const swsMeteoNav = document.getElementById('swsMeteoNav')
      const lis = swsMeteoNav.getElementsByTagName('LI')
      for (let i = 0; i < lis.length; i++) {
        if (i === state.mySwiper.realIndex) {
          lis[i].className = 'active'
          state.timeText = (state.timeVal[i])
        } else {
          lis[i].className = ''
        }
      }
    }
    const loadSetup = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataMeteo'
      }).then(function (res) {
        state.meteoData = res.data.DataMeteos
        state.charts = []
        res.data.DataMeteos.forEach(function (ele, index, arr) {
          state.charts.push(common.makeMeteo7dayChart(ele.ForecastDays, 450, 120))
        })
      })
    }
    const autoload = () => {
      loadSetup()
      setTimeout(autoload, 300000)
    }
    onMounted(() => {
      autoload()
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      slideTo,
      onSwiper,
      onSlideChange
    }
  }
}
</script>
