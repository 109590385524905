<template>
<div class="sws-maximize" v-loading="loadingBit" element-loading-background="rgba(255,255,255,0.05)">
  <div class="sws-maximize-menu">
    <ul id="swsMetarMaximizeNav">
      <li v-for="(item, index) in radarSatellites" :key="index" v-bind:class="{'active': index == mySwiper.realIndex}" @click="slideTo(index)">{{item.Name}}</li>
    </ul>
    <div class="sws-maximize-tool pull-right">
    </div>
  </div>
  <div class="sws-maximize-con">
    <div class="sws-radarsatellite-maximize">
      <swiper class="big-banner"
        :modules="modules"
        :scrollbar="{ draggable: true }"
        :autoplay="false"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in radarSatellites" :key="index">
          <div class="sws-radarsatellite-maximize-img">
            <img v-if="radarSatellitedata.length > 0" :src="radarSatellitedata[index].Src[imgIndexs[index]]" :id="'img2_' + item.Name_EN" />
          </div>
          <div class="sws-radarsatellite-maximize-ctrl">
            <button @click="imgBack(index)">上一张</button>
            <button @click="autoPlayBit = !autoPlayBit; autoPlay()">{{autoPlayBit == true ? "暂停" : "播放"}}</button>
            <button @click="imgNext(index)">下一张</button>
          </div>
          <div v-if="radarSatellitedata.length > 0" class="sws-radarsatellite-maximize-btn">
            <button v-for="(item2, index2) in radarSatellitedata[index].Time" v-bind:class="{'active': index2==imgIndexs[index]}" :key="index2" @click="imgIndexs[index] = index2">{{item2}}</button>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <div style="clear:both;"></div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useStore } from 'vuex'

export default {
  name: 'DataRadarSatellite',
  components: {
    Swiper,
    SwiperSlide
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      mySwiper: {},
      loadingBit: false,
      radarSatellites: [],
      radarSatellitedata: [],
      menuIndex: 0,
      imgIndexs: [0, 0, 0, 0],
      autoPlay: '',
      autoPlayBit: false
    })
    const onSwiper = (swiper) => {
      state.mySwiper = swiper
    }
    const slideTo = (i) => {
      state.mySwiper.slideTo(i)
    }
    const onSlideChange = () => {
      state.autoPlayBit = false
    }
    const updateRadarSatelliteData = () => {
      state.loadingBit = true
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataRadarSatellite/data'
      }).then(function (res) {
        state.loadingBit = false
        const data = res.data.RadarSatellites
        state.radarSatellitedata = data
        const imgIndexs = []
        data.forEach(function (ele, index, arr) {
          imgIndexs.push(ele.Src.length - 1)
        })
        state.imgIndexs = imgIndexs
      })
    }
    const loadRadarSatelliteSetup = () => {
      state.loadingBit = true
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataRadarSatellite/setup'
      }).then(function (res) {
        state.radarSatellites = res.data.RadarSatellites
        updateRadarSatelliteData()
      })
    }
    const imgNext = (index) => {
      if (state.imgIndexs[index] >= state.radarSatellitedata[index].Src.length - 1) {
        state.imgIndexs[index] = 0
      } else {
        state.imgIndexs[index]++
      }
    }
    const imgBack = (index) => {
      if (state.imgIndexs[index] <= 0) {
        state.imgIndexs[index] = state.radarSatellitedata[index].Src.length - 1
      } else {
        state.imgIndexs[index]--
      }
    }
    const autoPlay = () => {
      if (state.autoPlayBit) {
        try {
          imgNext(state.mySwiper.realIndex)
          state.autoPlay = setTimeout(autoPlay, 1000)
        } catch (error) {
          console.log(error)
        }
      }
    }
    onMounted(() => {
      loadRadarSatelliteSetup()
    })
    return {
      ...toRefs(state),
      modules: [Autoplay],
      onSwiper,
      slideTo,
      onSlideChange,
      loadRadarSatelliteSetup,
      imgNext,
      imgBack,
      autoPlay
    }
  }
}
</script>
