<template>
  <TopNavView
  v-if="appCode != ''"
  :cusInfo=cusInfo
  :appConfig=appConfig
  :documentTitle=documentTitle
  @openSetupWin="openSetupWin"
  ></TopNavView>
  <MapView
  v-if="appCode != ''"
  :cusInfo=cusInfo
  :dataMetars=dataMetars
  :dataCusMetars=dataCusMetars
  :metarThreshold=metarThreshold
  :metarThresholdCusSta=metarThresholdCusSta
  @closeData="closeSetup"
  ></MapView>
  <SideDataView
  v-if="appCode != ''"
  :dataMetars=dataMetars
  :metarsSetup=metarsSetup
  :metarThreshold=metarThreshold
  :metarThresholdCusSta=metarThresholdCusSta
  @openMaxz="openMaxz"
  ></SideDataView>
  <SetupView
  v-if="setupViewInfo.type && appCode != ''"
  :setupViewInfo="setupViewInfo"
  @closeSetup="closeSetup"
  >
  </SetupView>
  <DataView
  v-if="dataViewInfo.type && appCode != ''"
  :dataViewInfo="dataViewInfo"
  :metarsSetup=metarsSetup
  :metarThreshold=metarThreshold
  @closeData="closeSetup"
  >
  </DataView>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import TopNavView from '../components/TopNav'
import SideDataView from '../components/SideData'
import MapView from '../components/MapContainer'
import SetupView from '../components/SetupView'
import DataView from '../components/DataView'
import { useStore } from 'vuex'

export default ({
  name: 'HomeView',
  components: {
    MapView,
    TopNavView,
    SideDataView,
    SetupView,
    DataView
  },
  setup () {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      autoloadBit: false,
      cusInfo: '',
      documentTitle: '',
      appConfig: '',
      dataMetars: '',
      dataCusMetars: '',
      metarsSetup: null,
      metarThreshold: null,
      metarThresholdCusSta: null,
      setupViewInfo: {
        type: '',
        title: ''
      },
      dataViewInfo: {
        type: '',
        title: ''
      }
    })
    const loadMetars = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataMetar/Metars?appcode=' + state.appCode
      }).then(function (res) {
        const data = res.data
        store.commit('setStationInfos', data.StationInfos)
        if (data.State === 'ok' && data.DataMetars && data.DataMetars.length > 0) {
          for (let i = 0; i < data.DataMetars.length; i++) {
            data.StationInfos.filter(tem => tem.Station_ID_C === data.DataMetars[i].Station_ID_C).forEach(function (ele, index, arr) {
              data.DataMetars[i].Station_Name = ele.Station_Name
              data.DataMetars[i].City = ele.City
              data.DataMetars[i].Cnty = ele.Cnty
              data.DataMetars[i].Town = ele.Town
              data.DataMetars[i].Lon = ele.Lon - 0
              data.DataMetars[i].Lat = ele.Lat - 0
              data.DataMetars[i].Admin_Code_CHN = ele.Admin_Code_CHN
              data.DataMetars[i].Alti = ele.Alti
              data.DataMetars[i].Lvl = ele.Lvl
              data.DataMetars[i].State = ele.State
            })
          }
          state.dataMetars = res.data
        }
      })
    }
    const loadCusMetars = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/DataCusMetar/Metars?appcode=' + state.appCode
      }).then(function (res) {
        const data = res.data
        store.commit('setCusStationInfos', data.CusStationInfos)
        if (data.State === 'ok' && data.DataCusMetars) {
          for (let i = 0; i < data.DataCusMetars.length; i++) {
            data.CusStationInfos.filter(tem => tem.Station_ID_C === data.DataCusMetars[i].Station_ID_C).forEach(function (ele, index, arr) {
              data.DataCusMetars[i].CusStationTypeID = ele.CusStationTypeID
              data.DataCusMetars[i].CusStationTypeName = ele.CusStationTypeName
              data.DataCusMetars[i].Station_Name = ele.Station_Name
              data.DataCusMetars[i].City = ele.City
              data.DataCusMetars[i].Cnty = ele.Cnty
              data.DataCusMetars[i].Town = ele.Town
              data.DataCusMetars[i].Village = ele.Village
              data.DataCusMetars[i].Lon = ele.Lon - 0
              data.DataCusMetars[i].Lat = ele.Lat - 0
            })
          }
        }
        state.dataCusMetars = res.data
      })
    }
    const setAppSize = () => {
      let width = window.innerWidth
      let height = window.innerHeight

      width = width <= 1920 ? 1920 : width
      height = height <= 1080 ? 1080 : height
      const app = document.getElementById('app')
      app.style.width = width + 'px'
      app.style.height = height + 'px'
    }
    const openSetupWin = (typeName, titleName) => {
      closeSetup()
      state.setupViewInfo.type = typeName
      state.setupViewInfo.title = titleName
    }
    const openDataWin = (typeName, titleName) => {
      closeSetup()
      store.commit('delOpenStation')
      state.dataViewInfo.type = typeName
      state.dataViewInfo.title = titleName
    }
    const closeSetup = () => {
      state.setupViewInfo.type = ''
      state.setupViewInfo.title = ''
      state.dataViewInfo.type = ''
      state.dataViewInfo.title = ''
    }
    const getCommand = () => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/other/GetCommand?appcode=' + state.appCode
      }).then(function (res) {
        if (res.data.LoginOut) {
          alert(res.data.Msg)
          state.autoloadBit = false
          store.commit('delToken')
          store.commit('delUserName')
          store.commit('delAppCode')
          store.commit('delNickName')

          const url = '#/login/'
          location.href = url
        }
        if (res.data.RefreshBit === true) {
          location.reload()
        }
      })
    }
    const autoload = () => {
      if (state.autoloadBit) {
        const now = new Date()
        getCommand()
        if (now.getMinutes() % 5 === 2) {
          loadMetars()
          loadCusMetars()
        }

        setTimeout(function () {
          autoload()
        }, 60000)
      }
    }
    const openMaxz = (maxzType, title) => {
      openDataWin(maxzType, title)
    }
    onMounted(() => {
      const token = store.getters.getToken
      if (token === '') {
        const url = '#/login/'
        location.href = url
      } else {
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/other/cusinfo?appcode=' + state.appCode
        }).then(function (res) {
          state.appConfig = res.data.AppConfig
          state.cusInfo = res.data.CusInfo
          document.title = res.data.Title
          state.documentTitle = res.data.Title
        })
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/other/MetarsSetup?appcode=' + state.appCode
        }).then(function (res) {
          state.metarsSetup = res.data.MetarsSetup
          state.metarThreshold = res.data.MetarThresholdSta
          state.metarThresholdCusSta = res.data.MetarThresholdCusSta
        })

        loadMetars()
        loadCusMetars()
        state.autoloadBit = true
        autoload()
      }

      window.onresize = () => {
        setAppSize()
      }
    })
    return {
      ...toRefs(state),
      loadMetars,
      loadCusMetars,
      openSetupWin,
      openDataWin,
      closeSetup,
      autoload,
      openMaxz
    }
  }
})
</script>
<style >
  #app {
    height: 100%;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
</style>
<style lang="scss">
@import "../assets/styles/iconfont/iconfont.css";
$img:"../assets/images";
@import "../assets/styles/main.scss";  //引入方式
</style>
