<template>
  <div class="sws-setup-head">
      <div class="filter">
        <input v-model="keyword" placeholder="搜索预警名称/预警等级" @change="loadList" />
      </div>
      <div class="edit">
          <button class="iconfont" @click="edit(0)">&#xe711; 添加</button>
      </div>
  </div>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:20%;">
          <col style="width:42%;">
          <col style="width:8%;">
        </colgroup>
        <thead>
          <tr>
            <th>预警名称</th>
            <th>预警等级(含更高)</th>
            <th>强制显示阀值等级</th>
            <th>强制显示气象要素</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:15%;">
          <col style="width:15%;">
          <col style="width:20%;">
          <col style="width:42%;">
          <col style="width:8%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td :title="item.EventType_CN">{{item.EventType_CN}}</td>
            <td :title="item.Severity_CN">{{item.Severity_CN}}</td>
            <td :title="item.ThresholdDisplay">{{item.ThresholdDisplay}}</td>
            <td :title="item.SurfEleDisplay">{{item.SurfEleDisplay.length > 28 ? item.SurfEleDisplay.substring(0,28) + ' ...' : item.SurfEleDisplay}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(item.ID)">&#xec88;</button>
              <button class="iconfont" @click="del(item.ID)">&#xec7b;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
  <div v-if="editData != ''" class="sws-modal-mask">
    <div class="sws-modal">
      <div class="sws-modal-head">
        <div class="sws-modal-tool">
          <i class="iconfont close" @click="closeEdit">&#xe70f;</i>
        </div>
        <div class="title">
          {{editTitle}}
        </div>
      </div>
      <div class="sws-modal-body">
        <el-form label-width="120px">
          <el-form-item label="预警名称">
            <el-select v-model="editData.EventType_CN" class="m-2" filterable placeholder="选择预警名称" style="width:100%;">
              <el-option
                v-for="item in warningEventTypes"
                :key="item"
                :label="item"
                :value="item"
                />
            </el-select>
          </el-form-item>
          <el-form-item label="预警等级">
            <el-select v-model="editData.Severity_CN" class="m-2" filterable placeholder="选择预警等级" style="width:100%;">
              <el-option
                v-for="item in warningSeverities"
                :key="item"
                :label="item"
                :value="item"
                />
            </el-select>
          </el-form-item>
          <el-form-item label="强制显示等级">
          <el-checkbox-group v-model="editData.ThresholdDisplay">
            <el-checkbox label="blu">蓝色</el-checkbox>
            <el-checkbox label="yel">黄色</el-checkbox>
            <el-checkbox label="org">橙色</el-checkbox>
            <el-checkbox label="red">红色</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
          <el-form-item label="强制显示要素">
          <el-checkbox-group v-model="editData.SurfEleDisplay">
            <el-checkbox v-for="(item, index) in surfEleDisplay" :key="index" :label="item.Val">{{item.Name}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="色斑图">
          <el-checkbox v-model="editData.ImgSurferTemState" label="气温色斑图"/>
          <el-checkbox v-model="editData.ImgSurferPreState" label="降水色斑图"/>
        </el-form-item>
        <el-form-item label="雷达图、云图">
            <el-checkbox v-model="editData.ImgRadarState" label="雷达图"/>
            <el-checkbox v-model="editData.ImgSatelliteState" label="云图"/>
        </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="editData.AliasName" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="closeEdit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="sws-modal-foot"></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'SetupWarningMode',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      listData: [],
      pagingInfo: '',
      pagingKey: 0,
      editData: '',
      editTitle: '',
      surfEleDisplay: [],
      thresholdDisplay: [],
      warningEventTypes: [],
      warningModel: [],
      warningSeverities: []
    })
    // 数据列表
    const loadList = (page = 1) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupWarningMode/List?keyword=' + state.keyword + '&page=' + page
      }).then(function (res) {
        res.data.WarningModels.forEach(function (ele, index, arr) {
          ele.ThresholdDisplay = ele.ThresholdDisplay.replace('blu', '蓝色').replace('yel', '黄色').replace('org', '橙色').replace('red', '红色')
          ele.SurfEleDisplay = ele.SurfEleDisplay.replace('tem', '气温')
          ele.SurfEleDisplay = ele.SurfEleDisplay.replace('pre_1h', '1小时降水').replace('pre_3h', '3小时降水').replace('pre_6h', '6小时降水').replace('pre_12h', '12小时降水')
          ele.SurfEleDisplay = ele.SurfEleDisplay.replace('pre_24h', '24小时降水').replace('pre_48h', '48小时降水').replace('pre_72h', '72小时降水')
          ele.SurfEleDisplay = ele.SurfEleDisplay.replace('win', '极大风').replace('rhu', '湿度').replace('vis', '能见度')
        })
        state.listData = res.data.WarningModels
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    // 加载指定页
    const goPage = (page) => {
      loadList(page)
    }
    // 打开编辑页
    const edit = (id = 0) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupWarningMode/Edit?id=' + id
      }).then(function (res) {
        if (res.data.WarningModel.ThresholdDisplay !== null && res.data.WarningModel.ThresholdDisplay !== '') {
          res.data.WarningModel.ThresholdDisplay = res.data.WarningModel.ThresholdDisplay.split(',')
        } else {
          res.data.WarningModel.ThresholdDisplay = []
        }
        if (res.data.WarningModel.SurfEleDisplay !== null && res.data.WarningModel.SurfEleDisplay !== '') {
          res.data.WarningModel.SurfEleDisplay = res.data.WarningModel.SurfEleDisplay.split(',')
        } else {
          res.data.WarningModel.SurfEleDisplay = []
        }

        state.warningEventTypes = res.data.WarningEventTypes
        state.warningSeverities = res.data.WarningSeverities
        state.surfEleDisplay = res.data.SurfEleDisplay

        state.editData = res.data.WarningModel
        state.editTitle = res.data.EditTitle
      })
    }
    // 提交编辑页
    const onSubmit = () => {
      const obj = {
        AppCode: state.appCode,
        EventType_CN: state.editData.EventType_CN,
        ID: state.editData.ID,
        ImgRadarState: state.editData.ImgRadarState,
        ImgSatelliteState: state.editData.ImgSatelliteState,
        ImgSurferPreState: state.editData.ImgSurferPreState,
        ImgSurferTemState: state.editData.ImgSurferTemState,
        Severity_CN: state.editData.Severity_CN,
        SurfEleDisplay: state.editData.SurfEleDisplay.join(','),
        ThresholdDisplay: state.editData.ThresholdDisplay.join(','),
        UserName: state.userName
      }

      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupWarningMode/Update',
        data: obj
      }).then(function (res) {
        if (res.data.State === 'error') {
          ElMessage({
            showClose: true,
            message: res.data.Msg
          })
        } else {
          loadList(state.pagingInfo.CurrentPage)
          state.editData = ''
        }
      })
    }
    // 删除
    const del = (id) => {
      ElMessageBox.confirm(
        '确定删除?',
        '提醒',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/SetupWarningMode/Del?id=' + id
        }).then(function (res) {
          if (res.data.State === 'ok') {
            loadList(state.pagingInfo.CurrentPage)
          } else if (res.data.State === 'error') {
            ElMessage({
              showClose: true,
              message: res.data.Msg
            })
          }
        })
      }).catch(() => {
      })
    }
    // 关闭编辑页
    const closeEdit = () => {
      state.editData = ''
      state.staData = []
    }
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      goPage,
      loadList,
      edit,
      onSubmit,
      del,
      closeEdit
    }
  }
}
</script>
