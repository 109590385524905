<template>
<div>
  <div class="sws-side-wrap" v-if="layoutType.split('-')[0]=='metars'">
    <LayoutPartMetars
      :layoutSize=layoutSize
      :layoutType=layoutType
      :dataMetars=dataMetars
      :metarsSetup=metarsSetup
      :metarThreshold=metarThreshold
      @openMaxz="openMaxz"
    ></LayoutPartMetars>
  </div>
  <div class="sws-side-wrap" v-if="layoutType.split('-')[0]=='radarsatellite'">
    <LayoutPartRadarSatellite
      :layoutSize=layoutSize
      :layoutType=layoutType
      @openMaxz="openMaxz"
    ></LayoutPartRadarSatellite>
  </div>
  <div class="sws-side-wrap" v-if="layoutType.split('-')[0]=='metar'">
    <LayoutPartMetar
      :layoutSize=layoutSize
      :layoutType=layoutType
    ></LayoutPartMetar>
  </div>
  <div class="sws-side-wrap" v-if="layoutType.split('-')[0]=='meteo'">
    <LayoutPartMeteo
      :layoutSize=layoutSize
      :layoutType=layoutType
    ></LayoutPartMeteo>
  </div>
</div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import LayoutPartMetars from './LayoutPartMetars'
import LayoutPartMetar from './LayoutPartMetar'
import LayoutPartMeteo from './LayoutPartMeteo'
import LayoutPartRadarSatellite from './LayoutPartRadarSatellite'

export default {
  name: 'LayoutPart',
  components: {
    LayoutPartMetars,
    LayoutPartMetar,
    LayoutPartMeteo,
    LayoutPartRadarSatellite
  },
  props: ['layoutSize', 'layoutType', 'dataMetars', 'metarsSetup', 'metarThreshold', 'title'],
  setup (props, { emit }) {
    const state = reactive({
      layoutSize: props.layoutSize,
      layoutType: props.layoutType,
      dataMetars: props.dataMetars,
      metarsSetup: props.metarsSetup,
      metarThreshold: props.metarThreshold,
      title: props.title
    })
    const openMaxz = (maxzType) => {
      emit('openMaxz', maxzType, state.title)
    }
    onMounted(() => {

    })
    return {
      ...toRefs(state),
      openMaxz
    }
  }
}
</script>
<style lang="scss">
$img:"../assets/images";
@import "../assets/styles/sidedata.scss";  //引入方式
</style>
