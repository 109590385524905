<template>
  <div class="sws-setup-head">
      <div class="filter">
        <input v-model="keyword" placeholder="搜索站号/站名" @change="loadList" />
      </div>
      <div class="edit">
          <button class="iconfont" @click="edit(0)">&#xe711; 添加</button>
      </div>
  </div>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:20%;">
          <col style="width:40%;">
          <col style="width:30%;">
          <col style="width:10%;">
        </colgroup>
        <thead>
          <tr>
            <th>站名</th>
            <th>站号</th>
            <th>别名</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:20%;">
          <col style="width:40%;">
          <col style="width:30%;">
          <col style="width:10%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td :title="item.Station_Name">{{item.Station_Name}}</td>
            <td :title="item.Station_ID_C">{{item.Station_ID_C}}</td>
            <td :title="item.AliasName">{{item.AliasName}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(item.ID)">&#xec88;</button>
              <button class="iconfont" @click="del(item.ID)">&#xec7b;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
  <div v-if="editData != ''" class="sws-modal-mask">
    <div class="sws-modal">
      <div class="sws-modal-head">
        <div class="sws-modal-tool">
          <i class="iconfont close" @click="closeEdit">&#xe70f;</i>
        </div>
        <div class="title">
          {{editData != '' && editData.ID != 0 ? '编辑重点实况站点' : '创建重点实况站点'}}
        </div>
      </div>
      <div class="sws-modal-body">
        <el-form label-width="120px">
          <el-form-item label="站点">
            <el-select v-model="editData.Station_ID_C" class="m-2" filterable placeholder="选择站点" style="width:100%;">
              <el-option
                v-for="item in staData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="editData.AliasName" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="closeEdit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="sws-modal-foot"></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useStore } from 'vuex'

export default {
  name: 'SetupMyStation',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      listData: [],
      pagingInfo: '',
      pagingKey: 0,
      staData: [],
      editData: ''
    })
    // 数据列表
    const loadList = (page = 1) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMyStation/List?keyword=' + state.keyword + '&page=' + page
      }).then(function (res) {
        state.listData = res.data.MyStations
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    // 加载指定页
    const goPage = (page) => {
      loadList(page)
    }
    // 打开编辑页
    const edit = (id = 0) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMyStation/Edit?id=' + id
      }).then(function (res) {
        state.staData = []
        res.data.StationInfos.forEach(function (ele, index, arr) {
          state.staData.push({
            label: ele.Station_Name,
            value: ele.Station_ID_C
          })
        })

        state.editData = res.data.MyStation
      })
    }
    // 提交编辑页
    const onSubmit = () => {
      state.editData.AppCode = state.appCode
      state.editData.UserName = state.userName
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupMyStation/Update',
        data: state.editData
      }).then(function (res) {
        if (res.data.State === 'error') {
          ElMessage({
            showClose: true,
            message: res.data.Msg
          })
        } else {
          loadList(state.pagingInfo.CurrentPage)
          state.editData = ''
          state.staData = []
        }
      })
    }
    // 删除
    const del = (id) => {
      ElMessageBox.confirm(
        '确定删除?',
        '提醒',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'http://swsapi.bjzkzm.com/SetupMyStation/Del?id=' + id
        }).then(function (res) {
          if (res.data.State === 'ok') {
            loadList(state.pagingInfo.CurrentPage)
          } else if (res.data.State === 'error') {
            ElMessage({
              showClose: true,
              message: res.data.Msg
            })
          }
        })
      }).catch(() => {
      })
    }
    // 关闭编辑页
    const closeEdit = () => {
      state.editData = ''
      state.staData = []
    }
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      goPage,
      loadList,
      edit,
      onSubmit,
      del,
      closeEdit
    }
  }
}
</script>
