<template>
  <div class="sws-setup-head">
      <div class="filter">
        <input v-model="keyword" placeholder="搜索站号/站名" @change="loadList" />
      </div>
      <div class="edit">
          <button class="iconfont" @click="edit(0)">&#xe711; 添加</button>
      </div>
  </div>
  <div class="sws-setup-body">
    <div class="table-head">
      <table>
        <colgroup>
          <col style="width:20%;">
          <col style="width:20%;">
          <col style="width:10%;">
          <col style="width:7%;">
          <col style="width:15%;">
          <col style="width:10%;">
          <col style="width:10%;">
          <col style="width:8%;">
        </colgroup>
        <thead>
          <tr>
            <th>自动站</th>
            <th>自定义站</th>
            <th>监测要素</th>
            <th>阈值</th>
            <th>联系人</th>
            <th>时间间隔</th>
            <th>增量提醒</th>
            <th></th>
          </tr>
        </thead>
      </table>
    </div>
    <div class="table-body">
      <table>
        <colgroup>
          <col style="width:20%;">
          <col style="width:20%;">
          <col style="width:10%;">
          <col style="width:7%;">
          <col style="width:15%;">
          <col style="width:10%;">
          <col style="width:10%;">
          <col style="width:8%;">
        </colgroup>
        <tbody>
          <tr v-for="(item, index) in listData" :key="index">
            <td :title="item.StationNames">{{item.StationNames != null && item.StationNames.length > 10 ? item.StationNames.substring(0,10) + ' ...' : item.StationNames}}</td>
            <td :title="item.CusStationNames">{{item.CusStationNames != null && item.CusStationNames.length > 10 ? item.CusStationNames.substring(0,10) + ' ...' : item.CusStationNames}}</td>
            <td :title="item.SurfEleName">{{item.SurfEleName}}</td>
            <td :title="item.Val">{{item.Val}}</td>
            <td :title="item.LinkmanNames">{{item.LinkmanNames.length > 8 ? item.LinkmanNames.substring(0,8) + ' ...' : item.LinkmanNames}}</td>
            <td :title="item.AgainTime">{{item.AgainTime}} 小时</td>
            <td :title="item.AgainAddVal">{{item.AgainAddVal}}</td>
            <td class="edit">
              <button class="iconfont" @click="edit(item.ID)">&#xec88;</button>
              <button class="iconfont" @click="del(item.ID)">&#xec7b;</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-foot">
      <PagingHelper v-if="pagingInfo != ''" :pagingInfo="pagingInfo" @goPage="goPage" :key="pagingKey"></PagingHelper>
    </div>
  </div>
  <div v-if="editData != ''" class="sws-modal-mask">
    <div class="sws-modal">
      <div class="sws-modal-head">
        <div class="sws-modal-tool">
          <i class="iconfont close" @click="closeEdit">&#xe70f;</i>
        </div>
        <div class="title">
          {{editTitle}}
        </div>
      </div>
      <div class="sws-modal-body">
        <el-form label-width="120px">
          <el-form-item label="自动站">
            <el-checkbox-group v-model="editData.StationIDs">
              <el-tabs
                v-model="staArea"
                type="card"
              >
                <el-tab-pane v-for="(item, index) in staAreas" :key="index" :label="item" :name="item">
                  <el-checkbox v-for="(itemSec, indexSec) in stationInfos.filter(tem => tem.Cnty === item)" :key="indexSec" :label="itemSec.Station_ID_C" >{{itemSec.Station_Name}}</el-checkbox>
                </el-tab-pane>
              </el-tabs>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="自定义站">
            <el-checkbox-group v-model="editData.CusStationIDs">
              <el-tabs
                v-model="cusStationType"
                type="card"
              >
                <el-tab-pane v-for="(item, index) in cusStationTypes" :key="index" :label="item.Name" :name="item.Name">
                  <el-checkbox v-for="(itemSec, indexSec) in cusStationInfos.filter(tem => tem.CusStationTypeID === item.ID)" :key="indexSec" :label="itemSec.Station_ID_C" >{{itemSec.Station_Name}}</el-checkbox>
                </el-tab-pane>
              </el-tabs>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="监测要素">
            <el-select v-model="editData.SurfEle" class="m-2" filterable placeholder="选择要素" style="width:100%;">
              <el-option
                v-for="item in surfEleData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
          </el-form-item>
          <el-form-item label="阈值">
            <span>{{valTexts[0]}}</span>
            <el-input v-model="editData.Val" style="width:100px;" />
            <span>{{valTexts[1]}}</span>
            <el-input v-model="editData.AgainTime" style="width:100px;" />
            <span>{{valTexts[2]}}</span>
            <el-input v-model="editData.AgainAddVal" style="width:100px;" />
            <span>{{valTexts[3]}}</span>
          </el-form-item>
          <el-form-item label="联系人类别">
            <el-select v-model="linkmanTypeID" class="m-2" filterable placeholder="选择联系人类别" style="width:85%;padding-right:10px;">
              <el-option
                v-for="item in linkmanTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            <el-button style="width:15%;" @click="addToLinkmanType">添加联系人类别</el-button>
          </el-form-item>
          <el-form-item label="联系人">
            <el-select v-model="linkmanID" class="m-2" filterable placeholder="选择联系人" style="width:85%;padding-right:10px;">
              <el-option
                v-for="item in linkmen"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
            <el-button style="width:15%;" @click="addToLinkman">添加联系人</el-button>
          </el-form-item>
          <el-form-item v-for="(item, index) in thresholdLinkmen" :key="index" label="">
            <span v-if="item.LinkmanID != 0" style="width:15%;">{{item.LinkmanName}}</span>
            <span v-if="item.LinkmanTypeID != 0" style="width:15%;">{{item.LinkmanTypeName}}</span>
            <el-checkbox v-model="item.CallBit" label="电话通知" />
            <el-checkbox v-model="item.SmsBit" label="短信通知" />
            <el-checkbox v-model="item.WeChatBit" label="公众号通知" />
            <span style="padding-left:5%;padding-right:1%;">通知时间</span>
            <el-input v-model="item.TimeStart" style="width:80px;" />
            <span style="padding-left:1%;padding-right:1%;">至</span>
            <el-input v-model="item.TimeEnd" style="width:80px;" />
            <div class="pull-right">
              <el-button link @click="removeThresholdLinkman(item)"><i class="iconfont del-row">&#xec7b;</i></el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
            <el-button @click="closeEdit">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="sws-modal-foot"></div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, watch } from 'vue'
import axios from 'axios'
import PagingHelper from './PagingHelper'
import { ElMessage, ElMessageBox } from 'element-plus'
import common from '@/assets/js/common'
import qs from 'qs'
import { useStore } from 'vuex'

export default {
  name: 'SetupThreshold',
  components: {
    PagingHelper
  },
  props: [],
  setup (props, { emit }) {
    const store = useStore()
    const state = reactive({
      appCode: store.getters.getAppCode,
      userName: store.getters.getUserName,
      token: store.getters.getToken,
      keyword: '',
      listData: [],
      pagingInfo: '',
      pagingKey: 0,
      editData: '',
      editTitle: '',
      staArea: '',
      staAreas: [],
      stationInfos: [],
      cusStationType: '',
      cusStationTypes: [],
      cusStationInfos: [],
      thresholdLinkmen: [],
      surfEleData: [
        {
          value: 'pre1h',
          label: '1小时降水'
        },
        {
          value: 'pre12h',
          label: '12小时降水'
        },
        {
          value: 'pre24h',
          label: '24小时降水'
        },
        {
          value: 'temmin',
          label: '最低气温'
        },
        {
          value: 'temmax',
          label: '最高气温'
        },
        {
          value: 'wininst',
          label: '极大风'
        },
        {
          value: 'winmax',
          label: '最大风'
        },
        {
          value: 'vismin',
          label: '最小能见度'
        }
      ],
      linkmanID: '',
      linkmanTypeID: '',
      linkmen: [],
      limkmenSource: [],
      limkmanTypeSource: [],
      linkmanTypes: [],
      valTexts: []
    })
    // 数据列表
    const loadList = (page = 1) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupThreshold/List?keyword=' + state.keyword + '&page=' + page
      }).then(function (res) {
        state.listData = res.data.ThresholdListDatas
        state.pagingInfo = res.data.PagingInfo
        state.pagingKey++
      })
    }
    // 加载指定页
    const goPage = (page) => {
      loadList(page)
    }
    // 打开编辑页
    const edit = (id = 0) => {
      axios.request({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Custom-Token': state.token,
          'User-Card': state.userName,
          'App-Code': state.appCode
        },
        url: 'https://swsapi.bjzkzm.com/SetupThreshold/Edit?id=' + id
      }).then(function (res) {
        if (res.data.Threshold.StationIDs !== null && res.data.Threshold.StationIDs !== '') {
          res.data.Threshold.StationIDs = res.data.Threshold.StationIDs.split(',')
        } else {
          res.data.Threshold.StationIDs = []
        }
        if (res.data.Threshold.CusStationIDs !== null && res.data.Threshold.CusStationIDs !== '') {
          res.data.Threshold.CusStationIDs = res.data.Threshold.CusStationIDs.split(',')
        } else {
          res.data.Threshold.CusStationIDs = []
        }
        state.linkmen = []
        res.data.Linkmen.forEach(function (ele, index, arr) {
          state.linkmen.push({
            value: ele.ID,
            label: ele.Name
          })
        })
        state.linkmanTypes = []
        res.data.LinkmanTypes.forEach(function (ele, index, arr) {
          state.linkmanTypes.push({
            value: ele.ID,
            label: ele.Name
          })
        })
        res.data.ThresholdLinkmen.forEach(function (ele, index, arr) {
          ele.TimeStart = common.prefixInteger(ele.TimeStart.Hours, 2) + ':' + common.prefixInteger(ele.TimeStart.Minutes, 2) + ':' + common.prefixInteger(ele.TimeStart.Seconds, 2)
          ele.TimeEnd = common.prefixInteger(ele.TimeEnd.Hours, 2) + ':' + common.prefixInteger(ele.TimeEnd.Minutes, 2) + ':' + common.prefixInteger(ele.TimeEnd.Seconds, 2)
        })
        state.limkmenSource = res.data.Linkmen
        state.limkmanTypeSource = res.data.LinkmanTypes
        state.staArea = res.data.StaAreas.length > 0 ? res.data.StaAreas[0] : ''
        state.staAreas = res.data.StaAreas
        state.stationInfos = res.data.StationInfos
        state.cusStationType = res.data.CusStationTypes.length > 0 ? res.data.CusStationTypes[0].Name : ''
        state.cusStationTypes = res.data.CusStationTypes
        state.cusStationInfos = res.data.CusStationInfos
        state.thresholdLinkmen = res.data.ThresholdLinkmen
        state.editData = res.data.Threshold
        state.editTitle = res.data.EditTitle
      })
    }
    // 提交编辑页
    const onSubmit = () => {
      state.editData.AppCode = state.appCode
      state.editData.UserName = state.userName

      if (state.editData.CusStationIDs.length === 0 && state.editData.StationIDs.length === 0) {
        ElMessage({
          showClose: true,
          message: '请选择自动站或自定义站'
        })
      } else if (state.editData.Val === '' || isNaN(state.editData.Val)) {
        ElMessage({
          showClose: true,
          message: '请输入正确的阈值'
        })
      } else if (state.editData.AgainTime === '' || isNaN(state.editData.AgainTime) || state.editData.AgainTime < 1) {
        ElMessage({
          showClose: true,
          message: '请输入正确的间隔时间(大于等于1)'
        })
      } else if (state.editData.AgainAddVal === '' || isNaN(state.editData.AgainAddVal) || state.editData.AgainAddVal < 0.1) {
        ElMessage({
          showClose: true,
          message: '请输入正确的增量值(大于等于0.1)'
        })
      } else if (state.thresholdLinkmen.length === 0) {
        ElMessage({
          showClose: true,
          message: '请添加联系人'
        })
      } else {
        const obj = {
          Threshold: JSON.parse(JSON.stringify(state.editData)),
          ThresholdLinkmen: state.thresholdLinkmen
        }

        obj.Threshold.StationIDs = obj.Threshold.StationIDs.join(',')
        obj.Threshold.CusStationIDs = obj.Threshold.CusStationIDs.join(',')
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/SetupThreshold/Update',
          transformRequest: [function (data) {
            return qs.stringify(data) // 请求前参数序列化
          }],
          data: obj
        }).then(function (res) {
          if (res.data.State === 'error') {
            ElMessage({
              showClose: true,
              message: res.data.Msg
            })
          } else {
            loadList(state.pagingInfo.CurrentPage)
            state.editData = ''
          }
        })
      }
    }
    // 删除
    const del = (id) => {
      ElMessageBox.confirm(
        '确定删除?',
        '提醒',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        axios.request({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Custom-Token': state.token,
            'User-Card': state.userName,
            'App-Code': state.appCode
          },
          url: 'https://swsapi.bjzkzm.com/SetupThreshold/Del?id=' + id
        }).then(function (res) {
          if (res.data.State === 'ok') {
            loadList(state.pagingInfo.CurrentPage)
          } else if (res.data.State === 'error') {
            ElMessage({
              showClose: true,
              message: res.data.Msg
            })
          }
        })
      }).catch(() => {
      })
    }
    // 关闭编辑页
    const closeEdit = () => {
      state.editData = ''
      state.staData = []
    }
    // 添加联系人
    const addToLinkman = () => {
      if (state.linkmanID === '') {
        ElMessage({
          showClose: true,
          message: '请选择联系人'
        })
      } else if (state.thresholdLinkmen.some(tem => tem.LinkmanID === state.linkmanID)) {
        ElMessage({
          showClose: true,
          message: '请勿重复添加'
        })
      } else {
        state.limkmenSource.filter(tem => tem.ID === state.linkmanID).forEach(function (ele, index, arr) {
          state.thresholdLinkmen.push({
            ID: 0,
            AppID: 0,
            AppCode: state.appCode,
            ThresholdID: 0,
            LinkmanID: ele.ID,
            LinkmanName: ele.Name,
            LinkmanTypeID: 0,
            LinkmanTypeName: '',
            CallBit: false,
            SmsBit: false,
            WeChatBit: false,
            TimeStart: '00:00:00',
            TimeEnd: '23:59:59'
          })
        })
      }
    }
    const addToLinkmanType = () => {
      if (state.linkmanTypeID === '') {
        ElMessage({
          showClose: true,
          message: '请选择联系人类别'
        })
      } else if (state.thresholdLinkmen.some(tem => tem.LinkmanTypeID === state.linkmanTypeID)) {
        ElMessage({
          showClose: true,
          message: '请勿重复添加'
        })
      } else {
        state.limkmanTypeSource.filter(tem => tem.ID === state.linkmanTypeID).forEach(function (ele, index, arr) {
          state.thresholdLinkmen.push({
            ID: 0,
            AppID: 0,
            AppCode: state.appCode,
            ThresholdID: 0,
            LinkmanID: 0,
            LinkmanName: '',
            LinkmanTypeID: ele.ID,
            LinkmanTypeName: ele.Name,
            CallBit: false,
            SmsBit: false,
            WeChatBit: false,
            TimeStart: '00:00:00',
            TimeEnd: '23:59:59'
          })
        })
      }
    }
    const removeThresholdLinkman = (item) => {
      const arrNew = []
      state.thresholdLinkmen.forEach(function (ele, index, arr) {
        if (ele !== item) {
          arrNew.push(ele)
        }
      })
      state.thresholdLinkmen = arrNew
    }
    watch(() => state.editData.SurfEle, () => {
      switch (state.editData.SurfEle) {
        case 'pre1h':
          state.valTexts = ['1小时降水大于', 'mm时提醒，', '小时后再次提醒或增加超过', 'mm时再次提醒']
          break
        case 'pre12h':
          state.valTexts = ['12小时降水大于', 'mm时提醒，', '小时后再次提醒或增加超过', 'mm时再次提醒']
          break
        case 'pre24h':
          state.valTexts = ['24小时降水大于', 'mm时提醒，', '小时后再次提醒或增加超过', 'mm时再次提醒']
          break
        case 'temmin':
          state.valTexts = ['最低气温小于', ' ℃时提醒，', '小时后再次提醒或减少超过', '℃时再次提醒']
          break
        case 'temmax':
          state.valTexts = ['最高气温大于', '℃时提醒，', '小时后再次提醒或增加超过', '℃时再次提醒']
          break
        case 'wininst':
          state.valTexts = ['极大风大于', 'm/s时提醒，', '小时后再次提醒或增加超过', 'm/s时再次提醒']
          break
        case 'winmax':
          state.valTexts = ['最大风大于', 'm/s时提醒，', '小时后再次提醒或增加超过', 'm/s时再次提醒']
          break
        case 'vismin':
          state.valTexts = ['最小能见度小于', 'm时提醒，', '小时后再次提醒或减少超过', 'm时再次提醒']
          break
      }
    })
    onMounted(() => {
      loadList()
    })
    return {
      ...toRefs(state),
      goPage,
      loadList,
      edit,
      onSubmit,
      del,
      closeEdit,
      addToLinkman,
      addToLinkmanType,
      removeThresholdLinkman
    }
  }
}
</script>
